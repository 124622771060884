import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from '@next/core-lib/app';
import { LocaleService } from '@next/core-lib/i18n';
import { FilterAction } from '@next/core-lib/components';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { UserSelectors } from '@next/core-lib/user';
import {
  clearDeliveryTotalsRequest,
  getDeliveryReportRequest,
  loadDeliveryTotalsRequest,
} from '../../../delivery-data/store/delivery-data.actions';
import { HierarchicalData } from '../../../shared/hierarchical-data/hierarchical-data.model';
import { selectDeliveryTotals } from '../../../delivery-data/store/delivery-data.selectors';
import {
  DeliveryCategories,
  DeliveryColumnTypes,
} from '../../../delivery-data/delivery-data.model';
import { DeliveryDataService } from '../../../delivery-data/services/delivery-data.service';
import { settingsContextName, SettingsKeys } from '../../../shared/models/shared.models';

@Component({
  selector: 'app-inbound-delivery-totals',
  templateUrl: './inbound-delivery-totals.component.html',
})
export class InboundDeliveryTotalsComponent implements OnInit, OnDestroy {
  public readonly totalsTableConfig = {
    prefix: 'inbound-delivery.inbound-delivery-totals.',
    localeService: this.localeService,
    columns: [
      DeliveryColumnTypes.description,
      DeliveryColumnTypes.dryWeightDailyAmount,
      DeliveryColumnTypes.grossWeight,
    ],
    digitsInfo: {
      [DeliveryColumnTypes.dryWeightDailyAmount]: '1.0-0',
      [DeliveryColumnTypes.grossWeight]: '1.0-0',
    },
  };

  public readonly filterActions: FilterAction[] = [
    {
      icon: 'file_download',
      viewValue: 'Excel',
      value: 'xlsx',
    },
    {
      icon: 'file_download',
      viewValue: 'PDF',
      value: 'pdf',
    },
    {
      icon: 'file_download',
      viewValue: 'CSV',
      value: 'csv',
    },
  ];

  public readonly headings = this.deliveryDataService.getHeadings(
    this.totalsTableConfig.columns,
    'inbound-delivery.inbound-delivery-totals.',
  );

  public readonly exportFilename = `${this.localeService.translate(
    'inbound-delivery.inbound-delivery-totals.fileName',
  )}`;

  public data$: Observable<HierarchicalData[]>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
    private readonly deliveryDataService: DeliveryDataService,
  ) {}

  ngOnInit(): void {
    this.data$ = this.store
      .select(selectDeliveryTotals(this.totalsTableConfig))
      .pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearDeliveryTotalsRequest());

    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.store.dispatch(loadDeliveryTotalsRequest({ category: DeliveryCategories.grain }));
  }

  public downloadPdf = () =>
    combineLatest([
      this.settingsService.getSettings({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.dateRange,
      }),
      this.settingsService.getSettings({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.locations,
      }),
      this.store.select(UserSelectors.selectLocations),
      this.store.select(UserSelectors.selectCustomer),
      this.data$,
    ])
      .pipe(
        take(1),
        map(([dateRange, filterLocationsResult, userLocations, customer, data]) => {
          const startDate = new Date((dateRange?.[0].data as { startDate: string })?.startDate);
          const endDate = new Date((dateRange?.[0].data as { endDate: string })?.endDate);
          const filterLocations = filterLocationsResult[0].data as number[];

          this.store.dispatch(
            getDeliveryReportRequest({
              report: {
                title: this.localeService.translate('inbound-delivery.title-totals'),
                subTitle: `${this.localeService.translate(
                  'inbound-delivery.report.sub-title',
                )} ${this.deliveryDataService.formatDateTime(new Date())}`,
                fileName: this.deliveryDataService.formatExportFileName(
                  this.localeService.translate('inbound-delivery.inbound-delivery-totals.fileName'),
                  customer.externalId,
                  startDate,
                  endDate,
                ),
                legend: {
                  [this.localeService.translate(
                    'inbound-delivery.report.legend.customer',
                  )]: `${customer.name} (${customer.externalId})`,
                  [this.localeService.translate('inbound-delivery.report.legend.locations')]:
                    this.deliveryDataService.formatLocations(
                      userLocations.filter((userLocation) =>
                        filterLocations?.length > 0
                          ? filterLocations.includes(userLocation.id)
                          : true,
                      ),
                    ),
                  [this.localeService.translate('inbound-delivery.report.legend.start-date')]:
                    this.deliveryDataService.formatDateTime(startDate),
                  [this.localeService.translate('inbound-delivery.report.legend.end-date')]:
                    this.deliveryDataService.formatDateTime(endDate),
                },
                tableHeadings:
                  this.totalsTableConfig?.columns?.map((column) =>
                    this.localeService.translate(`${this.totalsTableConfig.prefix}${column}`),
                  ) ?? [],
                tableFooter: [],
                firstPageRowsPerPage: 32,
                rowsPerPage: 47,
                contactInformation: {
                  email: this.localeService.translate('inbound-delivery.report.contact.email'),
                  phoneNumber: this.localeService.translate(
                    'inbound-delivery.report.contact.phone-number',
                  ),
                },
                rows: this.deliveryDataService.mapHierarchicalDataToPdfRows(data),
              },
            }),
          );
        }),
      )
      .subscribe();
}
