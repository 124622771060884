import { LocaleService } from '@next/core-lib/i18n';

export const invoiceTypeTranslationKey = 'invoice.invoice-types';

// This is a best effort but not ideal solution to translate invoice types.
// As the type is a free text field in SAP, we can never support all invoice types for translation.
// A preferred solution would be SAP providing the different translations for invoice types.
export const translateInvoiceType = (
  invoiceDescription: string,
  localeService: LocaleService,
): string => {
  let translation = localeService.translate(
    `${invoiceTypeTranslationKey}.${invoiceDescription.trim().replace(' ', '').toLowerCase()}`,
  );

  if (translation.includes(invoiceTypeTranslationKey)) {
    translation = invoiceDescription;
  }

  return translation;
};
