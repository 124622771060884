import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { SettingsKeys, settingsContextName } from '../shared/models/shared.models';
import { InvoiceView } from './models/invoice.model';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: [],
})
export class InvoiceComponent implements OnInit, OnDestroy {
  public invoiceViewDataNotAvailableYet = true;

  public readonly invoiceViewType = InvoiceView;

  public invoiceView$: Observable<InvoiceView>;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly settingsService: SettingsService) {}

  ngOnInit(): void {
    this.invoiceView$ = this.settingsService
      .getSettingsUpdates({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.invoiceView,
      })
      .pipe(
        debounceTime(0),
        map((settings) =>
          settings?.map((setting) => (Array.isArray(setting) ? setting[0] : setting)),
        ),
        map((settings) => settings?.[0]?.data as InvoiceView),
        tap(() => {
          this.invoiceViewDataNotAvailableYet = false;
        }),
        takeUntil(this.destroy$),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
