import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { RootState } from '@next/core-lib/app';
import {
  DefaultFilterOptions,
  FilterConfig,
  FiltersConfig,
  FilterTypes,
} from '@next/core-lib/components';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { LocaleService } from '@next/core-lib/i18n';
import { BreakpointService, BreakpointServiceInjector } from '@next/core-lib/responsive';
import { SettingsStorage } from '@next/core-lib/settings';
import { TableColumn, TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { Identification, Location, UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, skipWhile, startWith, take } from 'rxjs/operators';
import { settingsContextName } from 'src/app/shared/models/shared.models';
import { Environment } from 'src/environment';
import {
  IdentificationFilterKeys,
  IdentificationFilterValues,
  IdentificationFilterValuesKeys,
} from '../../models/filtering';
import {
  IdentificationColumns,
  IdentificationOverviewModel,
  IdentificationStatus,
} from '../../models/identification.models';
import { DialogService } from '@next/core-lib/dialog';
import { IdentificationAddComponent } from '../identification-add/identification-add.component';
import { showIdentification } from '../../store/actions';
import * as duration from 'duration-fns';
import { startOfDay, sub } from 'date-fns';

interface ViewModel {
  tableConfig: TableConfig;
  aboutToExpire: boolean;
  expired: boolean;
}

@Component({
  selector: 'app-identification-overview',
  templateUrl: './identification-overview.component.html',
  styleUrls: ['identification-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentificationOverviewComponent {
  @ViewChild('statusColumn') statusColumn: TemplateRef<unknown>;
  readonly identificationStatusOptions = IdentificationStatus;

  filterConfig: FilterConfig<FiltersConfig>;

  readonly filterControl = new FormControl();

  readonly identifications$ = this.store.select(UserSelectors.selectCustomer).pipe(
    skipWhile((customer) => !customer),
    map(({ shipTos }) =>
      shipTos
        .map((shipTo) =>
          shipTo.identifications.map((identification) =>
            this.mapIdentificationToOverviewModel(shipTo, identification),
          ),
        )
        .flat(),
    ),
    shareReplay(1),
  );

  readonly vm$: Observable<ViewModel>;

  private aboutToExpire$: Observable<boolean>;
  private expired$: Observable<boolean>;
  private data$: Observable<IdentificationOverviewModel[]>;
  private tableConfig$: Observable<TableConfig>;

  private defaultLocations$ = this.store.select(UserSelectors.selectLocations).pipe(
    skipWhile((value) => !value?.length),
    map((locations) => locations?.map((location) => location.id)),
    distinctUntilChanged((a, b) => a.length === b.length && a.every((p) => a[p] === b[p])),
  );

  private defaultIdentificationStatuses = [IdentificationStatus.active];

  private readonly defaultIdentificationTypes$ = of(this.environment.IDENTIFICATION_TYPES_NL || []);

  private identificationStatusOptions$ = of([
    {
      value: IdentificationStatus.active,
      viewValue: this.localeService.translate(
        `identification.identification-overview.status-${IdentificationStatus.active}`,
      ),
    },
    {
      value: IdentificationStatus.inactive,
      viewValue: this.localeService.translate(
        `identification.identification-overview.status-${IdentificationStatus.inactive}`,
      ),
    },
    {
      value: IdentificationStatus.ended,
      viewValue: this.localeService.translate(
        `identification.identification-overview.status-${IdentificationStatus.ended}`,
      ),
    },
  ]);

  private identificationTypeOptions$ = this.defaultIdentificationTypes$.pipe(
    map((types) =>
      types.map((type) => ({
        value: type,
        viewValue: this.localeService.translate(`identification.identification-types.${type}`),
      })),
    ),
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    @Inject(EnvironmentInjector) private readonly environment: Environment,
    @Inject(BreakpointServiceInjector) private readonly breakpoint: BreakpointService,
    @Inject(DialogService) private readonly dialogService: DialogService,
  ) {
    this.initFilterConfig();
    this.initData();
    this.initTableConfig();
    this.initExpiredIdentifications();

    this.vm$ = combineLatest([this.tableConfig$, this.aboutToExpire$, this.expired$]).pipe(
      map(([tableConfig, aboutToExpire, expired]) => ({
        tableConfig,
        aboutToExpire: aboutToExpire && !expired,
        expired,
      })),
    );
  }

  openIdentificationAdd() {
    this.store
      .select(showIdentification)
      .pipe(take(1))
      .subscribe((_) => this.dialogService.fullscreenDialog(IdentificationAddComponent, {}, {}));

    this.store.dispatch(showIdentification());
  }

  private initFilterConfig() {
    this.filterConfig = {
      filters: {
        locationId: {
          type: FilterTypes.MultiSelect,
          options: DefaultFilterOptions.Locations,
          value: this.defaultLocations$,
          defaultValue: this.defaultLocations$,
          store: {
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: IdentificationFilterKeys.locationId,
          },
        },
        identificationType: {
          type: FilterTypes.MultiSelect,
          label: this.localeService.translate(
            `identification.identification-overview.identification-type`,
          ),
          options: this.identificationTypeOptions$,
          value: this.defaultIdentificationTypes$,
          defaultValue: this.defaultIdentificationTypes$,
          store: {
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: IdentificationFilterKeys.identificationType,
          },
        },
        status: {
          type: FilterTypes.MultiSelect,
          label: this.localeService.translate(`identification.identification-overview.status`),
          options: this.identificationStatusOptions$,
          value: of(this.defaultIdentificationStatuses),
          defaultValue: of(this.defaultIdentificationStatuses),
          store: {
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: IdentificationFilterKeys.status,
          },
        },
      },
    };
  }

  private initData() {
    this.data$ = combineLatest([
      this.identifications$,
      this.defaultIdentificationTypes$,
      this.filterControl.valueChanges.pipe(startWith({})),
    ]).pipe(
      map(([identifications, identificationTypes, filters]) => {
        identifications = identifications.filter((id) =>
          identificationTypes.includes(id.identificationType),
        );
        return [identifications, filters];
      }),
      map(([identifications, filters]) => this.filterIdentifications(identifications, filters)),
    );
  }

  private initTableConfig() {
    this.tableConfig$ = combineLatest([
      this.data$,
      this.breakpoint.ltMd$.pipe(startWith(true)),
    ]).pipe(
      map(([identifications, isMobileView]) => {
        const desktopColumns: TableColumn[] = [
          {
            id: IdentificationColumns.location,
            type: TableColumnTypes.STRING,
            label: `identification.identification-overview.${IdentificationColumns.location}`,
            className: 'flex__child--2',
            headerClassName: 'flex__child--2',
          },
          {
            id: IdentificationColumns.customerNumber,
            type: TableColumnTypes.STRING,
            label: `identification.identification-overview.${IdentificationColumns.customerNumber}`,
            className: 'flex__child--1',
            headerClassName: 'flex__child--1',
          },
          {
            id: IdentificationColumns.identificationTypeTranslated,
            type: TableColumnTypes.STRING,
            label: `identification.identification-overview.${IdentificationColumns.identificationTypeTranslated}`,
            className: 'flex__child--2',
            headerClassName: 'flex__child--2',
          },
          {
            id: IdentificationColumns.identificationNumber,
            type: TableColumnTypes.STRING,
            label: `identification.identification-overview.${IdentificationColumns.identificationNumber}`,
            className: 'flex__child--1',
            headerClassName: 'flex__child--1',
          },
          {
            id: IdentificationColumns.validFrom,
            type: TableColumnTypes.DATE,
            label: `identification.identification-overview.${IdentificationColumns.validFrom}`,
            className: 'flex__child--1',
            headerClassName: 'flex__child--1',
          },
          {
            id: IdentificationColumns.validTo,
            type: TableColumnTypes.DATE,
            label: `identification.identification-overview.${IdentificationColumns.validTo}`,
            className: 'flex__child--1',
            headerClassName: 'flex__child--1',
          },
          {
            id: IdentificationColumns.status,
            type: TableColumnTypes.TEMPLATE,
            label: `identification.identification-overview.${IdentificationColumns.status}`,
            className: 'flex__child--1',
            headerClassName: 'flex__child--1',
            template: this.statusColumn,
          },
        ];

        const mobileColumns = [...desktopColumns];
        [mobileColumns[3], mobileColumns[6]] = [mobileColumns[6], mobileColumns[3]];

        return {
          dataSource: new MatTableDataSource(identifications),
          columns: isMobileView ? mobileColumns : desktopColumns,
          pagination: true,
          scrolling: true,
          width: '70rem',
          wrapperWidth: '100%',
          primaryColumn: IdentificationColumns.identificationTypeTranslated,
          selectColumn: false,
        };
      }),
    );
  }

  private initExpiredIdentifications() {
    // Filter out identifications that do not meet the following conditions:
    // - Identification type is not in the list of visible identifications
    // - Expired identification is no more than configures duration old (typically two months)
    const filteredIdentifications = this.identifications$.pipe(
      map((all) => {
        const now = new Date();
        const identifications = all.filter((identification) =>
          this.environment.IDENTIFICATION_TYPES_NL.includes(identification.identificationType),
        );

        return {
          identifications,
          now,
        };
      }),
    );

    this.aboutToExpire$ = filteredIdentifications.pipe(
      map(({ identifications, now }) =>
        identifications.some((identification) => {
          const warningDate = startOfDay(
            sub(
              new Date(identification.validTo),
              duration.parse(this.environment.IDENTIFICATION_WARNING_PERIOD),
            ),
          );
          return now >= warningDate && !this.isExpired(identification, now);
        }),
      ),
    );

    this.expired$ = filteredIdentifications.pipe(
      map(({ identifications, now }) => {
        const allExpiredIdentifications = identifications.filter((identification) =>
          this.isExpired(identification, now),
        );

        const hasExpiredIdentificationsWithoutValidReplacement = allExpiredIdentifications.some(
          (expiredIdentification) =>
            !identifications.some(
              (identification) =>
                identification.identificationType === expiredIdentification.identificationType &&
                !this.isExpired(identification, now),
            ),
        );

        return hasExpiredIdentificationsWithoutValidReplacement;
      }),
    );
  }

  private isExpired: (identification: IdentificationOverviewModel, now: Date) => boolean = (
    identification,
    now,
  ) => now > startOfDay(new Date(identification.validTo));

  private getStatus(validFrom: string, validTo: string): IdentificationStatus {
    const now = new Date();
    const validFromCompare = new Date(validFrom);
    const validToCompare = new Date(validTo);

    if (validFromCompare <= now && validToCompare > now) {
      return IdentificationStatus.active;
    }
    if (validToCompare <= now) {
      return IdentificationStatus.ended;
    }
    if (validFromCompare > now) {
      return IdentificationStatus.inactive;
    }
  }

  private mapIdentificationToOverviewModel(
    location: Location,
    identification: Identification,
  ): IdentificationOverviewModel {
    return {
      location: location.street,
      locationId: location.id,
      customerNumber: location.externalId,
      identificationType: identification.name,
      identificationTypeTranslated: this.localeService.translate(
        `identification.identification-types.${identification.name}`,
      ),
      identificationNumber:
        identification.name === 'ZEPEU1'
          ? this.localeService.translate(`identification.identification-overview.nonumber`)
          : identification.value,
      validFrom: identification.validFrom,
      validTo: identification.validTo,
      status: this.getStatus(identification.validFrom, identification.validTo),
    };
  }

  private filterIdentifications(
    identifications: IdentificationOverviewModel[],
    filters: IdentificationFilterValues,
  ): IdentificationOverviewModel[] {
    let filteredIdentifications: IdentificationOverviewModel[] = identifications;

    const filterHelper = <T = IdentificationOverviewModel[IdentificationFilterValuesKeys]>(
      filterKey: IdentificationFilterValuesKeys,
    ) => {
      if (filters[filterKey]?.length) {
        filteredIdentifications = filteredIdentifications.filter((identification) =>
          (filters[filterKey] as unknown as T[]).includes(
            identification[filterKey] as unknown as T,
          ),
        );
      }
    };

    Object.keys(this.filterConfig.filters).forEach(filterHelper);
    return filteredIdentifications;
  }
}
