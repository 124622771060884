export enum IdentificationFilterKeys {
  locationId = 'locationId',
  identificationType = 'identificationType',
  status = 'status',
}

export interface IdentificationFilterValues {
  locationId: number[];
  identificationType: string[];
  status: string[];
}

export type IdentificationFilterValuesKeys = keyof IdentificationFilterValues;
