<app-page-hierarchical-data-filter
  [pageTitle]="'inbound-delivery.title' | translate"
  [searchFieldPlaceholder]="'inbound-delivery.search-placeholder' | translate"
  [emptyTitle]="'inbound-delivery.empty-title' | translate"
  [emptyDescription]="'inbound-delivery.empty-description' | translate"
  [data$]="data$"
  (loadDataCallback)="loadData()"
  [settingsActions]="filterActions"
  [exportFilename]="exportFilename"
  [exportPdfCallback]="downloadPdf"
  [headings]="headings"
  [showLegacyTotalWarning]="false"
></app-page-hierarchical-data-filter>
