<div class="page-table-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ 'delivery.title' | translate }}</h1>

  <agr-table-v2
    matSort
    [dataSource]="dataSource"
    [isEqual]="isEqual"
    [withSelection]="true"
    (filterClearClick)="resetFilters()"
    class="delivery-overview__table-spaced-cols"
  >
    <ng-container *agrTableSearch [formGroup]="searchAndFilters">
      <agr-table-search
        formControlName="search"
        [label]="'search' | translate"
        [placeholder]="'delivery.search-placeholder' | translate"
      >
      </agr-table-search>
    </ng-container>

    <ng-container *agrTableFilters [formGroup]="searchAndFilters">
      <agr-table-date-range-filter
        [label]="'daterange-picker.period' | translate"
        formControlName="dateRange"
      >
      </agr-table-date-range-filter>

      <agr-table-multi-select-filter
        agrTableFilterLocations
        [label]="'delivery.locations-filter' | translate"
        formControlName="locations"
      >
      </agr-table-multi-select-filter>
    </ng-container>

    <agr-table-top-bar *agrTableTopBar>
      <agr-table-selected-items></agr-table-selected-items>
      <div>
        <agr-export agrTableExport [options]="exportOptions" [dataSource]="dataSource">
        </agr-export>
      </div>
    </agr-table-top-bar>

    <ng-container matColumnDef="deliveryDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.deliveryDate' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.deliveryDate' | translate"
        *matCellDef="let row"
      >
        {{ row.deliveryDate | agrDate : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="externalDeliveryId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.externalDeliveryId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.externalDeliveryId' | translate"
        *matCellDef="let row"
      >
        {{ row.externalDeliveryId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="externalOrderId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.externalOrderId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.externalOrderId' | translate"
        *matCellDef="let row"
      >
        {{ row.externalOrderId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="externalInvoiceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.externalInvoiceId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.externalInvoiceId' | translate"
        *matCellDef="let row"
      >
        <a
          [routerLink]="['/invoice', row.externalInvoiceId]"
          class="text--underline delivery-overview__invoice-link"
        >
          {{ row.externalInvoiceId }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="allShipToIds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.allShipToIds' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.allShipToIds' | translate"
        *matCellDef="let row"
      >
        <agr-identifier-trigger
          [data]="row.allShipToIds"
          [hideDescription]="true"
          [onlyUnique]="true"
          (click)="$event.stopPropagation()"
        ></agr-identifier-trigger>
      </td>
    </ng-container>

    <ng-container matColumnDef="externalProductId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.externalProductId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.externalProductId' | translate"
        *matCellDef="let row"
      >
        {{ row.externalProductId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.productTitle' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.productTitle' | translate"
        *matCellDef="let row"
      >
        {{ row.productTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unitsDelivered">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.unitsDelivered' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.unitsDelivered' | translate"
        *matCellDef="let row"
      >
        {{ row.unitsDelivered | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unitVolumeType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.unitVolumeType' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.unitVolumeType' | translate"
        *matCellDef="let row"
      >
        {{ 'unit.' + row.unitVolumeType | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="baseQuantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.baseQuantity' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.baseQuantity' | translate"
        *matCellDef="let row"
      >
        {{ row.baseQuantity | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="baseUnit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'delivery.delivery-overview.baseUnit' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'delivery.delivery-overview.baseUnit' | translate"
        *matCellDef="let row"
      >
        {{ row.baseUnit }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <app-table-actions-button>
          <button mat-menu-item (click)="downloadDelivery(row)">
            <mat-icon>file_download</mat-icon>
            <span>{{ 'delivery.delivery-overview.download-delivery' | translate }}</span>
          </button>
          <button mat-menu-item (click)="openMSDS(row)">
            <mat-icon>file_download</mat-icon>
            <span>{{ 'delivery.delivery-overview.download-msds' | translate }}</span>
          </button>
          <button mat-menu-item [disabled]="!row?.invoiceId" (click)="downloadInvoice(row)">
            <mat-icon>file_download</mat-icon>
            <span>{{ 'delivery.delivery-overview.download-invoice' | translate }}</span>
          </button>
          <button
            mat-menu-item
            [disabled]="!row?.itemVLHasPdf"
            (click)="downloadQualityCertificate(row)"
          >
            <mat-icon>file_download</mat-icon>
            <span>{{ 'delivery.delivery-overview.download-quality-certificate' | translate }}</span>
          </button>
          <button
            *ngIf="returnOrdersEnabled$ | async"
            mat-menu-item
            [disabled]="!row?.itemCanBeReturned"
            (click)="addSkuToReturnOrder(row)"
            data-test="delivery-overview__add-to-return-btn"
          >
            <mat-icon>assignment_return</mat-icon>
            <span>{{ 'delivery.delivery-overview.add-to-return' | translate }}</span>
          </button>
        </app-table-actions-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: displayColumns"></tr>
    <tr *matNoDataRow>
      <td class="text--center" [attr.colspan]="displayColumns.length">
        <agr-notification-empty
          data-test="hierarchical-data__empty"
          [isEmpty]="true"
          [emptyTitle]="'delivery.empty-title' | translate"
          [emptyDescription]="'delivery.empty-description' | translate"
          [emptyImage]="'/assets/images/no-items-3.png'"
          [emptyActionIcon]="'history'"
          [emptyActionText]="'page-table-filter.reset-filters' | translate"
          [showEmptyAction]="true"
          (emptyAction)="resetFilters()"
          class="spacing--margin-top"
        ></agr-notification-empty>
      </td>
    </tr>
  </agr-table-v2>
</div>
