import { Injectable } from '@angular/core';
import { DaterangeConfig } from '@next/core-lib/components';
import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import { Observable, of } from 'rxjs';
import { defaultRecentStartDateMonthSubstract } from '../data/shared-defaults';

@Injectable()
export class DateService {
  defaultRecentStartDate() {
    const startDate = this.setDefaultStartHours(new Date());
    startDate.setMonth(startDate.getMonth() - defaultRecentStartDateMonthSubstract);
    return startDate;
  }

  defaultRecentEndDate() {
    return this.setDefaultEndHours(new Date());
  }

  defaultDateRange(): Observable<DateRange> {
    return of({
      startDate: this.defaultRecentStartDate(),
      endDate: this.defaultRecentEndDate(),
    });
  }

  createDateConfigWithCalendarYearOptions() {
    return new DaterangeConfig({
      presets: [
        ...new DaterangeConfig().defaultConfig.presets,
        {
          description: 'daterange-picker.last-year',
          startDate: this.setDefaultStartDate(new Date(), true),
          endDate: this.setDefaultEndDate(new Date(), true),
        },
        {
          description: 'daterange-picker.current-year',
          startDate: this.setDefaultStartDate(new Date(), false),
          endDate: this.setDefaultEndDate(new Date(), false),
        },
      ],
    });
  }

  private setDefaultStartDate(date: Date, useLastYear: boolean) {
    const year = date.getFullYear();
    const startDate = new Date(useLastYear ? year - 1 : year, 0, 1);
    return this.setDefaultStartHours(startDate);
  }

  private setDefaultEndDate(date: Date, useLastYear: boolean) {
    const year = date.getFullYear();
    const endDate = new Date(useLastYear ? year - 1 : year, 11, 31);
    return this.setDefaultEndHours(endDate);
  }

  private setDefaultStartHours(date: Date) {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  private setDefaultEndHours(date: Date) {
    date.setHours(23, 59, 59, 0);
    return date;
  }
}
