import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  map,
  tap,
  catchError,
  startWith,
  finalize,
  switchMap,
  take,
  debounceTime,
} from 'rxjs/operators';
import { CoreActions } from '@next/core-lib';
import { NotificationActions } from '@next/core-lib/notification';
import { LocaleService } from '@next/core-lib/i18n';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { Delivery } from '../delivery-data.model';
import { DeliveryDataService } from '../services/delivery-data.service';
import { ReportService } from '../services/report.service';
import * as DeliveryDataActions from './delivery-data.actions';
import { DownloadBlobService } from '../../shared/services/download-blob.service';
import { ReportRequest } from '../models/report.model';
import { DateService } from '../../shared/services/date.service';
import { settingsContextName, SettingsKeys } from '../../shared/models/shared.models';

@Injectable()
export class DeliveryDataEffects {
  loadRecentDeliveries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryDataActions.loadRecentDeliveriesRequest),
      mergeMap(() =>
        this.deliveryDataService
          .getDeliveries(
            this.dateService.defaultRecentStartDate().toISOString(),
            this.dateService.defaultRecentEndDate().toISOString(),
          )
          .pipe(
            map((deliveries: Delivery[]) =>
              DeliveryDataActions.loadRecentDeliveriesSuccess({ deliveries }),
            ),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          ),
      ),
    ),
  );

  loadDeliveries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryDataActions.loadDeliveriesRequest),
      debounceTime(500),
      switchMap((data) =>
        this.settingsService
          .getSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.dateRange,
          })
          .pipe(
            take(1),
            map((settings) => ({ data, settings })),
          ),
      ),
      mergeMap(({ data, settings }) => {
        const selectedDateRange = settings?.[0]?.data as { [key: string]: string };
        const startDate = !!selectedDateRange?.startDate
          ? new Date(selectedDateRange?.startDate)
          : this.dateService.defaultRecentStartDate();
        const endDate = !!selectedDateRange?.endDate
          ? new Date(selectedDateRange.endDate)
          : this.dateService.defaultRecentEndDate();
        const category = 'category' in data && data.category;

        return this.deliveryDataService
          .getDeliveries(startDate.toISOString(), endDate.toISOString(), category)
          .pipe(
            map((deliveries: Delivery[]) =>
              DeliveryDataActions.loadDeliveriesSuccess({ deliveries, category }),
            ),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          );
      }),
    ),
  );

  loadDeliveryTotals$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryDataActions.loadDeliveryTotalsRequest),
      debounceTime(500),
      switchMap((data) =>
        this.settingsService
          .getSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
          })
          .pipe(
            take(1),
            map((settings) => ({ data, settings })),
          ),
      ),
      mergeMap(({ data, settings }) => {
        const selectedDateRange = settings?.find((setting) =>
          (setting.settingsId + '').includes(SettingsKeys.dateRange),
        )?.data as { [key: string]: string };
        const startDate = !!selectedDateRange?.startDate
          ? new Date(selectedDateRange?.startDate)
          : this.dateService.defaultRecentStartDate();
        const endDate = !!selectedDateRange?.endDate
          ? new Date(selectedDateRange.endDate)
          : this.dateService.defaultRecentEndDate();

        const selectedBrsNumber =
          settings?.find((setting) => (setting.settingsId + '').includes(SettingsKeys.brsNumber))
            ?.data + '';
        const category = 'category' in data && data.category;

        return this.deliveryDataService
          .getDeliveryTotals(
            startDate.toISOString(),
            endDate.toISOString(),
            category,
            selectedBrsNumber,
          )
          .pipe(
            map((deliveryTotals) =>
              DeliveryDataActions.loadDeliveryTotalsSuccess({ deliveryTotals, category }),
            ),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          );
      }),
    ),
  );

  getDeliveryDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryDataActions.getDeliveryDocumentRequest,
        NotificationActions.snackbarClickButton,
      ),
      switchMap((data) => {
        const ids = 'ids' in data && data.ids;
        const shipToIds = 'shipToIds' in data && data.shipToIds;

        if (ids && shipToIds) {
          return this.deliveryDataService.getDeliveryNote(ids, shipToIds).pipe(
            tap(this.blobService.download),
            map(() => DeliveryDataActions.getDeliveryDocumentSuccess()),
            catchError((error: HttpResponse<Blob>) =>
              of(
                DeliveryDataActions.getDeliveryDocumentFailed(),
                NotificationActions.errorSnackbar({
                  message: this.localeService.translate(
                    `delivery.delivery-overview.failed-to-download-pdf-${error.status}`,
                  ),
                }),
              ),
            ),
            startWith(
              NotificationActions.infoSnackbar({
                message: this.localeService.translate('delivery.delivery-overview.pdf-loading'),
                buttonTitle: this.localeService.translate('cancel'),
                spinner: true,
              }),
            ),
            finalize(() => this.store.dispatch(NotificationActions.cancelSnackbar())),
          );
        } else {
          return of(NotificationActions.cancelSnackbar());
        }
      }),
    ),
  );

  getMSDSRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryDataActions.getMSDSRequest, NotificationActions.snackbarClickButton),
      switchMap((data) => {
        const externalProductId = 'externalProductId' in data && data.externalProductId;
        const shipToId = 'shipToId' in data && data.shipToId;

        if (externalProductId) {
          return this.deliveryDataService.getMSDS(shipToId, externalProductId).pipe(
            tap(this.blobService.download),
            map(() => DeliveryDataActions.getMSDSSuccess()),
            catchError((error: HttpResponse<Blob>) => {
              const message = this.localeService.translate(
                'delivery.delivery-overview.no-msds-warning',
              );
              const snackbarConfig = {
                message,
                buttonTitle: this.localeService.translate(
                  'delivery.delivery-overview.msds-link-text',
                ),
                id: 'msds',
              };
              const notification =
                error.status === 404
                  ? NotificationActions.warningSnackbar(snackbarConfig)
                  : NotificationActions.errorSnackbar(snackbarConfig);

              return of(DeliveryDataActions.getMSDSFailed(), notification);
            }),
            startWith(
              NotificationActions.infoSnackbar({
                message: this.localeService.translate('delivery.delivery-overview.msds-loading'),
                buttonTitle: this.localeService.translate('cancel'),
                spinner: true,
              }),
            ),
            finalize(() => this.store.dispatch(NotificationActions.cancelSnackbar())),
          );
        } else {
          return of(NotificationActions.cancelSnackbar());
        }
      }),
    ),
  );

  snackbarClickButton = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.snackbarClickButton),
        tap(
          ({ id }) =>
            id === 'msds' &&
            window.open(
              'https://www.agrifirm.nl/over-ons/mijnagrifirm/msdsveiligheidsbladen-overzicht/',
            ),
        ),
      ),
    { dispatch: false },
  );

  getDeliveryReportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryDataActions.getDeliveryReportRequest),
      switchMap((data: { report: ReportRequest }) =>
        this.reportService.createReport(data.report).pipe(
          tap(this.blobService.download),
          map(() => DeliveryDataActions.getDeliveryReportSuccess()),
          startWith(
            NotificationActions.infoSnackbar({
              message: this.localeService.translate(
                'delivery.delivery-overview.export-downloading',
              ),
              spinner: true,
            }),
          ),
          catchError(() => {
            const message = this.localeService.translate(
              'delivery.delivery-overview.no-report-error',
            );
            return of(
              DeliveryDataActions.getDeliveryReportFailed(),
              NotificationActions.errorSnackbar({ message }),
            );
          }),
        ),
      ),
    ),
  );

  getQualityCertificateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryDataActions.getQualityCertificateRequest,
        NotificationActions.snackbarClickButton,
      ),
      switchMap((data) => {
        const itemId = 'itemId' in data && data.itemId;
        const shipToId = 'shipToId' in data && data.shipToId;
        const itemVLDoctype = 'itemVLDoctype' in data && data.itemVLDoctype;
        const system = 'system' in data && data.system;
        if (itemId && shipToId && itemVLDoctype && system) {
          return this.deliveryDataService
            .getQualityCertificate(itemId, shipToId, itemVLDoctype, system)
            .pipe(
              tap(this.blobService.download),
              map(() => DeliveryDataActions.getQualityCertificateSuccess()),
              catchError((error: HttpResponse<Blob>) => {
                const errorMessage =
                  error.status === 404
                    ? `delivery.delivery-overview.failed-to-download-pdf-404`
                    : `delivery.delivery-overview.failed-to-download-pdf-500`;

                return of(
                  DeliveryDataActions.getQualityCertificateFailed(),
                  NotificationActions.errorSnackbar({
                    message: this.localeService.translate(errorMessage),
                  }),
                );
              }),
              finalize(() => this.store.dispatch(NotificationActions.cancelSnackbar())),
            );
        } else {
          return of(NotificationActions.cancelSnackbar());
        }
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly deliveryDataService: DeliveryDataService,
    private readonly blobService: DownloadBlobService,
    private readonly reportService: ReportService,
    private readonly dateService: DateService,
    private readonly settingsService: SettingsService,
    private readonly localeService: LocaleService,
  ) {}
}
