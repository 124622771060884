import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ContractLine, ContractState } from '../models/contract-line.model';

export const getContractState = createFeatureSelector<ContractState>('contractModule');

export const getContractsState = createSelector(
  getContractState,
  (state: ContractState) => state.contracts,
);
export const getContracts = createSelector(
  getContractsState,
  (contracts: ContractLine[]) => contracts,
);
