import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AgrEnvironment, EnvironmentInjector } from '@next/core-lib/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

type ApplicationType = 'cultivation' | 'goat' | 'dairyCattle';

interface ApplicationInformation {
  applicationKey: string;
  organisationId: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgroVisionUrlService {
  private applicationKeys: { [key in ApplicationType]: ApplicationInformation } = {
    cultivation: {
      applicationKey: 'AgrifirmFocusCultivation',
      organisationId: '43908',
    },
    goat: {
      applicationKey: 'AgrifirmFocusGoat',
      organisationId: '46370',
    },
    dairyCattle: {
      applicationKey: 'AgrifirmFocusDairyCattleWeb',
      organisationId: '15944',
    },
  };

  private baseUrl = `${this.env.API_BASE_URL}/service/navigation-v2/secure/navigation/`;

  constructor(private http: HttpClient, @Inject(EnvironmentInjector) private env: AgrEnvironment) {}

  public getAgroVisionUrl = (
    applicationType: ApplicationType,
    customerId: number,
  ): Observable<URL | null> => {
    const requestUrl = this.constructNavigationServiceAgroVisionUrl(
      this.applicationKeys[applicationType],
      customerId,
    );

    return this.http
      .get(requestUrl.toString(), { responseType: 'text' })
      .pipe(map((responseUrl) => new URL(responseUrl)));
  };

  private constructNavigationServiceAgroVisionUrl = (
    { organisationId, applicationKey }: ApplicationInformation,
    customerId: number,
  ): URL =>
    new URL(
      `agrovision?orgId=${organisationId}&applicationKey=${applicationKey}&customerId=${customerId}`,
      this.baseUrl,
    );
}
