import { Component, OnInit } from '@angular/core';
import { loadContractsRequest } from './store/contract.actions';
import { Store } from '@ngrx/store';
import { RootState } from '@next/core-lib/app';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
})
export class ContractComponent implements OnInit {
  constructor(private readonly store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(loadContractsRequest());
  }
}
