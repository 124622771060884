<div class="spacing--padding-vertical">
  <mat-card class="mat-elevation-z1 recent-invoices">
    <div class="recent-invoices__header" mat-card-header>
      <h3 class="text--h6">{{ 'dashboard.recent-invoices.title' | translate }}</h3>
      <a class="text--small" routerLink="/invoice" (click)="setFilter()">
        {{ 'dashboard.recent-invoices.header-link' | translate }}
      </a>
    </div>
    <mat-card-content class="widget_content">
      <div class="recent-invoices">
        <agr-table
          [emptyDescription]="'dashboard.recent-invoices.error-message' | translate"
          [config]="tableConfig$ | async"
        >
        </agr-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
