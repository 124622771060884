import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RootState } from '@next/core-lib/app';
import {
  clearRecentDeliveriesRequest,
  loadRecentDeliveriesRequest,
} from '../../../delivery-data/store/delivery-data.actions';
import { getTopRecentDeliveriesWithDeliveryInformation } from '../../../delivery-data/store/delivery-data.selectors';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from '../../../delivery-data/delivery-data.model';
import { TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { LocaleService } from '@next/core-lib/i18n';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CartActions, CartSelectors } from '@next/core-lib/cart';
import { DeliveryDataService } from '../../../delivery-data/services/delivery-data.service';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from '../../../../environment';
import { SettingsActions, SettingsStorage } from '@next/core-lib/settings';
import { settingsContextName, SettingsKeys } from '../../../shared/models/shared.models';

@Component({
  selector: 'app-recent-deliveries',
  templateUrl: './recent-deliveries.component.html',
  styleUrls: ['./recent-deliveries.component.scss'],
})
export class RecentDeliveriesComponent implements OnInit, OnDestroy {
  @ViewChild('orderAgainColumn') orderAgainColumn: TemplateRef<unknown>;

  public tableConfig$: Observable<TableConfig> = this.store
    .select(getTopRecentDeliveriesWithDeliveryInformation(3))
    .pipe(
      map((deliveries) => ({
        dataSource: new MatTableDataSource(
          deliveries?.reduce((acc, value) => [...acc, value], []) ?? [],
        ),
        columns: [
          {
            id: DeliveryColumnTypes.deliveryDate,
            type: TableColumnTypes.DATE,
            label: `dashboard.recent-deliveries.${DeliveryColumnTypes.deliveryDate}`,
            headerClassName: 'flex--1-6',
            className: 'flex--1-6',
          },
          {
            id: DeliveryColumnTypes.externalInvoiceId,
            type: TableColumnTypes.STRING,
            label: `dashboard.recent-deliveries.${DeliveryColumnTypes.externalInvoiceId}`,
            headerClassName: 'flex--1-4',
            className: 'flex--1-4',
          },
          {
            id: DeliveryColumnTypes.externalDeliveryId,
            type: TableColumnTypes.STRING,
            label: `dashboard.recent-deliveries.${DeliveryColumnTypes.externalDeliveryId}`,
            headerClassName: 'flex--1-4',
            className: 'flex--1-4',
          },
          {
            id: DeliveryColumnTypes.productTitle,
            type: TableColumnTypes.STRING,
            label: `dashboard.recent-deliveries.${DeliveryColumnTypes.productTitle}`,
          },
        ],
        rowClickAction: {
          id: 'view',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: this.viewDelivery.bind(this),
        },
        actions: !!this.env.ORDER_AGAIN
          ? [
              {
                id: 'orderAgain',
                label: 'dashboard.recent-deliveries.order-again',
                icon: 'add_shopping_cart',
                callback: this.orderAgain.bind(this),
                disabled: this.orderAgainDisabled,
              },
            ]
          : [],
        pagination: false,
        primaryColumn: DeliveryColumnTypes.externalDeliveryId,
        primaryColumnPrefix: this.localeService.translate(
          'dashboard.recent-deliveries.primaryColumnPrefix',
        ),
        selectColumn: false,
        activeSort: {
          id: DeliveryColumnTypes.deliveryDate,
          start: 'desc',
          disableClear: false,
        },
      })),
    );

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    private readonly router: Router,
    private readonly deliveryDataService: DeliveryDataService,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadRecentDeliveriesRequest());
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearRecentDeliveriesRequest());
  }

  public setFilter(searchValue: string = ''): void {
    this.store.dispatch(
      SettingsActions.setSettings({
        settings: {
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: SettingsKeys.search,
          data: searchValue,
        },
      }),
    );
  }

  private orderAgain(deliveryLine: DeliveryLineWithDelivery) {
    this.store
      .select(CartSelectors.selectCurrentSalesOrderCart)
      .pipe(take(1))
      .subscribe((cart) => {
        this.store.dispatch(
          CartActions.addCartLineToCart({
            cartId: cart?.id ?? undefined,
            cartLine: this.deliveryDataService.formatOrderline(deliveryLine),
          }),
        );
      });
  }

  private viewDelivery(delivery: DeliveryLineWithDelivery) {
    this.setFilter(delivery.externalDeliveryId);
    this.router.navigate(['deliveries']);
  }

  private orderAgainDisabled(delivery: DeliveryLineWithDelivery) {
    return !delivery?.skuId;
  }
}
