import { Pipe, PipeTransform } from '@angular/core';
import { CustomerBrsNumber } from '../models/shared.models';
import { FormatLocationPipe } from '@next/core-lib/pipes';

@Pipe({
  name: 'formatBrsNumber',
})
export class FormatBrsNumberPipe implements PipeTransform {
  constructor(private readonly formatLocationPipe: FormatLocationPipe) {}

  transform(customerBrsNumber: CustomerBrsNumber): string {
    if (!customerBrsNumber) {
      return '';
    }

    return `${customerBrsNumber.brsNumber} - ${customerBrsNumber.relatedLocations
      .map((relatedLocation) => this.formatLocationPipe.transform(relatedLocation).trim())
      .join(', ')}`;
  }
}
