<div class="mineral-legal-notice spacing--padding-bottom spacing--padding-top">
  <agr-extended-notification
    notificationType="info"
    [title]="'mineral.mineral-legal-notice.title' | translate"
    [actionText]="getActionText()"
    (action)="toggleNotification()"
  >
    <div>
      <div *ngIf="showNotification">
        <p class="text--small">{{ 'mineral.mineral-legal-notice.paragraph-1' | translate }}</p>
        <p class="text--small">{{ 'mineral.mineral-legal-notice.paragraph-2' | translate }}</p>
        <p class="text--small">
          {{ 'mineral.mineral-legal-notice.paragraph-3-part-1' | translate }}
          <a class="mineral-legal-notice__url" href="mailto:klantenservice@agrifirm.com">
            klantenservice&#64;agrifirm.com </a
          >.
          {{ 'mineral.mineral-legal-notice.paragraph-3-part-2' | translate }}
          <a
            class="mineral-legal-notice__url"
            target="_blank"
            href="https://www.agrifirm.nl/over-ons/contact-locaties"
          >
            https://www.agrifirm.nl/over-ons/contact-locaties </a
          >.
        </p>
      </div>
    </div>
  </agr-extended-notification>
</div>
