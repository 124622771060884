import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from '../../../environment';
import { Observable, of, throwError } from 'rxjs';
import {
  PreferenceDeliveryMethod,
  PreferenceGroup,
  UserPreferenceSetting,
} from '../models/preferences.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationPreferencesService {
  protected baseUrl = `${this.env.API_BASE_URL}/service/notification-preferences/secure/user/preferences`;

  constructor(
    private readonly http: HttpClient,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  getPreferenceGroups(): Observable<PreferenceGroup[]> {
    return this.http.get<PreferenceGroup[]>(this.baseUrl).pipe(
      map((response) => response),
      catchError((error) => throwError(error)),
    );
  }

  patchPreference(preference: UserPreferenceSetting): Observable<PreferenceGroup[]> {
    return this.http
      .patch<PreferenceGroup[]>(
        `${this.baseUrl}/${preference.groupKey}/${preference.subGroupKey}/${preference.method}`,
        { isEnabled: preference.enabled } as PreferenceDeliveryMethod,
      )
      .pipe(
        map((response) => response),
        catchError((error) => {
          if (error.status === 404) {
            return of([] as PreferenceGroup[]);
          } else {
            return throwError(error);
          }
        }),
      );
  }
}
