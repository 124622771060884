import { RootState } from '@next/core-lib/app';
import { IdentifierData } from '@next/core-lib/components';
import { EntityState } from '@ngrx/entity';

export interface CustomerReference {
  id: number;
  externalCustomerId: string;
  customerName?: string;
}

export interface ShipToReference {
  id: number;
  externalShipToId: string;
  name?: string;
  shipToLegacies: [
    {
      id: string;
      legacyType: string;
    },
  ];
}

export interface Invoice {
  [key: string]:
    | string
    | number
    | Date
    | CustomerReference
    | ShipToReference
    | InvoiceLine[]
    | InvoiceVendor
    | IdentifierData;
  brutoAmount: number;
  collectionDate: Date;
  currencyKey: string;
  customer: CustomerReference;
  documentType: string;
  externalInvoiceId: string;
  id: number;
  invoiceDate: Date;
  invoiceDescription: string;
  invoiceLines: InvoiceLine[];
  nettoAmount: number;
  paymentTerm: string;
  shipTo: ShipToReference;
  vatAmount: number;
  vendor: InvoiceVendor;
  allShipToIds: IdentifierData;
}

export interface InvoiceLine {
  brutoAmount: number;
  externalProductId: string;
  id: string;
  nettoAmount: number;
  productTitle: string;
  unitQuantityType: string;
  unitsDelivered: number;
  baseUnitQuantity: number;
  baseUnitOfMeasure: string;
  vatAmount: number;
  vatPercentage: number;
}

export interface DetailedInvoice extends InvoiceLine {
  allShipToIds: IdentifierData;
  externalCustomerId: string;
  locationAddress: string;
  invoiceDate: Date;
  externalInvoiceId: string;
  currencyKey: string;
  shipTo: ShipToReference;
  invoiceDescription: string;
}

export interface InvoiceVendor {
  addressNumber: string;
  cc: string;
  city: string;
  country: string;
  iban: string;
  name: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
  swiftBic: string;
}

export interface InvoiceDataModuleState extends RootState {
  recentInvoices: EntityState<Invoice>;
  invoices: EntityState<Invoice>;
  invoiceTotals: InvoiceTotal[];
}

export interface GetInvoicesResponse {
  invoices: Invoice[];
}

export interface GetInvoiceTotalsResponse {
  invoiceTotals: InvoiceTotal[];
}

export interface InvoiceTotal {
  brutoAmount: number;
  currencyKey: string;
  externalProductId: string;
  nettoAmount: number;
  productTitle: string;
  unitQuantityType: string;
  unitsDelivered: number;
  baseUnitQuantity: number;
}

export enum InvoiceColumnTypes {
  invoiceDate = 'invoiceDate',
  externalInvoiceId = 'externalInvoiceId',
  allShipToIds = 'allShipToIds',
  externalProductId = 'externalProductId',
  invoiceDescription = 'invoiceDescription',
  brutoAmount = 'brutoAmount',
  productTitle = 'productTitle',
  unitsDelivered = 'unitsDelivered',
  unitQuantityType = 'unitQuantityType',
  baseUnitQuantity = 'baseUnitQuantity',
  baseUnitOfMeasure = 'baseUnitOfMeasure',
  externalCustomerId = 'externalCustomerId',
  locationAddress = 'locationAddress',
  nettoAmount = 'nettoAmount',
  vendor = 'vendor',
  harvestYear = 'harvestYear',
  currencyKey = 'currencyKey',
}

export type InvoiceColumn = keyof InvoiceTotal | InvoiceColumnTypes;
