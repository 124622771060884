import { AgrEnvironment, AgrProcessEnv, setAgrEnvironment } from '@next/core-lib/environment';

/* eslint-disable @typescript-eslint/naming-convention */
export class Environment extends AgrEnvironment {
  // add app specific environment variables here
  LEGACY_URL?: string;
  SHOP_URL?: string;
  MESTPLACE_URL?: string;
  GRAIN_REGISTRATION_URL?: string;
  ORDER_AGAIN?: boolean;
  IDENTIFICATION_TYPES_NL: string[];
  IDENTIFICATION_WARNING_PERIOD: string;
  RUSH_RETURN: boolean;
  USE_SHOP_FOR_CART: boolean;
  HELP_NL_URL?: string;
  REGENERATIVE_AGRICULTURE_WORKSPACE_ID: string;
  REGENERATIVE_AGRICULTURE_REPORT_ID: string;

  constructor(env: Environment) {
    super(env);
  }
}

export const setEnvironment = ({
  /* APP_SPECIFIC_ENV, */
  LEGACY_URL,
  SHOP_URL,
  MESTPLACE_URL,
  GRAIN_REGISTRATION_URL,
  ORDER_AGAIN,
  IDENTIFICATION_TYPES_NL,
  IDENTIFICATION_WARNING_PERIOD,
  RUSH_RETURN,
  USE_SHOP_FOR_CART,
  HELP_NL_URL,
  REGENERATIVE_AGRICULTURE_WORKSPACE_ID,
  REGENERATIVE_AGRICULTURE_REPORT_ID,
  ...env
}: AgrProcessEnv): Environment =>
  new Environment({
    /* APP_SPECIFIC_ENV, */
    /* APP_SPECIFIC_ENV: ..., */
    ...setAgrEnvironment(env),
    LEGACY_URL,
    SHOP_URL,
    MESTPLACE_URL,
    GRAIN_REGISTRATION_URL,
    ORDER_AGAIN: ORDER_AGAIN === 'true',
    IDENTIFICATION_TYPES_NL: IDENTIFICATION_TYPES_NL?.split(','),
    IDENTIFICATION_WARNING_PERIOD: IDENTIFICATION_WARNING_PERIOD || 'P21D',
    RUSH_RETURN: RUSH_RETURN === 'true',
    USE_SHOP_FOR_CART: USE_SHOP_FOR_CART === 'true',
    HELP_NL_URL,
    REGENERATIVE_AGRICULTURE_WORKSPACE_ID,
    REGENERATIVE_AGRICULTURE_REPORT_ID,
  });
/* eslint-enable @typescript-eslint/naming-convention */
