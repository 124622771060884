import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomerGuard } from '@next/core-lib/relationships';
import { GreenhouseComponent } from './greenhouse.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'greenhouse',
        component: GreenhouseComponent,
        canActivate: [CustomerGuard],
      },
    ]),
  ],
})
export class GreenhouseRoutingModule {}
