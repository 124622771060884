import { AbstractControl, ValidationErrors } from '@angular/forms';

export const maxFileSize =
  (sizeInBytes: number) =>
  (control: AbstractControl): ValidationErrors | null => {
    const file = control.value as File;
    if (!file) {
      // Required check should be handled by a different validator
      return;
    }

    if (file.size > sizeInBytes) {
      return { maxFileSize: true };
    }

    return null;
  };
