import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgrPowerBiEmbeddedModule } from '@next/core-lib/powerbi-embedded';
import { SharedModule } from '../shared/shared.module';
import { RegenerativeAgricultureRoutingModule } from './regenerative-agriculture-router.module';
import { RegenerativeAgricultureComponent } from './regenerative-agriculture.component';

@NgModule({
  declarations: [RegenerativeAgricultureComponent],
  imports: [
    CommonModule,
    SharedModule,
    RegenerativeAgricultureRoutingModule,
    RouterModule,
    AgrPowerBiEmbeddedModule,
  ],
})
export class RegenerativeAgricultureModule {}
