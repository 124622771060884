import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Environment } from '../../../environment';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { ContractLine } from '../models/contract-line.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private readonly baseUrl = `${this.env.API_BASE_URL}/service/contract/secure/contract`;

  constructor(
    private readonly http: HttpClient,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  getContracts(): Observable<ContractLine[]> {
    return this.http.get<GetContractsResponse>(`${this.baseUrl}/all`, {}).pipe(
      map((response) => response.contracts),
      catchError((error) => {
        if (error.status === 404) {
          return of([] as ContractLine[]);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  public getContractDocument(contractId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.baseUrl}/${contractId}/document`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}

interface GetContractsResponse {
  contracts: ContractLine[];
}
