import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimZeroes',
})
export class TrimZeroesPipe implements PipeTransform {
  transform(value: string): string {
    return (+value).toString();
  }
}
