import { Injectable } from '@angular/core';
import { FeatureFlagActions } from '@next/core-lib/featureflag';
import { I18nActions, I18nSelectors } from '@next/core-lib/i18n';
import { NavigationActions } from '@next/core-lib/navigation';
import {
  AccessRoleTypes,
  Location,
  ShipToIdentification,
  UserActions,
  UserSelectors,
} from '@next/core-lib/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CustomerBrsNumber } from '../shared/models/shared.models';
import { NavigationItemsService } from '../shared/services/navigation-items.service';
import * as AppActions from './actions';

@Injectable()
export class AppEffects {
  getUserNavigationItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.customerInitialized,
        I18nActions.localeInitialized,
        UserActions.customerChanged,
        FeatureFlagActions.getFeatureFlagsRequestSuccess,
      ),
      debounceTime(200),
      withLatestFrom(this.store.select(UserSelectors.selectUserApplications)),
      map((action) =>
        action[1]?.filter(
          (userApplication) => userApplication.RoleKey !== AccessRoleTypes.NotAuthorized,
        ),
      ),
      withLatestFrom(this.store.select(I18nSelectors.selectLocale)),
      mergeMap(([authorizedUserApplications, selectedLocale]) =>
        this.navigationItemsService.getMatchingNavigationItems(
          authorizedUserApplications,
          selectedLocale,
        ),
      ),
      map((navigationItems) =>
        NavigationActions.setNavigationItems({
          navigationItems,
        }),
      ),
    ),
  );

  getCustomerBrsNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.customerInitialized, UserActions.customerChanged),
      debounceTime(200),
      withLatestFrom(this.store.select(UserSelectors.selectCustomer)),
      map(([_, customer]) =>
        AppActions.getCustomerBrsNumbersSuccess({
          customerBrsNumbers: customer?.shipTos
            .map((location) =>
              location.identifications
                ?.filter((identification) => identification.name === ShipToIdentification.ZBRS01)
                .reduce(
                  (acc, identification) => [
                    ...acc,
                    {
                      relatedLocation: location,
                      brsNumber: identification.value,
                    },
                  ],
                  [],
                ),
            )
            .flat()
            .reduce((acc, customerBrsNumber) => {
              const existing = acc.find(
                (accObject: CustomerBrsNumber) =>
                  accObject.brsNumber === customerBrsNumber.brsNumber,
              );

              if (existing) {
                const existingLocation = existing.relatedLocations.some(
                  (location: Location) =>
                    location.externalId === customerBrsNumber.relatedLocation.externalId,
                );

                if (!existingLocation) {
                  acc
                    .find(
                      (accObject: CustomerBrsNumber) =>
                        accObject.brsNumber === customerBrsNumber.brsNumber,
                    )
                    .relatedLocations.push(customerBrsNumber.relatedLocation);
                }
              } else {
                acc = [
                  ...acc,
                  {
                    relatedLocations: [customerBrsNumber.relatedLocation],
                    brsNumber: customerBrsNumber.brsNumber,
                  },
                ];
              }

              return acc;
            }, [])
            .sort((o1: CustomerBrsNumber, o2: CustomerBrsNumber) =>
              o1.brsNumber > o2.brsNumber ? 1 : -1,
            ),
        }),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly navigationItemsService: NavigationItemsService,
  ) {}

  // private readonly elasticApiService: ElasticApiService,
}
