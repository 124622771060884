import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RootState } from '@next/core-lib/app';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { FormatLocationPipe } from '@next/core-lib/pipes';
import { Settings, SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { TableColumnTypes } from '@next/core-lib/table';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, take } from 'rxjs/operators';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';
import { DeliveryDataService } from 'src/app/delivery-data/services/delivery-data.service';
import { getDeliveryReportRequest } from 'src/app/delivery-data/store/delivery-data.actions';
import { settingsContextName, SettingsKeys } from 'src/app/shared/models/shared.models';
import { FormatBrsNumberPipe } from 'src/app/shared/pipes/format-brs-number.pipe';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import { selectCustomerBrsNumbers } from 'src/app/store/selectors';
import { BexOverviewDataSource } from '../../datasource/bex-overview.datasource';
import {
  BexOverviewDataFilters,
  BexOverviewViewModel,
  displayColumns,
} from '../models/overview.model';
import { DateService } from 'src/app/shared/services/date.service';
import { DaterangeConfig } from '@next/core-lib/components';
import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';

@Component({
  selector: 'app-bex-overview',
  templateUrl: './bex-overview.component.html',
  styleUrls: ['./bex-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FormatBrsNumberPipe, FormatLocationPipe],
})
export class BexOverviewComponent implements OnInit, OnDestroy {
  readonly dataSource = new BexOverviewDataSource(this._deliveryDataService);
  readonly displayColumns = displayColumns;
  readonly subscriptions: Subscription[] = [];

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(),
    brsNumber: new FormControl(),
    dateRange: new FormControl(),
  });

  readonly showTotalsToggle = new FormControl();

  readonly exportOptions: ExportOptionsV2;

  readonly brsNumbers$ = this.store.select(selectCustomerBrsNumbers).pipe(
    skipWhile((data) => !data?.length),
    distinctUntilChanged(
      (a, b) => a.length === b.length && a.every((p, index) => p.brsNumber === b[index].brsNumber),
    ),
  );

  readonly dateRangeConfig = this.dateService.createDateConfigWithCalendarYearOptions();

  public brsOptions$ = this.brsNumbers$.pipe(
    map((brsNumbers) =>
      brsNumbers.map((customerBrsNumber) => ({
        value: customerBrsNumber,
        viewValue: this.formatBrsNumberPipe.transform(customerBrsNumber),
      })),
    ),
  );

  constructor(
    private readonly _deliveryDataService: DeliveryDataService,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
    private readonly store: Store<RootState>,
    private readonly formatBrsNumberPipe: FormatBrsNumberPipe,
    private readonly dateService: DateService,
  ) {
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
        {
          name: 'PDF',
          export: (data: BexOverviewViewModel[]) => this.downloadPdf(data),
        },
      ],
      fileName: this.localeService.translate('bex.bex-overview.fileName'),
      columns: displayColumns.map((col) => ({
        id: col,
        name: this.localeService.translate(`bex.bex-overview.${col}`),
      })),
    };

    this.subscriptions.push(
      this.settingsService
        .getSettings({
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: SettingsKeys.dateRange,
        })
        .pipe(take(1))
        .subscribe((settings) => {
          const dateRange = this.getDateRange(settings);
          const filters = this.searchAndFilters.value as BexOverviewDataFilters;

          filters.dateRange = dateRange;
          this.dateRangeConfig.config.initial = dateRange;
        }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchAndFilters.valueChanges.subscribe(() => {
        const searchFilters = this.searchAndFilters.value as BexOverviewDataFilters;
        const { dateRange } = searchFilters;
        this.dataSource.applyFilters(searchFilters);

        this.settingsService
          .setSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.dateRange,
            data: {
              startDate: dateRange.startDate.toISOString(),
              endDate: dateRange.endDate.toISOString(),
              description: dateRange.description,
            },
          })
          .subscribe((_) => {});
      }),
    );

    this.subscriptions.push(
      this.showTotalsToggle.valueChanges.subscribe((showTotals) =>
        this.settingsService
          .setSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.totals,
            data: showTotals,
          })
          .pipe(take(1))
          .subscribe((_) => {}),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  isEqual(a: BexOverviewViewModel, b: BexOverviewViewModel) {
    return a.id === b.id && a.deliveryId === b.deliveryId;
  }

  resetFilters() {
    const filters: BexOverviewDataFilters = {
      search: null,
      brsNumber: null,
      dateRange: new DaterangeConfig().config.initial,
    };

    this.searchAndFilters.reset(filters);
  }

  getTableColumnType(dCol: DeliveryColumnTypes) {
    return dCol === 'deliveryDate'
      ? TableColumnTypes.DATE
      : [
          'unitQuantity',
          'vem',
          'roughProtein',
          'nitrogen',
          'phosphorus',
          'dryMatterAmount',
          'roughAshes',
        ].includes(dCol)
      ? TableColumnTypes.NUMBER
      : TableColumnTypes.STRING;
  }

  private downloadPdf = (data: BexOverviewViewModel[]) =>
    combineLatest([
      of(this.searchAndFilters.value as BexOverviewDataFilters),
      this.store.select(UserSelectors.selectCustomer),
      this.dataSource.order$,
    ])
      .pipe(take(1))
      .subscribe(([filters, customer, order]) => {
        const startDate = filters.dateRange.startDate;
        const endDate = filters.dateRange.endDate;
        data.sort((objectA, objectB) => {
          const propertyA = objectA[order.column as keyof BexOverviewViewModel];
          const propertyB = objectB[order.column as keyof BexOverviewViewModel];
          const result = propertyA > propertyB ? 1 : propertyA < propertyB ? -1 : 0;
          return order.descending ? -result : result;
        });

        this.store.dispatch(
          getDeliveryReportRequest({
            report: {
              title: this.localeService.translate('bex.title-details'),
              subTitle: `${this.localeService.translate(
                'bex.report.sub-title',
              )} ${this._deliveryDataService.formatDateTime(new Date())}`,
              fileName: this._deliveryDataService.formatExportFileName(
                this.localeService.translate('bex.bex-overview.fileName'),
                customer.externalId,
                startDate,
                endDate,
              ),
              legend: {
                [this.localeService.translate(
                  'bex.report.legend.customer',
                )]: `${customer.name} (${customer.externalId})`,
                [this.localeService.translate('bex.report.legend.brs-number')]:
                  this.formatBrsNumberPipe.transform(filters.brsNumber),
                [this.localeService.translate('bex.report.legend.start-date')]:
                  this._deliveryDataService.formatDateTime(startDate),
                [this.localeService.translate('bex.report.legend.end-date')]:
                  this._deliveryDataService.formatDateTime(endDate),
              },
              tableHeadings: this.exportOptions.columns.map((col) => col.name) ?? [],
              tableFooter: [
                this.localeService.translate('bex.disclaimer1'),
                this.localeService.translate('bex.disclaimer2'),
                this.localeService.translate('bex.disclaimer3'),
                this.localeService.translate('bex.disclaimer4'),
              ],
              firstPageRowsPerPage: 32,
              rowsPerPage: 47,
              contactInformation: {
                email: this.localeService.translate('bex.report.contact.email'),
                phoneNumber: this.localeService.translate('bex.report.contact.phone-number'),
              },
              rows: this._deliveryDataService.mapToPdfRows(
                this.displayColumns.map((dCol) => ({
                  id: dCol,
                  type: this.getTableColumnType(dCol),
                  digitsInfo: '1.0-2',
                })),
                data as unknown as DeliveryLineWithDelivery[],
              ),
            },
          }),
        );
      });

  private getDateRange(settings: Settings[]): DateRange {
    const selectedDateRange = settings?.find((setting) =>
      (setting.settingsId + '').includes(SettingsKeys.dateRange),
    )?.data as { [key: string]: string };

    const startDate = selectedDateRange?.startDate
      ? new Date(selectedDateRange.startDate)
      : this.dateService.defaultRecentStartDate();

    const endDate = selectedDateRange?.endDate
      ? new Date(selectedDateRange.endDate)
      : this.dateService.defaultRecentEndDate();

    return {
      startDate,
      endDate,
      description: selectedDateRange?.description,
    };
  }
}
