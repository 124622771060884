import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomerGuard } from '@next/core-lib/relationships';
import { IdentificationComponent } from './identification.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'identification',
        component: IdentificationComponent,
        canActivate: [CustomerGuard],
      },
    ]),
  ],
})
export class IdentificationRoutingModule {}
