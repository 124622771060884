import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocaleService } from '@next/core-lib/i18n';
import { NotificationActions } from '@next/core-lib/notification';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap, map, switchMap, finalize, tap, catchError } from 'rxjs/operators';
import { DownloadBlobService } from 'src/app/shared/services/download-blob.service';
import { ContractService } from '../services/contract.service';
import * as ContractActions from './contract.actions';

@Injectable()
export class ContractEffects {
  loadCycles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.loadContractsRequest),
      mergeMap(() =>
        this.api
          .getContracts()
          .pipe(map((contracts) => ContractActions.loadContractsSuccess({ contracts }))),
      ),
    ),
  );

  getContractDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.getContractDocumentRequest, NotificationActions.snackbarClickButton),
      switchMap((data) => {
        const contractId = 'contractId' in data && data.contractId;
        if (contractId) {
          return this.api.getContractDocument(contractId).pipe(
            tap(this.blobService.download),
            map(() => ContractActions.getContractDocumentSuccess()),
            catchError((error: HttpResponse<Blob>) => {
              const errorMessage =
                error.status === 404
                  ? `contract.contract-overview.failed-to-download-pdf-404`
                  : `contract.contract-overview.failed-to-download-pdf-500`;

              return of(
                ContractActions.getContractDocumentFailed(),
                NotificationActions.errorSnackbar({
                  message: this.localeService.translate(errorMessage),
                }),
              );
            }),
            finalize(() => this.store.dispatch(NotificationActions.cancelSnackbar())),
          );
        } else {
          return of(NotificationActions.cancelSnackbar());
        }
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: ContractService,
    private readonly store: Store,
    private readonly blobService: DownloadBlobService,
    private readonly localeService: LocaleService,
  ) {}
}
