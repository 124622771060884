import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RootState } from '@next/core-lib/app';
import { FilterAction } from '@next/core-lib/components';
import { LocaleService } from '@next/core-lib/i18n';
import { TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InvoiceColumnTypes, InvoiceTotal } from '../../../invoice-data/invoice-data.model';
import {
  clearInvoicesRequest,
  loadInvoiceTotalsRequest,
} from '../../../invoice-data/store/invoice-data.actions';
import { selectPlainInvoiceTotals } from '../../../invoice-data/store/invoice-data.selectors';

@Component({
  selector: 'app-invoice-detailed-overview',
  templateUrl: './invoice-detailed-overview.component.html',
  styleUrls: [],
})
export class InvoiceDetailedOverviewComponent implements OnInit, OnDestroy {
  tableConfig: TableConfig = {
    dataSource: new MatTableDataSource([]),
    columns: [
      {
        id: InvoiceColumnTypes.externalProductId,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.externalProductId}`,
        className: 'flex__child--1',
        headerClassName: 'flex__child--1',
      },
      {
        id: InvoiceColumnTypes.productTitle,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.productTitle}`,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
      {
        id: InvoiceColumnTypes.unitsDelivered,
        type: TableColumnTypes.NUMBER,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.unitsDelivered}`,
        className: 'flex__child--4',
        headerClassName: 'flex__child--4',
      },
      {
        id: InvoiceColumnTypes.unitQuantityType,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.unitQuantityType}`,
      },
      {
        id: InvoiceColumnTypes.baseUnitQuantity,
        type: TableColumnTypes.NUMBER,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.baseUnitQuantity}`,
        className: 'flex__child--4',
        headerClassName: 'flex__child--4',
      },
      {
        id: InvoiceColumnTypes.baseUnitOfMeasure,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.baseUnitOfMeasure}`,
      },
      {
        id: InvoiceColumnTypes.brutoAmount,
        type: TableColumnTypes.CURRENCY,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.brutoAmount}`,
        currencyKeyColumn: InvoiceColumnTypes.currencyKey,
        className: 'flex__child--2',
        headerClassName: 'flex__child--2',
      },
    ],
    pagination: true,
    scrolling: true,
    width: '900px',
    wrapperWidth: '100%',
    primaryColumn: InvoiceColumnTypes.externalProductId,
    activeSort: {
      id: InvoiceColumnTypes.externalProductId,
      start: 'desc',
      disableClear: false,
    },
    actionColumn: false,
  };

  data$: Observable<InvoiceTotal[]>;

  exportFilename = `${this.localeService.translate('invoice.invoice-totals.fileName')}`;

  filterActions: FilterAction[] = [
    {
      icon: 'file_download',
      viewValue: 'Excel',
      value: 'xlsx',
    },
    {
      icon: 'file_download',
      viewValue: 'CSV',
      value: 'csv',
    },
  ];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
  ) {}

  ngOnInit(): void {
    this.data$ = this.store.select(selectPlainInvoiceTotals).pipe(
      map((invoices: InvoiceTotal[]) =>
        invoices.map((i: InvoiceTotal) => ({
          ...i,
          unitsDelivered: Math.round(i.unitsDelivered),
          baseUnitQuantity: Math.round(i.baseUnitQuantity),
          unitQuantityType: i.unitQuantityType
            ? this.localeService.translate(`unit.${i.unitQuantityType}`)
            : '',
        })),
      ),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearInvoicesRequest());

    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.store.dispatch(loadInvoiceTotalsRequest());
  }
}
