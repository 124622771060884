import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '@next/core-lib/app';
import { loadPreferenceGroupsRequest } from './store/preferences.actions';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  constructor(private readonly store: Store<RootState>) {}

  ngOnInit(): void {
    this.store.dispatch(loadPreferenceGroupsRequest());
  }
}
