import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RootState } from '@next/core-lib/app';
import { DaterangeConfig } from '@next/core-lib/components';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { TableColumnTypes } from '@next/core-lib/table';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subscription, take } from 'rxjs';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';
import { DeliveryDataService } from 'src/app/delivery-data/services/delivery-data.service';
import { getDeliveryReportRequest } from 'src/app/delivery-data/store/delivery-data.actions';
import { DateService } from 'src/app/shared/services/date.service';
import { GreenhouseDataSource } from '../../datasource/greenhouse.datasource';
import {
  displayColumns,
  GreenhouseDataFilters,
  GreenhouseViewModel,
} from '../../models/greenhouse.model';

@Component({
  selector: 'app-greenhouse-overview',
  templateUrl: './greenhouse-overview.component.html',
  styleUrls: ['./greenhouse-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GreenhouseOverviewComponent implements OnInit, OnDestroy {
  readonly dataSource = new GreenhouseDataSource(this._deliveryDataService);
  readonly displayColumns = displayColumns;
  readonly exportOptions: ExportOptionsV2;
  readonly dateRangeConfig = this.dateService.createDateConfigWithCalendarYearOptions();
  readonly subscriptions: Subscription[] = [];

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(),
    locations: new FormControl(),
    dateRange: new FormControl(),
  });

  constructor(
    private readonly _deliveryDataService: DeliveryDataService,
    private readonly dateService: DateService,
    private readonly localeService: LocaleService,
    private readonly store: Store<RootState>,
  ) {
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
        {
          name: 'PDF',
          export: (data: GreenhouseViewModel[]) => this.downloadPdf(data),
        },
      ],
      fileName: this.localeService.translate('greenhouse.greenhouse-overview.fileName'),
      columns: displayColumns.map((col) => ({
        id: col,
        name: this.localeService.translate(`greenhouse.greenhouse-overview.${col}`),
      })),
    };
  }
  downloadPdf(data: GreenhouseViewModel[]): void {
    combineLatest([
      of(this.searchAndFilters.value as GreenhouseDataFilters),
      this.store.select(UserSelectors.selectCustomer),
      this.dataSource._order$,
    ])
      .pipe(take(1))
      .subscribe(([filters, customer, order]) => {
        const startDate = filters.dateRange.startDate;
        const endDate = filters.dateRange.endDate;
        data.sort((objectA, objectB) => {
          const propertyA = objectA[order.column as keyof GreenhouseViewModel];
          const propertyB = objectB[order.column as keyof GreenhouseViewModel];
          const result = propertyA > propertyB ? 1 : propertyA < propertyB ? -1 : 0;
          return order.descending ? -result : result;
        });

        this.store.dispatch(
          getDeliveryReportRequest({
            report: {
              title: this.localeService.translate('greenhouse.title-details'),
              subTitle: `${this.localeService.translate(
                'greenhouse.report.sub-title',
              )} ${this._deliveryDataService.formatDateTime(new Date())}`,
              fileName: this._deliveryDataService.formatExportFileName(
                this.localeService.translate('greenhouse.greenhouse-overview.fileName'),
                customer.externalId,
                startDate,
                endDate,
              ),
              legend: {
                [this.localeService.translate(
                  'greenhouse.report.legend.customer',
                )]: `${customer.name} (${customer.externalId})`,
                [this.localeService.translate('greenhouse.report.legend.start-date')]:
                  this._deliveryDataService.formatDateTime(startDate),
                [this.localeService.translate('greenhouse.report.legend.end-date')]:
                  this._deliveryDataService.formatDateTime(endDate),
              },
              tableHeadings: this.exportOptions.columns.map((col) => col.name) ?? [],
              tableFooter: [],
              firstPageRowsPerPage: 31,
              rowsPerPage: 45,
              contactInformation: {
                email: this.localeService.translate('greenhouse.report.contact.email'),
                phoneNumber: this.localeService.translate('greenhouse.report.contact.phone-number'),
              },
              rows: this._deliveryDataService.mapToPdfRows(
                this.displayColumns.map((dCol) => ({
                  id: dCol,
                  type: this.getTableColumnType(dCol),
                  digitsInfo: '1.0-2',
                })),
                data as unknown as DeliveryLineWithDelivery[],
              ),
            },
          }),
        );
      });
  }

  getTableColumnType(dCol: DeliveryColumnTypes) {
    return dCol === 'deliveryDate'
      ? TableColumnTypes.DATE
      : [
          'unitQuantity',
          'emissionFactorPercent00',
          'emissionFactorPercent40',
          'emissionFactorPercent80',
          'co2Total',
          'fossilExcludingPeatEmissions',
          'fossilPeatEmissions',
          'biogenic',
          'landUseChange',
        ].includes(dCol)
      ? TableColumnTypes.NUMBER
      : TableColumnTypes.STRING;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchAndFilters.valueChanges.subscribe(() =>
        this.dataSource.applyFilters(this.searchAndFilters.value as GreenhouseDataFilters),
      ),
    );
  }

  resetFilters() {
    this.searchAndFilters.reset({
      search: null,
      locations: null,
      dateRange: new DaterangeConfig().config.initial,
    });
  }

  isEqual(a: GreenhouseViewModel, b: GreenhouseViewModel) {
    return a.id === b.id && a.deliveryId === b.deliveryId;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
