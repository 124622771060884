import { createSelector, createFeatureSelector } from '@ngrx/store';
import { preferenceGroupAdapter } from './preferences.reducer';
import { PreferencesState } from '../models/preferences.model';

const { selectEntities, selectAll } = preferenceGroupAdapter.getSelectors();

export const getPreferencesState = createFeatureSelector<PreferencesState>('preferencesModule');

export const getPreferenceGroupsState = createSelector(
  getPreferencesState,
  (state) => state.preferenceGroups,
);

export const getPreferenceGroupEntities = createSelector(getPreferenceGroupsState, selectEntities);

export const getPreferenceGroups = createSelector(getPreferenceGroupsState, selectAll);
