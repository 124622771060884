import { RootState } from '@next/core-lib/app';
import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';

export interface GreenhouseModuleState extends RootState {
  greenhouseNoticeVisible: boolean;
}

export const displayColumns = [
  DeliveryColumnTypes.deliveryDate,
  DeliveryColumnTypes.allShipToIds,
  DeliveryColumnTypes.externalProductId,
  DeliveryColumnTypes.productTitle,
  DeliveryColumnTypes.klwArticleGroupName,
  DeliveryColumnTypes.unitQuantity,
  DeliveryColumnTypes.emissionFactorPercent00,
  DeliveryColumnTypes.emissionFactorPercent40,
  DeliveryColumnTypes.emissionFactorPercent80,
  DeliveryColumnTypes.co2Total,
  DeliveryColumnTypes.fossilExcludingPeatEmissions,
  DeliveryColumnTypes.fossilPeatEmissions,
  DeliveryColumnTypes.biogenic,
  DeliveryColumnTypes.landUseChange,
] as const;

export type GreenhouseViewModel = Pick<
  DeliveryLineWithDelivery,
  (typeof displayColumns)[number] | 'id' | 'deliveryId'
>;

export interface GreenhouseDataFilters {
  locations: number[];
  search: string;
  dateRange: DateRange;
}
