import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { DeliveryDataService } from './services/delivery-data.service';
import { deliveryDataReducer } from './store/delivery-data.reducer';
import { DeliveryDataEffects } from './store/delivery-data.effects';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { DownloadBlobService } from '../shared/services/download-blob.service';
import { LocaleService } from '@next/core-lib/i18n';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    PageTableFilterModule,
    StoreModule.forFeature('deliveryDataModule', deliveryDataReducer),
    EffectsModule.forFeature([DeliveryDataEffects]),
  ],
  providers: [DeliveryDataService, DownloadBlobService, LocaleService],
})
export class DeliveryDataModule {}
