import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferencesComponent } from './preferences.component';
import { SharedModule } from '../shared/shared.module';
import { PreferencesRoutingModule } from './preferences-routing.module';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { preferencesReducer } from './store/preferences.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PreferencesEffects } from './store/preferences.effects';
import { PreferencesOverviewComponent } from './components/preferences-overview/preferences-overview.component';
import { MatDividerModule } from '@angular/material/divider';
import { AgrNotificationEmptyModule } from '@next/core-lib/components';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PreferencesComponent, PreferencesOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    PreferencesRoutingModule,
    RouterModule,
    StoreModule.forFeature('preferencesModule', preferencesReducer),
    EffectsModule.forFeature([PreferencesEffects]),
    MatDividerModule,
    AgrNotificationEmptyModule,
    MatSlideToggleModule,
    FormsModule,
  ],
})
export class PreferencesModule {}
