import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CoreActions } from '@next/core-lib';
import { RootState } from '@next/core-lib/app';
import { CartLine } from '@next/core-lib/cart';
import { IdentifierData } from '@next/core-lib/components';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { LocaleService } from '@next/core-lib/i18n';
import { NotificationActions } from '@next/core-lib/notification';
import { TableColumn, TableColumnTypes } from '@next/core-lib/table';
import { Customer, Location, SelectedLocation, UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { DeliveryOverviewViewModel } from 'src/app/delivery/models/delivery.model';
import { Environment } from '../../../environment';
import {
  ColTypes,
  HierarchicalData,
  HierarchicalDataColumn,
  RowTypes,
} from '../../shared/hierarchical-data/hierarchical-data.model';
import {
  Delivery,
  DeliveryCategories,
  DeliveryColumn,
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
  DeliveryTotals,
  GetDeliveriesResponse,
  GetDeliveryTotalsResponse,
} from '../delivery-data.model';
import {
  OrderOptions,
  PageAndSize,
  Paginated,
  SearchOptions,
} from '../models/paginated-deliveries.model';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDataService {
  protected baseUrl = `${this.env.API_BASE_URL}/service/delivery-aggregation/secure`;
  private readonly customer$ = this.store.select(UserSelectors.selectCustomer);

  private readonly brsNumberCategories = [DeliveryCategories.mineral, DeliveryCategories.bex];

  constructor(
    private readonly http: HttpClient,
    @Inject(EnvironmentInjector) private readonly env: Environment,
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
  ) {}

  public getDeliveriesPaginated(
    startDate: Date,
    endDate: Date,
    paginationOptions: PageAndSize,
    search?: SearchOptions,
    shipToIds?: number[],
    order?: OrderOptions,
    category = DeliveryCategories.none,
    externalBrsNR?: string,
    isExport: boolean = false,
  ): Observable<Paginated<Delivery>> {
    const { pageIndex, pageSize } = paginationOptions;

    return this.customer$.pipe(
      skipWhile((customer) => !customer && !shipToIds),
      take(1),
      map((customer) =>
        shipToIds?.length ? shipToIds : customer.shipTos.map((shipTo) => shipTo.id),
      ),
      tap((_) =>
        isExport ? this.store.dispatch(CoreActions.setLoading({ loading: true })) : undefined,
      ),
      map((_shipToIds: number[]) => ({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        pageIndex,
        pageSize,
        shipToIds: _shipToIds,
        category,
        order,
        search,
        externalBrsNR,
      })),
      switchMap((body) => this.http.post<Paginated<Delivery>>(`${this.baseUrl}/deliveries`, body)),
      tap((_) =>
        isExport ? this.store.dispatch(CoreActions.setLoading({ loading: false })) : undefined,
      ),
      catchError((error) => {
        this.store.dispatch(NotificationActions.errorSnackbar({ message: error.message }));
        if (isExport) {
          this.store.dispatch(CoreActions.setLoading({ loading: false }));
        }
        return of({
          metadata: {
            currentPage: 0,
            pageSize: 0,
            totalResultCount: 0,
            totalPages: 0,
          },
          results: [],
        });
      }),
      shareReplay(1),
    );
  }

  public getDeliveries(
    startDate: string,
    endDate: string,
    category?: DeliveryCategories,
  ): Observable<Delivery[]> {
    return this.customer$.pipe(
      skipWhile((customerId) => !customerId),
      take(1),
      mergeMap((userCustomer: Customer) => {
        if (!userCustomer) {
          return of([] as Delivery[]);
        }

        return this.http
          .get<GetDeliveriesResponse>(`${this.baseUrl}/deliveries`, {
            params: {
              StartDate: startDate, // eslint-disable-line @typescript-eslint/naming-convention
              EndDate: endDate, // eslint-disable-line @typescript-eslint/naming-convention
              CustomerId: userCustomer?.id + '', // eslint-disable-line @typescript-eslint/naming-convention
              ShipToIds: userCustomer?.shipTos?.map((shipTo) => shipTo.id + ''), // eslint-disable-line @typescript-eslint/naming-convention, max-len
              ...(!!category ? { Category: category } : {}), // eslint-disable-line @typescript-eslint/naming-convention
            },
          })
          .pipe(
            map((response: GetDeliveriesResponse) => response.deliveries),
            catchError((error: HttpErrorResponse) => {
              this.store.dispatch(NotificationActions.errorSnackbar({ message: error.message }));
              return of([] as Delivery[]);
            }),
          );
      }),
    );
  }

  public getDeliveryTotals(
    startDate: string,
    endDate: string,
    category: DeliveryCategories,
    brsNumber?: string,
  ): Observable<DeliveryTotals> {
    return this.customer$.pipe(
      skipWhile((customerId) => !customerId),
      take(1),
      mergeMap((userCustomer: Customer) => {
        if (!userCustomer) {
          return of({
            groupedProductCategories: [],
            shipToTotals: [],
          } as DeliveryTotals);
        }

        return this.http
          .get<GetDeliveryTotalsResponse>(`${this.baseUrl}/deliveries/totals`, {
            params: {
              StartDate: startDate, // eslint-disable-line @typescript-eslint/naming-convention
              EndDate: endDate, // eslint-disable-line @typescript-eslint/naming-convention
              CustomerId: userCustomer.id + '', // eslint-disable-line @typescript-eslint/naming-convention
              Category: category, // eslint-disable-line @typescript-eslint/naming-convention
              ...(!!brsNumber && this.brsNumberCategories.includes(category)
                ? { BrsNumber: brsNumber } // eslint-disable-line @typescript-eslint/naming-convention
                : {}),
            },
          })
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.store.dispatch(NotificationActions.errorSnackbar({ message: error.message }));
              return of({
                groupedProductCategories: [],
                shipToTotals: [],
              } as DeliveryTotals);
            }),
          );
      }),
    );
  }

  public getDeliveryNote(ids: number[], shipToIds: number[]): Observable<HttpResponse<Blob>> {
    return this.customer$.pipe(
      skipWhile((customerId) => !customerId),
      take(1),
      mergeMap((userCustomer: Customer) => {
        if (!userCustomer) {
          return of({} as HttpResponse<Blob>);
        }

        return this.http.get(
          `${this.env.API_BASE_URL}/service/document/secure/document?DocumentType=Delivery`,
          {
            params: {
              Ids: ids.map((id) => id + ''), // eslint-disable-line @typescript-eslint/naming-convention
              shipToIds,
            },
            responseType: 'blob',
            observe: 'response',
          },
        );
      }),
    );
  }

  public getMSDS(shipToId: number, externalProductId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.env.API_BASE_URL}/service/document/secure/document/msds`, {
      params: {
        ShipToId: shipToId.toString(), // eslint-disable-line @typescript-eslint/naming-convention
        ExternalProductId: externalProductId, // eslint-disable-line @typescript-eslint/naming-convention
      },
      responseType: 'blob',
      observe: 'response',
    });
  }

  public getHeadings(columns: DeliveryColumn[], prefix: string): HierarchicalDataColumn[] {
    return columns.map((col: string) => ({
      value: this.localeService.translate(`${prefix}${col}`),
      id: col,
      class: col !== DeliveryColumnTypes.description ? 'text--right' : '',
    }));
  }

  public formatLocations(locations: Location[] | SelectedLocation[]) {
    const maxAmountOfLocations = 5;
    let formattedLocations = locations
      .slice(0, maxAmountOfLocations)
      .map((loc: Location) => `${loc.street} (${loc.externalId})`)
      .join('  |  ');

    if (locations.length > maxAmountOfLocations) {
      formattedLocations += `  |  ...${
        locations.length - maxAmountOfLocations
      } ${this.localeService.translate('bex.report.legend.others')}`;
    }

    return formattedLocations;
  }

  public formatDateTime(dateTime: Date): string {
    return formatDate(dateTime, 'shortDate', this.localeService.getLocale());
  }

  public formatExportFileName(
    prefix: string,
    customerExternalId: string,
    startDate: Date,
    endDate: Date,
  ): string {
    return `${prefix}_${customerExternalId}_${this.formatDateTime(startDate).replace(
      /\//g,
      '-',
    )}_${this.formatDateTime(endDate).replace(/\//g, '-')}`;
  }

  public mapToPdfRows(columns: TableColumn[], data: DeliveryLineWithDelivery[]) {
    return data.map((line) => ({
      class: '',
      value: columns.reduce((obj, tableColumn) => {
        let value = line[tableColumn.id];
        switch (tableColumn.type) {
          case TableColumnTypes.DATE: {
            value = this.formatDateTime(value as Date);
            break;
          }
          case TableColumnTypes.NUMBER: {
            value = formatNumber(
              value as number,
              this.localeService.getLocale(),
              tableColumn.digitsInfo,
            );
            break;
          }
          case TableColumnTypes.CURRENCY: {
            value = formatCurrency(
              value as number,
              this.localeService.getLocale(),
              '',
              tableColumn?.currencyKeyColumn,
            );

            break;
          }
        }

        if (tableColumn.id === DeliveryColumnTypes.allShipToIds) {
          value = (value as IdentifierData).identifiers[0].value;
        }

        obj.push({
          class: `col-${tableColumn.type}`,
          value,
        });
        return obj;
      }, []),
    }));
  }

  public mapHierarchicalDataToPdfRows(data: HierarchicalData[]) {
    return [
      ...data.map((row) => ({
        class: `${row.type ? 'row--type-' + RowTypes[row.type] + ' ' : ''}${
          row.level ? 'row--level-' + row.level + ' ' : ''
        }`.trim(),
        value: row.columns.map((column) => {
          let columnValue = column.value;
          if (column.type === ColTypes.date) {
            columnValue = this.formatDateTime(columnValue as Date);
          } else if (column.type === ColTypes.number) {
            columnValue =
              formatNumber(
                columnValue as number,
                this.localeService.getLocale(),
                column.digitsInfo,
              ) + (column.unit ? ' ' + column.unit : '');
          } else if (column.type === ColTypes.currency) {
            columnValue = formatCurrency(
              columnValue as number,
              this.localeService.getLocale(),
              this.localeService.getCurrency(),
            );
          }

          return {
            class: `${column.type ? 'column--type-' + ColTypes[column.type] + ' ' : ''}${
              column.id ? 'column--id-' + column.id + ' ' : ''
            }${column.class ? column.class + ' ' : ''}`.trim(),
            value: columnValue,
            colspan: column.span,
          };
        }),
      })),
    ];
  }

  public formatOrderline(
    deliveryLine: DeliveryLineWithDelivery | DeliveryOverviewViewModel,
  ): CartLine {
    return {
      skuId: deliveryLine.skuId,
      quantity: parseInt(deliveryLine.quantity + '', 10),
    };
  }

  public filterAndSortDeliveries(deliveries: Delivery[], shipToIds: number[], brsNumber: string) {
    let filteredDeliveries = [...deliveries];

    if (shipToIds?.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        shipToIds?.find((shipToId) => shipToId === delivery?.shipTo?.id),
      );
    } else if (brsNumber?.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        delivery?.externalBrsNR?.endsWith(brsNumber),
      );
    }

    return filteredDeliveries.sort((delivery1, delivery2) =>
      delivery1.deliveryDate < delivery2.deliveryDate ? 1 : -1,
    );
  }

  public getQualityCertificate(
    itemId: string,
    shipToId: number,
    itemVLDoctype: string,
    system: string,
  ): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${this.env.API_BASE_URL}/service/document/secure/document/delivery/qualitycertificate`,
      {
        params: {
          ExternalDeliveryId: itemId, // eslint-disable-line @typescript-eslint/naming-convention
          ShipToId: shipToId, // eslint-disable-line @typescript-eslint/naming-convention, max-len
          DocType: itemVLDoctype, // eslint-disable-line @typescript-eslint/naming-convention
          System: system, // eslint-disable-line @typescript-eslint/naming-convention
        },
        responseType: 'blob',
        observe: 'response',
      },
    );
  }
}
