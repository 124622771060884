import { createAction, props } from '@ngrx/store';
import { ContractLine } from '../models/contract-line.model';

export const loadContractsRequest = createAction('[Contracts] Load contracts request');

export const loadContractsSuccess = createAction(
  '[Contracts] Load contracts success',
  props<{ contracts: ContractLine[] }>(),
);

export const loadContractsFailed = createAction(
  '[Contracts] Load contracts failed',
  props<{ error: Error }>(),
);

export const clearContractsRequest = createAction('[Contracts] Clear contracts request');

export const openContract = createAction(
  '[Contracts] Open contract',
  props<{ contract: ContractLine }>(),
);

export const getContractDocumentRequest = createAction(
  '[Contracts] Get contract document request',
  props<{ contractId: string }>(),
);

export const getContractDocumentSuccess = createAction('[Contracts] Get contract document success');

export const getContractDocumentFailed = createAction('[Contracts] Get contract document failed');
