import { createAction, props } from '@ngrx/store';

export const showIdentification = createAction('[Identification] Show identification');

export const insertIdentification = createAction(
  '[Identification] Identification insert',
  props<{ data: string; mediaType: string; fileName: string }>(),
);

export const insertIdentificationSuccess = createAction(
  '[Identification] Identification insert success',
);

export const insertIdentificationFailure = createAction(
  '[Identification] Identification insert failure',
);
