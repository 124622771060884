/* eslint-disable @typescript-eslint/member-ordering */ // Prevents from making destroy subject private
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { getContracts } from '../../store/contract.selectors';
import { ContractLine } from '../../models/contract-line.model';
import { TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  DefaultFilterOptions,
  FilterConfig,
  FiltersConfig,
  FilterTypes,
} from '@next/core-lib/components';
import { settingsContextName } from '../../../shared/models/shared.models';
import { SettingsStorage } from '@next/core-lib/settings';
import { LocaleService } from '@next/core-lib/i18n';
import * as ContractActions from '../../store/contract.actions';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, map, skipWhile, takeUntil } from 'rxjs/operators';
import { UserSelectors } from '@next/core-lib/user';
import { RootState } from '@next/core-lib/app';
import { ContractColumns } from './models/contract-columns';
import {
  ContractFilterKeys,
  ContractFilterValues,
  ContractStatusOptions,
} from './models/filtering';
import { ContractOverviewViewModel } from './models/contract-overview-view-model';
import { LineStatus } from '../../models/line-status.enum';
import { getContractDocumentRequest } from '../../store/contract.actions';

@Component({
  selector: 'app-contract-overview',
  templateUrl: './contract-overview.component.html',
})
export class ContractOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @ViewChild('statusColumn') statusColumn: TemplateRef<unknown>;
  @ViewChild('externalIdColumn') externalIdColumn: TemplateRef<unknown>;
  @ViewChild('contractQuantityColumn') contractQuantityColumn: TemplateRef<unknown>;
  @ViewChild('consumedQuantityColumn') consumedQuantityColumn: TemplateRef<unknown>;
  @ViewChild('openQuantityColumn') openQuantityColumn: TemplateRef<unknown>;
  @ViewChild('unitColumn') unitColumn: TemplateRef<unknown>;

  readonly lineStatus = LineStatus;
  readonly contractStatusOptions = ContractStatusOptions;

  defaultLocations$ = this.store.select(UserSelectors.selectLocations).pipe(
    skipWhile((value) => !value?.length),
    map((locations) => locations?.map((location) => location.id)),
    distinctUntilChanged((a, b) => a.length === b.length && a.every((p) => a[p] === b[p])),
    takeUntil(this.destroy$),
  );
  defaultContractStatusOptions$ = of([
    {
      value: ContractStatusOptions.active,
      viewValue: this.localeService.translate(
        `contract.contract-overview.status-${ContractStatusOptions.active}`,
      ),
    },
    {
      value: ContractStatusOptions.inactive,
      viewValue: this.localeService.translate(
        `contract.contract-overview.status-${ContractStatusOptions.inactive}`,
      ),
    },
    {
      value: ContractStatusOptions.ended,
      viewValue: this.localeService.translate(
        `contract.contract-overview.status-${ContractStatusOptions.ended}`,
      ),
    },
  ]);
  defaultContractStatuses = [
    ContractStatusOptions.active,
    ContractStatusOptions.inactive,
    ContractStatusOptions.ended,
  ];

  selectedContractStatuses = [ContractStatusOptions.active];

  public filterConfig: FilterConfig<FiltersConfig> = {
    filters: {
      locations: {
        type: FilterTypes.MultiSelect,
        options: DefaultFilterOptions.Locations,
        value: this.defaultLocations$,
        defaultValue: this.defaultLocations$,
        store: {
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: ContractFilterKeys.locations,
        },
        order: 1,
      },
      search: {
        type: FilterTypes.Text,
        defaultValue: of(''),
        store: {
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: ContractFilterKeys.search,
        },
      },
      status: {
        type: FilterTypes.MultiSelect,
        label: this.localeService.translate(`contract.contract-overview.status`),
        options: this.defaultContractStatusOptions$,
        value: of(this.selectedContractStatuses),
        store: {
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: ContractFilterKeys.status,
        },
        order: 1,
      },
    },
  };

  public tableConfig: TableConfig = {
    dataSource: new MatTableDataSource(),
    columns: [
      {
        id: ContractColumns.externalId,
        label: `contract.contract-overview.${ContractColumns.externalId}`,
        type: TableColumnTypes.TEMPLATE,
        className: 'flex--1-10',
        headerClassName: 'flex--1-10',
      },
      {
        id: ContractColumns.validFrom,
        label: `contract.contract-overview.${ContractColumns.validFrom}`,
        type: TableColumnTypes.DATE,
        className: 'flex--1-10',
        headerClassName: 'flex--1-10',
      },
      {
        id: ContractColumns.validTo,
        label: `contract.contract-overview.${ContractColumns.validTo}`,
        type: TableColumnTypes.DATE,
        className: 'flex--1-10',
        headerClassName: 'flex--1-10',
      },
      {
        id: ContractColumns.status,
        label: `contract.contract-overview.${ContractColumns.status}`,
        type: TableColumnTypes.TEMPLATE,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
      {
        id: ContractColumns.contractPosition,
        label: `contract.contract-overview.${ContractColumns.contractPosition}`,
        type: TableColumnTypes.STRING,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
      {
        id: ContractColumns.materialDescription,
        label: `contract.contract-overview.${ContractColumns.materialDescription}`,
        type: TableColumnTypes.STRING,
        className: 'flex--1-8',
        headerClassName: 'flex--1-8',
      },
      {
        id: ContractColumns.businessPartnerExternalId,
        label: `contract.contract-overview.${ContractColumns.businessPartnerExternalId}`,
        type: TableColumnTypes.STRING,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
      {
        id: ContractColumns.contractQuantity,
        label: `contract.contract-overview.${ContractColumns.contractQuantity}`,
        type: TableColumnTypes.TEMPLATE,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
      {
        id: ContractColumns.consumedQuantity,
        label: `contract.contract-overview.${ContractColumns.consumedQuantity}`,
        type: TableColumnTypes.TEMPLATE,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
      {
        id: ContractColumns.openQuantity,
        label: `contract.contract-overview.${ContractColumns.openQuantity}`,
        type: TableColumnTypes.TEMPLATE,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
      {
        id: ContractColumns.unit,
        label: `contract.contract-overview.${ContractColumns.unit}`,
        type: TableColumnTypes.TEMPLATE,
        className: 'flex--1-11',
        headerClassName: 'flex--1-11',
      },
    ],
    alternateRows: true,
    primaryColumn: ContractColumns.externalId,
    primaryColumnPrefix: this.localeService.translate('contract.contract-overview.externalIdShort'),
    selectColumn: false,
    activeSort: {
      id: ContractColumns.externalId,
      start: 'desc',
      disableClear: false,
    },
    actionColumn: true,
    hideActionLabel: true,
    actions: [
      {
        id: 'contract',
        label: '',
        icon: 'file_download',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: this.downloadContract.bind(this),
        disabled: this.downloadContractDisabled,
      },
    ],
  };

  public filterControl: FormControl = new FormControl();
  public readonly data$: Observable<ContractOverviewViewModel[]> = this.store
    .select(getContracts)
    .pipe(
      map((contracts) =>
        contracts.map((contract) => ({
          ...contract,
          contractStatus: this.mapContractStatusOption(contract),
          unit: contract.unit ? `unit.${contract.unit}` : '',
          location: {
            identifiers: [
              {
                description: '',
                value: contract.businessPartner.externalId,
              },
              contract.businessPartner.legacyIdentifiers.map((identifier) => ({
                description: identifier.legacyType,
                value: identifier.id,
              })),
            ],
          },
        })),
      ),
    );

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
  ) {}

  ngOnInit() {
    combineLatest([this.data$, this.filterControl.valueChanges])
      .pipe(
        map(([contracts, filters]) => this.filterContracts(contracts, filters)),
        takeUntil(this.destroy$),
      )
      .subscribe((contracts) => {
        this.tableConfig = {
          ...this.tableConfig,
          dataSource: new MatTableDataSource(contracts),
        };
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.tableConfig.columns[0].template = this.externalIdColumn;
      this.tableConfig.columns[3].template = this.statusColumn;
      this.tableConfig.columns[7].template = this.contractQuantityColumn;
      this.tableConfig.columns[8].template = this.consumedQuantityColumn;
      this.tableConfig.columns[9].template = this.openQuantityColumn;
      this.tableConfig.columns[10].template = this.unitColumn;
    }, 0);
  }

  ngOnDestroy(): void {
    this.store.dispatch(ContractActions.clearContractsRequest());

    this.destroy$.next();
    this.destroy$.complete();
  }

  private mapContractStatusOption(contract: ContractLine): ContractStatusOptions {
    const currentDate = new Date();
    const validFrom = new Date(contract.validFrom);
    const validTo = new Date(contract.validTo);

    if (contract.status === LineStatus.rel && currentDate >= validFrom && currentDate <= validTo) {
      return ContractStatusOptions.active;
    }

    if (contract.status === LineStatus.ended && currentDate > validTo) {
      return ContractStatusOptions.ended;
    }

    if (contract.status === LineStatus.rel && currentDate < validFrom) {
      return ContractStatusOptions.inactive;
    }

    return ContractStatusOptions.unknown;
  }

  private filterContracts(contracts: ContractLine[], filters: ContractFilterValues) {
    if (!contracts?.length || !filters) {
      return [];
    }

    const searchQuery = filters.search?.toLowerCase();
    let filteredContracts = contracts.filter(
      (contractLine) =>
        contractLine.externalId.toLocaleLowerCase().includes(searchQuery) ||
        contractLine.materialDescription.toLocaleLowerCase().includes(searchQuery),
    );

    if (filters.locations?.length) {
      filteredContracts = filteredContracts.filter((contractLine) =>
        filters.locations.includes(contractLine.businessPartner.id),
      );
    }

    if (!filters.status?.length || this.defaultContractStatuses.length === filters.status.length) {
      return filteredContracts;
    }

    filteredContracts = filteredContracts.filter(
      (contract: ContractLine) =>
        (filters.status?.includes(ContractStatusOptions.active) &&
          new Date(contract.validTo) >= new Date()) ||
        (filters.status?.includes(ContractStatusOptions.inactive) &&
          new Date(contract.validTo) < new Date()) ||
        (filters.status?.includes(ContractStatusOptions.ended) &&
          contract.status === LineStatus.ended),
    );

    return filteredContracts;
  }

  private downloadContract(contract: ContractLine) {
    this.store.dispatch(
      getContractDocumentRequest({
        contractId: contract.externalId,
      }),
    );
  }

  private downloadContractDisabled(contract: ContractLine) {
    return !contract?.externalId || !contract.docType;
  }
}
