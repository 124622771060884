export enum RowTypes {
  title,
  header,
  footer,
}

export enum ColTypes {
  string,
  number,
  currency,
  date,
}

export interface HierarchicalDataColumn {
  id: string;
  value: string | number | Date;
  digitsInfo?: string;
  unit?: string;
  class?: string;
  span?: number;
  currencyKeyColumn?: string;
  type?: ColTypes;
}

export interface HierarchicalData {
  type?: RowTypes;
  level?: number;
  columns: HierarchicalDataColumn[];
}
