import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TableActionsButtonComponent } from './table-actions-button.component';

@NgModule({
  declarations: [TableActionsButtonComponent],
  imports: [MatMenuModule, MatIconModule, MatButtonModule],
  exports: [TableActionsButtonComponent],
})
export class TableActionsButtonModule {}
