import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class DownloadBlobService {
  constructor() {}

  public download(response: HttpResponse<Blob>) {
    if (response != null) {
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(response.body);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
}
