import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  map,
  tap,
  catchError,
  startWith,
  finalize,
  switchMap,
  take,
  debounceTime,
} from 'rxjs/operators';
import { InvoiceDataService } from '../services/invoice-data.service';
import { Invoice, InvoiceTotal } from '../invoice-data.model';
import * as InvoiceDataActions from './invoice-data.actions';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CoreActions } from '@next/core-lib';
import { NotificationActions } from '@next/core-lib/notification';
import { LocaleService } from '@next/core-lib/i18n';
import { DownloadBlobService } from '../../shared/services/download-blob.service';
import { Settings, SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { DateService } from '../../shared/services/date.service';
import { settingsContextName, SettingsKeys } from '../../shared/models/shared.models';

@Injectable()
export class InvoiceDataEffects {
  loadRecentInvoices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceDataActions.loadRecentInvoicesRequest),
      mergeMap(() =>
        this.invoiceService
          .getInvoices(
            this.dateService.defaultRecentStartDate().toISOString(),
            this.dateService.defaultRecentEndDate().toISOString(),
          )
          .pipe(
            map((invoices: Invoice[]) =>
              InvoiceDataActions.loadRecentInvoicesSuccess({ invoices }),
            ),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          ),
      ),
    ),
  );

  loadInvoices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceDataActions.loadInvoicesRequest),
      debounceTime(500),
      switchMap((_) =>
        this.settingsService
          .getSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.dateRange,
          })
          .pipe(take(1)),
      ),
      mergeMap((settings: Settings[]) => {
        const selectedDateRange = settings?.[0]?.data as { [key: string]: string };

        return this.invoiceService
          .getInvoices(
            (!!selectedDateRange?.startDate
              ? new Date(selectedDateRange?.startDate)
              : this.dateService.defaultRecentStartDate()
            ).toISOString(),
            (!!selectedDateRange?.endDate
              ? new Date(selectedDateRange.endDate)
              : this.dateService.defaultRecentEndDate()
            ).toISOString(),
          )
          .pipe(
            map((invoices: Invoice[]) => InvoiceDataActions.loadInvoicesSuccess({ invoices })),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
            catchError((error: HttpErrorResponse) =>
              of(NotificationActions.errorSnackbar({ message: error.message })),
            ),
          );
      }),
    ),
  );

  loadInvoiceTotals$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceDataActions.loadInvoiceTotalsRequest),
      debounceTime(500),
      switchMap((_) =>
        combineLatest([
          this.settingsService.getSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.dateRange,
          }),
          this.settingsService.getSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.locations,
          }),
        ]),
      ),
      mergeMap(([dateRangeSettings, locationSettings]) => {
        const selectedDateRange = dateRangeSettings?.[0]?.data as { [key: string]: string };
        const locations = (locationSettings?.[0]?.data as number[]) ?? [];

        return this.invoiceService
          .getInvoiceTotals(
            (!!selectedDateRange?.startDate
              ? new Date(selectedDateRange?.startDate)
              : this.dateService.defaultRecentStartDate()
            ).toISOString(),
            (!!selectedDateRange?.endDate
              ? new Date(selectedDateRange.endDate)
              : this.dateService.defaultRecentEndDate()
            ).toISOString(),
            locations,
          )
          .pipe(
            map((invoiceTotals: InvoiceTotal[]) =>
              InvoiceDataActions.loadInvoiceTotalsSuccess({ invoiceTotals }),
            ),
            startWith(CoreActions.setLoading({ loading: true })),
            finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
            catchError((error: HttpErrorResponse) =>
              of(NotificationActions.errorSnackbar({ message: error.message })),
            ),
          );
      }),
    ),
  );

  getInvoiceDocumentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceDataActions.getInvoiceDocumentRequest, NotificationActions.snackbarClickButton),
      switchMap((data) => {
        const ids = 'ids' in data && data.ids;
        const shipToIds = 'shipToIds' in data ? data.shipToIds : undefined;

        if (ids) {
          return this.invoiceService.getInvoiceDocument(ids, shipToIds).pipe(
            tap(this.blobService.download),
            map(() => InvoiceDataActions.getInvoiceDocumentSuccess()),
            catchError(({ status = 500 }: HttpResponse<Blob>) =>
              of(
                InvoiceDataActions.getInvoiceDocumentFailed(),
                NotificationActions.errorSnackbar({
                  message: this.localeService.translate(
                    `invoice.invoice-overview.failed-to-download-pdf-${status}`,
                  ),
                }),
              ),
            ),
            startWith(
              NotificationActions.infoSnackbar({
                message: this.localeService.translate('invoice.invoice-overview.pdf-loading'),
                buttonTitle: this.localeService.translate('cancel'),
                spinner: true,
              }),
            ),
            finalize(() => this.store.dispatch(NotificationActions.cancelSnackbar())),
          );
        } else {
          return of(NotificationActions.cancelSnackbar());
        }
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly invoiceService: InvoiceDataService,
    private readonly blobService: DownloadBlobService,
    private readonly store: Store,
    private readonly dateService: DateService,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
  ) {}
}
