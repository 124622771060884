import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RootState } from '@next/core-lib/app';
import { FilterAction } from '@next/core-lib/components';
import { LocaleService } from '@next/core-lib/i18n';
import { TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { DetailedInvoice, InvoiceColumnTypes } from '../../../invoice-data/invoice-data.model';
import { InvoiceDataService } from '../../../invoice-data/services/invoice-data.service';
import {
  clearInvoiceTotalsRequest,
  loadInvoicesRequest,
} from '../../../invoice-data/store/invoice-data.actions';
import { selectDetailedInvoices } from '../../../invoice-data/store/invoice-data.selectors';
import { translateInvoiceType } from '../../utility/invoice-type-translation';

@Component({
  selector: 'app-invoice-totals',
  templateUrl: './invoice-totals.component.html',
  styleUrls: [],
})
export class InvoiceTotalsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('locationColumn') locationColumn: TemplateRef<unknown>;

  public tableConfig: TableConfig = {
    dataSource: new MatTableDataSource([]),
    columns: [
      {
        id: InvoiceColumnTypes.allShipToIds,
        type: TableColumnTypes.IDENTIFIER,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.allShipToIds}`,
        hideIdentifierDescription: true,
        onlyUniqueIdentifiers: true,
        className: 'flex__child--2',
        headerClassName: 'flex__child--2',
      },
      {
        id: InvoiceColumnTypes.locationAddress,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.locationAddress}`,
        className: 'flex__child--4',
        headerClassName: 'flex__child--4',
      },
      {
        id: InvoiceColumnTypes.invoiceDate,
        type: TableColumnTypes.DATE,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.invoiceDate}`,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
      {
        id: InvoiceColumnTypes.externalInvoiceId,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.externalInvoiceId}`,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
      {
        id: InvoiceColumnTypes.externalProductId,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.externalProductId}`,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
      {
        id: InvoiceColumnTypes.productTitle,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.productTitle}`,
        className: 'flex__child--8',
        headerClassName: 'flex__child--8',
      },
      {
        id: InvoiceColumnTypes.unitsDelivered,
        type: TableColumnTypes.NUMBER,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.unitsDelivered}`,
        className: 'flex__child--4',
        headerClassName: 'flex__child--4',
      },
      {
        id: InvoiceColumnTypes.unitQuantityType,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.unitQuantityType}`,
      },
      {
        id: InvoiceColumnTypes.baseUnitQuantity,
        type: TableColumnTypes.NUMBER,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.baseUnitQuantity}`,
        className: 'flex__child--4',
        headerClassName: 'flex__child--4',
      },
      {
        id: InvoiceColumnTypes.baseUnitOfMeasure,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-totals.${InvoiceColumnTypes.baseUnitOfMeasure}`,
      },
      {
        id: InvoiceColumnTypes.brutoAmount,
        type: TableColumnTypes.CURRENCY,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.brutoAmount}`,
        currencyKeyColumn: InvoiceColumnTypes.currencyKey,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
      {
        id: InvoiceColumnTypes.nettoAmount,
        type: TableColumnTypes.CURRENCY,
        label: `invoice.invoice-detailed-overview.${InvoiceColumnTypes.nettoAmount}`,
        currencyKeyColumn: InvoiceColumnTypes.currencyKey,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
      {
        id: InvoiceColumnTypes.invoiceDescription,
        type: TableColumnTypes.STRING,
        label: `invoice.invoice-overview.${InvoiceColumnTypes.invoiceDescription}`,
        className: 'flex__child--3',
        headerClassName: 'flex__child--3',
      },
    ],
    pagination: true,
    scrolling: true,
    width: '1650px',
    wrapperWidth: '100%',
    primaryColumn: InvoiceColumnTypes.externalInvoiceId,
    primaryColumnPrefix: this.localeService.translate(
      'invoice.invoice-overview.primaryColumnPrefix',
    ),
    activeSort: {
      id: InvoiceColumnTypes.invoiceDate,
      start: 'desc',
      disableClear: false,
    },
  };

  public readonly filterActions: FilterAction[] = [
    {
      icon: 'file_download',
      viewValue: 'Excel',
      value: 'xlsx',
    },
    {
      icon: 'file_download',
      viewValue: 'CSV',
      value: 'csv',
    },
  ];

  public exportFilename = `${this.localeService.translate(
    'invoice.invoice-detailed-overview.fileName',
  )}`;

  public selection: SelectionModel<DetailedInvoice> = new SelectionModel<DetailedInvoice>(
    true,
    [],
    true,
  );

  public data$: Observable<DetailedInvoice[]>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    public readonly invoiceDataService: InvoiceDataService,
  ) {}

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.store.select(selectDetailedInvoices),
      this.localeService.locale$,
    ]).pipe(
      debounceTime(100),
      map(([invoices, _]) =>
        invoices.map(
          (invoice): DetailedInvoice => ({
            ...invoice,
            unitsDelivered: Math.round(invoice.unitsDelivered),
            baseUnitQuantity: Math.round(invoice.baseUnitQuantity),
            invoiceDescription: translateInvoiceType(
              invoice.invoiceDescription,
              this.localeService,
            ),
            [InvoiceColumnTypes.unitQuantityType]: invoice[InvoiceColumnTypes.unitQuantityType]
              ? this.localeService.translate(`unit.${invoice[InvoiceColumnTypes.unitQuantityType]}`)
              : '',
          }),
        ),
      ),
      takeUntil(this.destroy$),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const newConfig = { ...this.tableConfig };
      newConfig.columns[0].template = this.locationColumn;
      this.tableConfig = newConfig;
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearInvoiceTotalsRequest());

    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.store.dispatch(loadInvoicesRequest());
  }
}
