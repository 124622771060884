import { Inject, Injectable } from '@angular/core';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { FeatureFlagService } from '@next/core-lib/featureflag';
import { LocaleService } from '@next/core-lib/i18n';
import { ParentNavigationItem } from '@next/core-lib/navigation';
import { AccessRule, UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import { Environment } from '../../../environment';
import { getStaticUserNavigationItems } from '../data/all-user-navigation-items';
import { UserNavigationItem } from '../models/shared.models';
import { AgroVisionUrlService } from './agro-vision-url.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemsService {
  customer$ = this.store.select(UserSelectors.selectCustomer);
  featureFlags: string[];

  constructor(
    private readonly localeService: LocaleService,
    @Inject(EnvironmentInjector) private readonly env: Environment,
    private readonly store: Store,
    private featureFlagService: FeatureFlagService,
    private agroVisionUrlService: AgroVisionUrlService,
  ) {
    this.customer$
      .pipe(
        map((selectedCustomer) => selectedCustomer?.shipTos[0]?.salesAreas[0]?.salesOrganization),
        switchMap((salesOrganization) =>
          this.featureFlagService.getFeatureFlagsForSalesArea(salesOrganization),
        ),
        filter((featureFlags) => featureFlags !== undefined && featureFlags.length > 0),
      )
      .subscribe((featureFlags) => {
        this.featureFlags = featureFlags;
      });
  }

  getAgroVisionNavigationItems(): Observable<UserNavigationItem[]> {
    return this.store.select(UserSelectors.selectCustomer).pipe(
      map((customer) => customer?.id),
      filter((customerId) => customerId != null),
      switchMap((customerId) =>
        combineLatest([
          this.agroVisionUrlService
            .getAgroVisionUrl('goat', customerId)
            .pipe(catchError(() => of(null))),
          this.agroVisionUrlService
            .getAgroVisionUrl('cultivation', customerId)
            .pipe(catchError(() => of(null))),
          this.agroVisionUrlService
            .getAgroVisionUrl('dairyCattle', customerId)
            .pipe(catchError(() => of(null))),
        ]).pipe(
          map(([goatUrl, cultivationUrl, dairyCattleUrl]) =>
            [
              {
                key: 'goat',
                url: goatUrl,
                titleKey: 'app.management.agrifirm-focus-goat',
                iconName: 'AgrifirmFocusGoat',
              },
              {
                key: 'twig',
                url: cultivationUrl,
                titleKey: 'app.management.agrifirm-focus-arable',
                iconName: 'AgrifirmFocusCultivation',
              },
              {
                key: 'cow',
                url: dairyCattleUrl,
                titleKey: 'app.management.agrifirm-focus-dairy-cattle',
                iconName: 'AgrifirmFocusDairyCattleWeb',
              },
            ]
              .filter((item) => item.url)
              .map((item) =>
                this.createNavigationItem(
                  item.url.toString(),
                  item.key,
                  item.titleKey,
                  item.iconName,
                ),
              ),
          ),
        ),
      ),
    );
  }

  getMatchingNavigationItems(
    authorizedUserApplications: AccessRule[],
    selectedLocale: string,
  ): Observable<ParentNavigationItem[]> {
    if (!authorizedUserApplications?.length) {
      return of([]);
    }

    const staticNavigationItems = getStaticUserNavigationItems(this.env, this.featureFlags);
    const dynamicNavigationItems$ = this.getAgroVisionNavigationItems();

    return dynamicNavigationItems$.pipe(
      map((dynamicNavigationItems) => [...staticNavigationItems, ...dynamicNavigationItems]),
      map((items) =>
        items.filter((parentUserNavigationItem) =>
          this.isMatchingUserNavigationItem(
            authorizedUserApplications,
            parentUserNavigationItem,
            selectedLocale,
          ),
        ),
      ),
      map((items) =>
        items
          .map((parentUserNavigationItem) => ({
            ...parentUserNavigationItem,
            label: this.localeService.translate(parentUserNavigationItem.label),
            applicationKey: null,
            children: parentUserNavigationItem.children
              ?.filter((childUserNavigationItem) =>
                this.isMatchingUserNavigationItem(
                  authorizedUserApplications,
                  childUserNavigationItem,
                  selectedLocale,
                ),
              )
              .map((childUserNavigationItem) => ({
                ...childUserNavigationItem,
                label: this.localeService.translate(childUserNavigationItem.label),
                applicationKey: null,
              })),
          }))
          .filter(
            (userNavigationItem) =>
              !userNavigationItem.children || userNavigationItem.children.length > 0,
          ),
      ),
    );
  }

  private getAccessRoleForUserNavigationItem(
    authorizedUserApplications: AccessRule[],
    userNavigationItem: UserNavigationItem,
  ): string {
    return authorizedUserApplications.find(
      (userApplication) => userApplication.ApplicationKey === userNavigationItem.applicationKey,
    )?.RoleKey;
  }

  private isMatchingUserNavigationItem(
    authorizedUserApplications: AccessRule[],
    userNavigationItem: UserNavigationItem,
    selectedLocale: string,
  ): boolean {
    const authorizedUserApplicationsKeys = authorizedUserApplications.map(
      (application: AccessRule) => application.ApplicationKey,
    );

    if (this.checkHideForRolesCondition(authorizedUserApplications, userNavigationItem)) {
      return false;
    }

    if (userNavigationItem.hideForLocales?.includes(selectedLocale)) {
      return false;
    }

    if (
      userNavigationItem.applicationKey &&
      !authorizedUserApplicationsKeys.includes(userNavigationItem.applicationKey)
    ) {
      return false;
    }

    return !(
      userNavigationItem.applicationKeys &&
      !userNavigationItem.applicationKeys.every((applicationKey: string) =>
        authorizedUserApplicationsKeys.includes(applicationKey),
      )
    );
  }

  private checkHideForRolesCondition(
    authorizedUserApplications: AccessRule[],
    userNavigationItem: UserNavigationItem,
  ): boolean {
    if (!userNavigationItem.hideForRoles) {
      return false;
    }

    if (
      userNavigationItem.applicationKey &&
      userNavigationItem.hideForRoles.includes(
        this.getAccessRoleForUserNavigationItem(authorizedUserApplications, userNavigationItem),
      )
    ) {
      return true;
    }

    return (
      userNavigationItem.applicationKeys &&
      authorizedUserApplications
        .filter((userApplication) =>
          userNavigationItem.applicationKeys.includes(userApplication.ApplicationKey),
        )
        .some((userApplication) =>
          userNavigationItem.hideForRoles.includes(userApplication.RoleKey),
        )
    );
  }

  private createNavigationItem(
    absoluteUrl: string,
    iconKey: string,
    label: string,
    applicationKey: string,
  ): UserNavigationItem {
    return {
      newWindow: true,
      hideNewWindowIcon: true,
      hideSession: true,
      applicationKey,
      absoluteUrl,
      iconKey,
      label,
    };
  }
}
