<div class="hierarchical-data">
  <ng-container *ngIf="data?.length > 0">
    <div class="flex" *ngIf="headings">
      <div
        *ngFor="let heading of headings"
        class="hierarchical-data__heading flex__child text--label"
        [class]="
          (!!heading.span ? 'flex__child-' + heading.span : '') +
          'hierarchical-data__heading-' +
          heading.id +
          ' ' +
          heading.class
        "
        [attr.data-test]="'hierarchical-data__heading-' + heading.id"
      >
        {{ heading.value }}
      </div>
    </div>

    <div
      class="flex hierarchical-data__row"
      [ngClass]="{ 'hierarchical-data__row--no-border': row.type === rowTypes.title }"
      [class]="row.level ? 'hierarchical-data__row--l' + row.level : ''"
      *ngFor="let row of data"
    >
      <ng-container *ngFor="let col of row.columns; let isLast = last">
        <div
          *ngIf="row.type === rowTypes.title"
          [class]="
            'hierarchical-data__title flex__child ' +
            'hierarchical-data__title-' +
            col.id +
            ' ' +
            (!!col.span ? ' flex__child-' + col.span + ' ' : '') +
            (isLast && !row.level ? ' flex flex--align-center ' : '') +
            (!row.level ? ' hierarchical-data__title--large ' : '') +
            'text--bold'
          "
          [attr.data-test]="'hierarchical-data__title-' + col.id"
        >
          <ng-container *ngTemplateOutlet="column; context: { col: col, row: row }"></ng-container>
          <span
            *ngIf="isLast && !row.level"
            class="hierarchical-data__divider spacing--margin-left flex__child--auto"
          ></span>
        </div>

        <div
          *ngIf="row.type === rowTypes.header"
          class="hierarchical-data__header flex__child color--primary-300 text--bold"
          [ngClass]="'hierarchical-data__header-' + col.id"
          [attr.data-test]="'hierarchical-data__header-' + col.id"
          [class]="!!col.span ? 'flex__child-' + col.span : ''"
        >
          <ng-container *ngTemplateOutlet="column; context: { col: col, row: row }"></ng-container>
        </div>

        <div
          *ngIf="
            row.type !== rowTypes.title &&
            row.type !== rowTypes.header &&
            row.type !== rowTypes.footer
          "
          class="hierarchical-data__column flex__child"
          [ngClass]="'hierarchical-data__column-' + col.id"
          [attr.data-test]="'hierarchical-data__column-' + col.id"
          [class]="!!col.span ? 'flex__child-' + col.span : ''"
        >
          <ng-container *ngTemplateOutlet="column; context: { col: col, row: row }"></ng-container>
        </div>

        <div
          *ngIf="row.type === rowTypes.footer"
          class="hierarchical-data__footer flex__child color--primary-300 text--bold"
          [ngClass]="'hierarchical-data__footer-' + col.id"
          [attr.data-test]="'hierarchical-data__footer-' + col.id"
          [class]="!!col.span ? 'flex__child-' + col.span : ''"
        >
          <ng-container *ngTemplateOutlet="column; context: { col: col, row: row }"></ng-container>
        </div>
      </ng-container>

      <ng-template #column let-col="col" let-row="row">
        <div
          [class]="'hierarchical-data__column-inner ' + col.class"
          [ngClass]="{ 'text--right': col.type && col.type !== colTypes.string }"
        >
          <ng-container *ngIf="!col?.type || col.type === colTypes.string">
            {{ col.value }}
          </ng-container>
          <ng-container *ngIf="col.type === colTypes.number">
            {{ col.value | agrNumber : col?.digitsInfo }}
          </ng-container>
          <span *ngIf="col.unit">{{ col.unit }}</span>
          <ng-container *ngIf="col.type === colTypes.currency">
            {{ col.value | agrCurrency : col?.currencyKeyColumn : 'symbol-narrow' }}
          </ng-container>
          <ng-container *ngIf="col.type === colTypes.date">
            {{ col.value | agrDate : 'shortDate' }}
          </ng-container>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <agr-notification-empty
    data-test="hierarchical-data__empty"
    [isEmpty]="!!isEmpty || !data?.length"
    [emptyTitle]="emptyTitle"
    [emptyDescription]="emptyDescription"
    [emptyImage]="emptyImage"
    [emptyActionIcon]="emptyActionIcon"
    [emptyActionText]="emptyActionText"
    [emptyActionColor]="emptyActionColor"
    [showEmptyAction]="showEmptyAction"
    (emptyAction)="emptyAction.emit($event)"
    class="spacing--margin-top"
  ></agr-notification-empty>
</div>
