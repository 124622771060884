<app-page-hierarchical-data-filter
  [pageTitle]=""
  [searchFieldPlaceholder]="'mineral.search-placeholder' | translate"
  [emptyTitle]="'mineral.empty-title' | translate"
  [emptyDescription]="'mineral.empty-description' | translate"
  [data$]="data$"
  (loadDataCallback)="loadData()"
  [headings]="headings"
  [settingsActions]="filterActions"
  [exportFilename]="exportFilename"
  [exportPdfCallback]="downloadPdf"
  [filterOnBrs]="true"
  [showLegacyTotalWarning]="true"
></app-page-hierarchical-data-filter>
