import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AgrIdentifierModule, AgrNotificationEmptyModule } from '@next/core-lib/components';
import { AgrExportModule } from '@next/core-lib/export';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { DeliveryDataService } from '../delivery-data/services/delivery-data.service';
import { SharedModule } from '../shared/shared.module';
import { TableActionsButtonModule } from '../shared/table-actions-button/table-actions-button.module';
import { TableV2Module } from '@next/core-lib/table-v2';
import { DeliveryOverviewComponent } from './components/delivery-overview/delivery-overview.component';
import { DeliveryRoutingModule } from './delivery-routing.module';
import { DeliveryComponent } from './delivery.component';

@NgModule({
  declarations: [DeliveryComponent, DeliveryOverviewComponent],
  imports: [
    DeliveryDataModule,
    SharedModule,
    CommonModule,
    DeliveryRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    TableV2Module,
    MatSortModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
    TableActionsButtonModule,
  ],
  providers: [DeliveryDataService],
})
export class DeliveryModule {}
