<ng-container *ngIf="vm$ | async as vm">
  <div class="wrapper--spacing-horizontal">
    <div class="identification__title">
      <h1 class="text--h1">{{ 'identification.identification-overview.title' | translate }}</h1>
      <button
        mat-flat-button
        color="accent"
        class="identification__add"
        (click)="openIdentificationAdd()"
      >
        <mat-icon>add</mat-icon>
        <span>{{ 'identification.add' | translate }}</span>
      </button>
    </div>

    <div class="spacing--margin-bottom">
      <agr-filter
        *ngIf="filterConfig"
        [config]="filterConfig"
        [formControl]="filterControl"
      ></agr-filter>
    </div>

    <div class="spacing--margin-bottom">
      <div
        *ngIf="vm.aboutToExpire"
        class="notification notification-info text--input-small"
        data-test="identification-overview__about-to-expire"
      >
        <mat-icon>info</mat-icon>
        {{ 'identification-overview.about-to-expire' | translate }}
      </div>

      <div
        *ngIf="vm.expired"
        class="notification notification-warning text--input-small"
        data-test="identification-overview__expired"
      >
        <mat-icon>warning</mat-icon>
        {{ 'identification-overview.expired' | translate }}
      </div>
    </div>

    <agr-table [config]="vm.tableConfig"></agr-table>
  </div>

  <ng-template #statusColumn let-row="row">
    <span
      *ngIf="row.status === identificationStatusOptions.active"
      class="flex--align-center column-gap"
    >
      <mat-icon>check</mat-icon>
      <span>
        {{ 'identification.identification-overview.status-active' | translate }}
      </span>
    </span>
    <span
      *ngIf="row.status === identificationStatusOptions.inactive"
      class="flex--align-center column-gap"
    >
      <mat-icon>close</mat-icon>
      <span>
        {{ 'identification.identification-overview.status-inactive' | translate }}
      </span>
    </span>
    <span
      *ngIf="row.status === identificationStatusOptions.ended"
      class="flex--align-center column-gap"
    >
      <mat-icon>close</mat-icon>
      <span>
        {{ 'identification.identification-overview.status-ended' | translate }}
      </span>
    </span>
  </ng-template>
</ng-container>
