import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
export enum ContractFilterKeys {
  dateRange = 'dateRange',
  locations = 'locations',
  search = 'search',
  status = 'status',
}

export interface ContractFilterValues {
  locations: number[];
  range: DateRange;
  search: string;
  status: ContractStatusOptions[];
}

export enum ContractStatusOptions {
  active = 'active',
  inactive = 'inactive',
  ended = 'ended',
  unknown = 'unknown',
}
