import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationPreferencesService } from '../services/notification-preferences.service';
import { catchError, finalize, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import * as PreferencesActions from './preferences.actions';
import { of } from 'rxjs';
import { NotificationActions } from '@next/core-lib/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { CoreActions } from '@next/core-lib';
import { Store } from '@ngrx/store';

@Injectable()
export class PreferencesEffects {
  loadPreferenceGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.loadPreferenceGroupsRequest),
      mergeMap(() =>
        this.notificationPreferencesService.getPreferenceGroups().pipe(
          map((preferenceGroups) =>
            PreferencesActions.loadPreferenceGroupsSuccess({ preferenceGroups }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              PreferencesActions.loadPreferenceGroupsFailed(),
              NotificationActions.errorSnackbar({ message: error?.message ?? error?.statusText }),
            ),
          ),
        ),
      ),
    ),
  );

  patchPreferenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.patchPreferenceRequest),
      switchMap(({ preference }) =>
        this.notificationPreferencesService.patchPreference(preference).pipe(
          map((updatedPreferenceGroups) =>
            PreferencesActions.patchPreferenceSuccess({
              preferenceGroups: updatedPreferenceGroups,
            }),
          ),
          startWith(CoreActions.setLoading({ loading: true })),
          finalize(() => this.store.dispatch(CoreActions.setLoading({ loading: false }))),
          catchError((error: HttpErrorResponse) => {
            this.store.dispatch(PreferencesActions.loadPreferenceGroupsRequest());
            return of(
              PreferencesActions.patchPreferenceFailure(),
              NotificationActions.errorSnackbar({ message: error?.message ?? error?.statusText }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationPreferencesService: NotificationPreferencesService,
    private readonly store: Store,
  ) {}
}
