<app-page-table-filter
  [pageTitle]="'invoice.title' | translate"
  [searchFieldPlaceholder]="'invoice.search-placeholder' | translate"
  [emptyTitle]="'invoice.empty-title' | translate"
  [emptyDescription]="'invoice.empty-description' | translate"
  [tableConfig]="tableConfig"
  (loadDataCallback)="loadData()"
  [filterAndSortFunction]="invoiceDataService.filterAndSortDetailedInvoices"
  [data$]="data$"
  [settingsActions]="filterActions"
  [exportFilename]="exportFilename"
  [showTotals]="false"
  [showInvoiceView]="true"
>
</app-page-table-filter>

<ng-template #locationColumn let-row="row">
  <span class="text--underline" matTooltip="{{ row.shipTo.name }}">{{
    row.shipTo.externalShipToId
  }}</span>
</ng-template>
