import { createAction, props } from '@ngrx/store';
import { Delivery, DeliveryCategories, DeliveryTotals } from '../delivery-data.model';
import { ReportRequest } from '../models/report.model';

export const loadDeliveriesRequest = createAction(
  '[DeliveryData] Load Deliveries request',
  props<{ category?: DeliveryCategories }>(),
);

export const loadDeliveriesSuccess = createAction(
  '[DeliveryData] Load Deliveries success',
  props<{ deliveries: Delivery[]; category?: DeliveryCategories }>(),
);

export const clearDeliveriesRequest = createAction(
  '[DeliveryData] Clear Deliveries request',
  props<{ category?: DeliveryCategories }>(),
);

export const loadDeliveryTotalsRequest = createAction(
  '[DeliveryData] Load Delivery Totals request',
  props<{ category?: DeliveryCategories }>(),
);

export const loadDeliveryTotalsSuccess = createAction(
  '[DeliveryData] Load Delivery Totals success',
  props<{ deliveryTotals: DeliveryTotals; category?: DeliveryCategories }>(),
);

export const clearDeliveryTotalsRequest = createAction(
  '[DeliveryData] Clear Delivery Totals request',
);

export const getDeliveryDocumentRequest = createAction(
  '[DeliveryData] Get Delivery document request',
  props<{ ids: number[]; shipToIds: number[] }>(),
);

export const getDeliveryDocumentSuccess = createAction(
  '[DeliveryData] Get delivery document success',
);

export const getDeliveryDocumentFailed = createAction(
  '[DeliveryData] Get delivery document failed',
);

export const loadRecentDeliveriesRequest = createAction(
  '[DeliveryData] Load Recent Deliveries request',
);

export const loadRecentDeliveriesSuccess = createAction(
  '[DeliveryData] Load Recent Deliveries success',
  props<{ deliveries: Delivery[] }>(),
);

export const clearRecentDeliveriesRequest = createAction(
  '[DeliveryData] Clear Recent Deliveries request',
);

export const getMSDSRequest = createAction(
  '[DeliveryData] Get MSDS document request',
  props<{ shipToId: number; externalProductId: string }>(),
);

export const getMSDSSuccess = createAction('[DeliveryData] Get MSDS document success');

export const getMSDSFailed = createAction('[DeliveryData] Get MSDS document failed');

export const getDeliveryReportRequest = createAction(
  '[DeliveryData] Get Delivery report request',
  props<{ report: ReportRequest }>(),
);

export const getDeliveryReportSuccess = createAction('[DeliveryData] Get Delivery report success');

export const getDeliveryReportFailed = createAction('[DeliveryData] Get Delivery report failed');

export const getQualityCertificateRequest = createAction(
  '[DeliveryData] Get quality certificate request',
  props<{ itemId: string; shipToId: number; itemVLDoctype: string; system: string }>(),
);

export const getQualityCertificateSuccess = createAction(
  '[DeliveryData] Get quality certificate success',
);

export const getQualityCertificateFailed = createAction(
  '[DeliveryData] Get quality certificate failed',
);
