import { createAction, props } from '@ngrx/store';
import { Invoice, InvoiceTotal } from '../invoice-data.model';

export const loadInvoicesRequest = createAction('[Invoice] Load invoice request');

export const loadInvoicesSuccess = createAction(
  '[Invoice] Load invoice success',
  props<{ invoices: Invoice[] }>(),
);

export const clearInvoicesRequest = createAction('[Invoice] Clear invoice request');

export const loadInvoiceTotalsRequest = createAction('[Invoice] Load invoice totals request');

export const loadInvoiceTotalsSuccess = createAction(
  '[Invoice] Load invoice totals success',
  props<{ invoiceTotals: InvoiceTotal[] }>(),
);

export const clearInvoiceTotalsRequest = createAction('[Invoice] Clear invoice totals request');

export const getInvoiceDocumentRequest = createAction(
  '[Invoice] Get invoice document request',
  props<{ ids: number[]; shipToIds?: number[] }>(),
);

export const getInvoiceDocumentSuccess = createAction('[Invoice] Get invoice document success');

export const getInvoiceDocumentFailed = createAction('[Invoice] Get invoice document failed');

export const loadRecentInvoicesRequest = createAction('[Invoice] Load Recent Invoices request');

export const loadRecentInvoicesSuccess = createAction(
  '[Invoice] Load Recent Invoices success',
  props<{ invoices: Invoice[] }>(),
);

export const clearRecentInvoicesRequest = createAction('[Invoice] Clear Recent Invoices request');
