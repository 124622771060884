import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { SettingsKeys, settingsContextName } from '../shared/models/shared.models';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
})
export class DeliveryComponent implements OnInit, OnDestroy {
  public showTotals$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly settingsService: SettingsService) {}

  ngOnInit(): void {
    this.showTotals$ = this.settingsService
      .getSettingsUpdates({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.totals,
      })
      .pipe(
        debounceTime(0),
        map((settings) =>
          settings?.map((setting) => (Array.isArray(setting) ? setting[0] : setting)),
        ),
        map((settings) => settings?.[0]?.data as boolean),
        takeUntil(this.destroy$),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
