import { NavigationItem, ParentNavigationItem } from '@next/core-lib/navigation';
import { Location } from '@next/core-lib/user';

export interface AppState {
  userNavigationItems?: ParentNavigationItem[];
  customerBrsNumbers: CustomerBrsNumber[];
}

export interface UserNavigationItem extends NavigationItem {
  hideForLocales?: string[];
  hideForRoles?: string[];
  applicationKey?: string;
  applicationKeys?: string[];
  children?: UserNavigationItem[];
}

export interface CustomerBrsNumber {
  relatedLocations: Location[];
  brsNumber: string;
}

export enum SettingsKeys {
  brsNumber = 'brsNumber',
  dateRange = 'dateRange',
  locations = 'locations',
  search = 'search',
  totals = 'totals',
  invoiceView = 'invoiceView',
}

export const settingsContextName = 'customer-dashboard';
