<div class="contract-overview wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ 'contract.contract-overview.title' | translate }}</h1>

  <div class="spacing--margin-bottom">
    <agr-filter *ngIf="filterConfig" [config]="filterConfig" [formControl]="filterControl">
    </agr-filter>
  </div>
  <agr-table *ngIf="tableConfig" [config]="tableConfig"></agr-table>
</div>

<ng-template #statusColumn let-row="row">
  <span *ngIf="row.contractStatus === contractStatusOptions.active">
    {{ 'contract.contract-overview.status-active' | translate }}
  </span>
  <span *ngIf="row.contractStatus === contractStatusOptions.inactive">
    {{ 'contract.contract-overview.status-inactive' | translate }}
  </span>
  <span *ngIf="row.contractStatus === contractStatusOptions.ended">
    {{ 'contract.contract-overview.status-ended' | translate }}
  </span>
  <span *ngIf="row.contractStatus === contractStatusOptions.unknown">
    {{ 'contract.contract-overview.status-unknown' | translate }}
  </span>
</ng-template>

<ng-template #externalIdColumn let-row="row">
  <span>{{ row.externalId | trimZeroes }}</span>
</ng-template>

<ng-template #contractQuantityColumn let-row="row">
  <span>{{ row.contractQuantity | agrNumber }} </span>
</ng-template>

<ng-template #consumedQuantityColumn let-row="row">
  <span>{{ row.consumedQuantity | agrNumber }} </span>
</ng-template>

<ng-template #openQuantityColumn let-row="row">
  <span>{{ row.openQuantity | agrNumber }} </span>
</ng-template>

<ng-template #unitColumn let-row="row">
  <span>{{ row.unit | translate }} </span>
</ng-template>
