import { NgModule } from '@angular/core';
import { FileUploadComponent } from './components/file-upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AgrPipesModule } from '@next/core-lib/pipes';
import { CommonModule } from '@angular/common';
import { FileUploadPreviewTemplateDirective } from './directives/file-upload-preview-template.directive';
import { ObjectUrlPipe } from './pipes/object-url.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { AgrI18nSharedModule } from '@next/core-lib/i18n';
import { FileUploadErrorsTemplateDirective } from './directives/file-upload-errors-template.directive';
import { HumanFileSizePipe } from './pipes/human-file-size.pipe';

@NgModule({
  imports: [
    AgrI18nSharedModule,
    AgrPipesModule,
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FileUploadComponent,
    FileUploadErrorsTemplateDirective,
    FileUploadPreviewTemplateDirective,
    HumanFileSizePipe,
    ObjectUrlPipe,
  ],
  exports: [
    FileUploadComponent,
    FileUploadErrorsTemplateDirective,
    FileUploadPreviewTemplateDirective,
    HumanFileSizePipe,
    ObjectUrlPipe,
  ],
})
export class FileUploadModule {}
