import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  insertIdentification,
  insertIdentificationFailure,
  insertIdentificationSuccess,
} from './actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { IdentificationService } from '../services/identification.service';
import { of } from 'rxjs';
import { NotificationActions } from '@next/core-lib/notification';
import { LocaleService } from '@next/core-lib/i18n';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';

@Injectable()
export class IdentificationEffects {
  insertIdentification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertIdentification),
      withLatestFrom(this.store.select(UserSelectors.selectCustomer)),
      switchMap(([{ data, fileName, mediaType }, customer]) =>
        this.identificationService
          .insert({
            customerId: customer.externalId,
            attachments: [
              {
                data,
                fileName,
                mediaType,
              },
            ],
          })
          .pipe(
            map(() => insertIdentificationSuccess()),
            catchError(() => of(insertIdentificationFailure())),
          ),
      ),
    ),
  );

  insertIdentificationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertIdentificationFailure),
      map((_) =>
        NotificationActions.errorSnackbar({
          message: this.localeService.translate('identification-add.snackbar-error'),
          buttonTitle: this.localeService.translate('close'),
        }),
      ),
    ),
  );

  insertIdentificationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertIdentificationSuccess),
      map((_) =>
        NotificationActions.successSnackbar({
          message: this.localeService.translate('identification-add.snackbar-success'),
          buttonTitle: this.localeService.translate('close'),
        }),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly identificationService: IdentificationService,
    private readonly localeService: LocaleService,
    private readonly store: Store,
  ) {}
}
