import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocaleService } from '@next/core-lib/i18n';
import { SelectedLocation, UserSelectors } from '@next/core-lib/user';
import { adapter } from './delivery-data.reducer';
import {
  DeliveryDataModuleState,
  Delivery,
  DeliveryLineWithDelivery,
  Totals,
  DeliveryColumn,
  DeliveryColumnTypes,
  GroupedProductCategory,
  ArticleTotal,
  DeliveryTotals,
} from '../delivery-data.model';
import {
  ColTypes,
  HierarchicalData,
  RowTypes,
} from '../../shared/hierarchical-data/hierarchical-data.model';

const { selectAll } = adapter.getSelectors();

const getDeliveryDataModuleState =
  createFeatureSelector<DeliveryDataModuleState>('deliveryDataModule');

const getRecentDeliveriesState = createSelector(
  getDeliveryDataModuleState,
  (state) => state.recentDeliveries,
);

const selectRecentDeliveries = createSelector(
  createSelector(getRecentDeliveriesState, selectAll),
  (deliveries: Delivery[]) =>
    deliveries.sort((delivery1, delivery2) =>
      delivery1.deliveryDate < delivery2.deliveryDate ? 1 : -1,
    ),
);

// Recent deliveries
const getRecentDeliveriesByDateDesc = createSelector(selectRecentDeliveries, (state) =>
  state.sort((delivery1, delivery2) => {
    if (delivery1.deliveryDate > delivery2.deliveryDate) {
      return -1;
    }

    if (delivery1.deliveryDate < delivery2.deliveryDate) {
      return 1;
    }

    return 0;
  }),
);

const getTopRecentDeliveries = createSelector(
  getRecentDeliveriesByDateDesc,
  (state: Delivery[], props: { amountOfDeliveryLinesToSelect: number }) => {
    const deliveriesToReturn: Delivery[] = [];
    let remainingDeliveryLinesToGet = props.amountOfDeliveryLinesToSelect;

    for (const delivery of state) {
      const resultRemainingToGet = remainingDeliveryLinesToGet - delivery.deliveryLines.length;

      if (resultRemainingToGet >= 0) {
        remainingDeliveryLinesToGet -= delivery.deliveryLines.length;
        deliveriesToReturn.push(delivery);
      } else if (remainingDeliveryLinesToGet > 0 && resultRemainingToGet <= 0) {
        remainingDeliveryLinesToGet -= delivery.deliveryLines.length;

        deliveriesToReturn.push({
          ...delivery,
          deliveryLines: delivery.deliveryLines.slice(0, remainingDeliveryLinesToGet),
        });
      } else {
        break;
      }
    }

    return deliveriesToReturn;
  },
);

export const getTopRecentDeliveriesWithDeliveryInformation = (
  amountOfDeliveryLinesToSelect: number,
) =>
  createSelector(
    getRecentDeliveriesByDateDesc,
    (deliveries: Delivery[]): DeliveryLineWithDelivery[] =>
      mapDeliveryLinesForOverview(
        getTopRecentDeliveries.projector(deliveries, { amountOfDeliveryLinesToSelect }),
      ),
  );

export const selectDeliveryTotals = ({
  prefix,
  localeService,
  columns,
  digitsInfo,
  hideUnits,
}: {
  prefix: string;
  localeService: LocaleService;
  columns: DeliveryColumn[];
  digitsInfo?: { [key: string]: string };
  hideUnits?: boolean;
}) =>
  createSelector(
    UserSelectors.selectLocations,
    getDeliveryDataModuleState,
    (locations: SelectedLocation[], { deliveryTotals }: { deliveryTotals: DeliveryTotals }) =>
      mapDeliveryLinesForTotals(
        locations,
        deliveryTotals,
        prefix,
        localeService,
        columns,
        digitsInfo,
        hideUnits,
      ),
  );

// Deliveries
const getDeliveriesState = createSelector(getDeliveryDataModuleState, (state) => state.deliveries);
const selectDeliveries = createSelector(
  createSelector(getDeliveriesState, selectAll),
  (deliveries: Delivery[]) =>
    deliveries.sort((delivery1, delivery2) =>
      delivery1.deliveryDate < delivery2.deliveryDate ? 1 : -1,
    ),
);

export const selectDeliveriesOverview = createSelector(selectDeliveries, (deliveries: Delivery[]) =>
  mapDeliveryLinesForOverview(deliveries),
);

// BEX
const selectBexState = createSelector(getDeliveryDataModuleState, (state) => state.bex);

export const selectBexOverview = createSelector(selectBexState, selectAll);

// Minerals
const selectMineralsState = createSelector(getDeliveryDataModuleState, (state) => state.minerals);
const selectMinerals = createSelector(selectMineralsState, selectAll);

export const selectMineralsOverview = createSelector(selectMinerals, (deliveries) =>
  mapDeliveryLinesForOverview(deliveries),
);

// Inbound deliveries (graan/peen/ui)
const selectInboundDeliveriesState = createSelector(
  getDeliveryDataModuleState,
  (state) => state.inboundDeliveries,
);

export const selectInboundDeliveriesOverview = createSelector(
  selectInboundDeliveriesState,
  selectAll,
);

// GBM
const selectGbmDeliveriesState = createSelector(getDeliveryDataModuleState, (state) => state.gbm);
const selectGbmDeliveries = createSelector(selectGbmDeliveriesState, selectAll);

export const selectGbmDeliveriesForOverview = createSelector(selectGbmDeliveries, (deliveries) =>
  mapDeliveryLinesForOverview(deliveries),
);

/* HELPERS */
export const mapDeliveryLinesForOverview = (deliveries: Delivery[]) =>
  deliveries
    .map(
      ({
        id,
        externalDeliveryId,
        deliveryDate,
        customer,
        shipTo,
        deliveryLines,
        externalDeliveryNoteId,
        externalOrderId,
        contractDescription,
        externalBrsNR,
        grainWeightDocument,
        uniwinNumber,
      }: Delivery) =>
        (deliveryLines as DeliveryLineWithDelivery[])?.map((line: DeliveryLineWithDelivery) => ({
          ...line,
          deliveryId: id,
          externalDeliveryId,
          deliveryDate: new Date(deliveryDate),
          customer,
          shipTo,
          shipToId: shipTo.id,
          contractDescription,
          externalDeliveryNoteId,
          allShipToIds: {
            identifiers: [
              {
                description: '',
                value: shipTo.externalShipToId,
              },
              ...shipTo?.shipToLegacies.map((legacyItem) => ({
                description: legacyItem.legacyType,
                value: legacyItem.id,
              })),
            ],
          },
          ...line.deliveryLinePrice,
          externalOrderId,
          externalBrsNR,
          grainWeightDocument,
          uniwinNumber,
          uniwinNumberWithExternalDeliveryNoteIdFallback: uniwinNumber
            ? uniwinNumber
            : externalDeliveryNoteId,
        })),
    )
    .reduce((acc, value) => acc.concat(value), []);

const mapDeliveryLinesForTotals = (
  locations: SelectedLocation[],
  deliveryTotals: DeliveryTotals,
  prefix: string,
  localeService: LocaleService,
  columns: DeliveryColumn[],
  digitsInfo?: { [key: string]: string },
  hideUnits?: boolean,
) => {
  const viewTotals: HierarchicalData[] = [];

  deliveryTotals.shipToTotals?.forEach((shipToTotal) => {
    const currentLocation = locations?.find(
      (location) => location.externalId === shipToTotal.shipTo?.externalShipToId,
    );

    viewTotals.push({
      type: RowTypes.header,
      columns: formatTotals(
        shipToTotal.totals,
        `${currentLocation?.name} (${currentLocation?.externalId})`,
        columns,
        digitsInfo,
        hideUnits,
      ),
    });
  });

  deliveryTotals.groupedProductCategories?.forEach(
    (groupedProductCategory: GroupedProductCategory) => {
      groupedProductCategory.articleTotals.forEach((articleTotal: ArticleTotal) => {
        const productDescription = `${articleTotal.productTitle} (${articleTotal.externalProductId})`;
        viewTotals.push({
          columns: formatTotals(
            articleTotal.totals,
            productDescription,
            columns,
            digitsInfo,
            hideUnits,
          ),
        });
      });

      groupedProductCategory.totals.forEach((totals: Totals) => {
        viewTotals.push({
          type: RowTypes.footer,
          columns: formatTotals(
            totals,
            `${
              groupedProductCategory.name || localeService.translate(`${prefix}other`)
            } ${localeService.translate(`${prefix}totals`)}`,
            columns,
            digitsInfo,
            hideUnits,
          ),
        });
      });
    },
  );

  return viewTotals;
};

const formatTotals = (
  totals: Totals,
  rowDescription: string,
  columns: DeliveryColumn[],
  digitsInfo?: { [key: string]: string },
  hideUnits?: boolean,
) => [
  {
    value: rowDescription,
    id: DeliveryColumnTypes.description,
    type: ColTypes.string,
  },
  ...columns
    .filter((col) => col !== DeliveryColumnTypes.description)
    .map((col: keyof Totals) => ({
      id: col,
      value: (col in totals && typeof totals[col] === 'number' ? totals[col] : 0) as number,
      digitsInfo: digitsInfo?.[col as string] || '1.0-3',
      unit: formatUnit(hideUnits, col, totals),
      class: 'text--right',
      type: ColTypes.number,
    })),
];

const formatUnit = (hideUnits: boolean, col: keyof Totals, totals: Totals) => {
  if (hideUnits) {
    return '';
  }

  switch (col) {
    case DeliveryColumnTypes.unitQuantity:
      return totals.unitsDelivered;
    case DeliveryColumnTypes.productQuantity:
      return totals.productUnit;
    default:
      return '';
  }
};
