import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractDialog,
  AbstractDialogData,
  AbstractDialogResultData,
} from '@next/core-lib/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { insertIdentification } from '../../store/actions';
import { fileToDateUrl } from '../../../shared/file-upload/utils/file.util';
import { selectShow } from '../../store/selectors';
import { LocaleService } from '@next/core-lib/i18n';
import { FileTransformerInterface } from '../../../shared/file-upload/models/file-transformer.interface';

// Old-school "require" because an ESM import gets optimized and during optimization too much
// code is optimized away. That causes an undefined error in production
const imageBlobReduce = require('image-blob-reduce');

type IdentificationAddDialogData = AbstractDialogData;

@Component({
  selector: 'app-identification-add',
  templateUrl: 'identification-add.component.html',
  styleUrls: ['identification-add.component.scss'],
})
export class IdentificationAddComponent
  extends AbstractDialog<IdentificationAddDialogData>
  implements OnInit, OnDestroy {
  data = {};
  readonly data$: Observable<IdentificationAddDialogData>;
  readonly form = new FormGroup({
    image: new FormControl(null, Validators.required),
  });
  readonly twoMegaBytes = 2000_000;
  readonly transformer: FileTransformerInterface = {
    transform: async (file: File) => {
      // Skip resizing when the image is smaller than 2 MB
      if (file.size < this.twoMegaBytes) {
        return file;
      }

      try {
        const blob = await imageBlobReduce().toBlob(file, { max: 1920 });

        return new File([blob], file.name, { type: blob.type });
      } catch (err) {
        console.warn('Unable to resize image, continuing with original file', err);

        return file;
      }
    },
  };

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) _data: IdentificationAddDialogData,
    protected readonly mdDialogRef: MatDialogRef<
      IdentificationAddComponent,
      AbstractDialogResultData<IdentificationAddDialogData>
    >,
    private store: Store,
    localeService: LocaleService,
  ) {
    super();
    this.data$ = combineLatest([of(_data), this.form.statusChanges.pipe(startWith(false))]).pipe(
      map(([data]) => ({
        ...data,
        title: localeService.translate('identification-add.title'),
        confirmButtonText: localeService.translate('identification-add.confirm'),
        disableConfirmButton: !this.form.valid,
        confirmButton: true,
      })),
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(selectShow)
        .pipe(
          filter((show) => !show),
          take(1),
        )
        .subscribe((_) => {
          this.confirm();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async saveIdentification() {
    if (!this.form.valid) {
      console.warn('Form not valid');
      return;
    }

    try {
      const file = this.form.get('image').value as File;
      const data = await fileToDateUrl(file);
      const fileName = file.name;
      const mediaType = file.type;

      this.store.dispatch(
        insertIdentification({
          data,
          fileName,
          mediaType,
        }),
      );
    } catch (ex) {
      console.error('Unexpected exception during insertIdentification dispatch', ex);
    }
  }
}
