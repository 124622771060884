export enum IdentificationColumns {
  location = 'location',
  customerNumber = 'customerNumber',
  identificationTypeTranslated = 'identificationTypeTranslated',
  identificationNumber = 'identificationNumber',
  validFrom = 'validFrom',
  validTo = 'validTo',
  status = 'status',
}

export interface IdentificationOverviewModel {
  location: string;
  locationId: number;
  customerNumber: string;
  identificationType: string;
  identificationTypeTranslated: string;
  identificationNumber: string;
  validFrom: string;
  validTo: string;
  status: IdentificationStatus;
}

export enum IdentificationStatus {
  active = 'active',
  inactive = 'inactive',
  ended = 'ended',
}
