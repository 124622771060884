import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { InvoiceComponent } from './invoice.component';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { SharedModule } from '../shared/shared.module';
import { DownloadBlobService } from '../shared/services/download-blob.service';
import { InvoiceDataModule } from '../invoice-data/invoice-data.module';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InvoiceOverviewComponent } from './components/invoice-overview/invoice-overview.component';
import { InvoiceTotalsComponent } from './components/invoice-totals/invoice-totals.component';
import { PageHierarchicalDataFilterModule } from '../shared/page-hierarchical-data-filter/page-hierarchical-data-filter.module';
import { InvoiceDetailedOverviewComponent } from './components/invoice-detailed-overview/invoice-detailed-overview.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { AgrIdentifierModule, AgrNotificationEmptyModule } from '@next/core-lib/components';
import { AgrExportModule } from '@next/core-lib/export';
import { TableV2Module } from '@next/core-lib/table-v2';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    InvoiceComponent,
    InvoiceOverviewComponent,
    InvoiceTotalsComponent,
    InvoiceDetailedOverviewComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    InvoiceRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    InvoiceDataModule,
    PageTableFilterModule,
    MatTooltipModule,
    PageHierarchicalDataFilterModule,
    TableV2Module,
    MatSortModule,
    ReactiveFormsModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
    MatRadioModule,
    MatFormFieldModule,
  ],
  providers: [DownloadBlobService],
})
export class InvoiceModule {}
