import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';

export const gbmColumns = [
  DeliveryColumnTypes.deliveryDate,
  DeliveryColumnTypes.externalProductId,
  DeliveryColumnTypes.productTitle,
  DeliveryColumnTypes.unitQuantity,
  DeliveryColumnTypes.unitVolumeType,
  DeliveryColumnTypes.productQuantity,
  DeliveryColumnTypes.productUnit,
  DeliveryColumnTypes.allShipToIds,
  DeliveryColumnTypes.externalDeliveryId,
] as const;

export type GbmOverviewViewModel = Pick<
  DeliveryLineWithDelivery,
  (typeof gbmColumns)[number] | 'id' | 'deliveryId' | 'shipToId'
>;

export interface GbmOverviewDataFilters {
  locations: number[];
  search: string;
  dateRange: DateRange;
}
