import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
  AgrExtendedNotificationModule,
  AgrIdentifierModule,
  AgrNotificationEmptyModule,
  AgrTotalsLegacyWarningDialogModule,
} from '@next/core-lib/components';
import { AgrDialogModule } from '@next/core-lib/dialog';
import { AgrExportModule } from '@next/core-lib/export';
import { TableV2Module } from '@next/core-lib/table-v2';
import { StoreModule } from '@ngrx/store';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { DeliveryDataService } from '../delivery-data/services/delivery-data.service';
import { PageHierarchicalDataFilterModule } from '../shared/page-hierarchical-data-filter/page-hierarchical-data-filter.module';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { DownloadBlobService } from '../shared/services/download-blob.service';
import { SharedModule } from '../shared/shared.module';
import { MineralLegalNoticeComponent } from './components/mineral-legal-notice/mineral-legal-notice.component';
import { MineralOverviewComponent } from './components/mineral-overview/mineral-overview.component';
import { MineralTotalsComponent } from './components/mineral-totals/mineral-totals.component';
import { MineralRoutingModule } from './mineral-routing.module';
import { MineralComponent } from './mineral.component';
import { mineralReducer } from './store/mineral.reducer';

@NgModule({
  declarations: [
    MineralComponent,
    MineralTotalsComponent,
    MineralOverviewComponent,
    MineralLegalNoticeComponent,
  ],
  imports: [
    DeliveryDataModule,
    SharedModule,
    CommonModule,
    MineralRoutingModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    PageTableFilterModule,
    PageHierarchicalDataFilterModule,
    AgrExtendedNotificationModule,
    AgrDialogModule,
    StoreModule.forFeature('mineralModule', mineralReducer),
    TableV2Module,
    MatSortModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
    AgrTotalsLegacyWarningDialogModule,
  ],
  providers: [DownloadBlobService, DeliveryDataService],
})
export class MineralModule {}
