import { Component } from '@angular/core';
import { NavigationSelectors, ParentNavigationItem } from '@next/core-lib/navigation';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public navigationItems$: Observable<ParentNavigationItem[]> = this.store.select(
    NavigationSelectors.selectNavigationItems,
  );

  constructor(private readonly store: Store) {}
}
