<div class="greenhouse-notice spacing--padding-bottom spacing--padding-top">
  <agr-extended-notification
    notificationType="info"
    [title]="'greenhouse.greenhouse-overview.notification-title' | translate"
    [actionText]="getActionText()"
    (action)="toggleNotification()"
  >
    <div>
      <div *ngIf="showNotification">
        <p class="text--small">{{ 'greenhouse.greenhouse-overview.notification-description' | translate }}</p>
        <p class="text--small">{{ 'greenhouse.greenhouse-overview.notification-extra-information1' | translate }}</p>
        <p class="text--small">{{ 'greenhouse.greenhouse-overview.notification-extra-information2' | translate }}</p>
      </div>
    </div>
  </agr-extended-notification>
</div>
