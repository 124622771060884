<div class="page-table-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ 'bex.title' | translate }}</h1>

  <agr-table-v2
    matSort
    [dataSource]="dataSource"
    [isEqual]="isEqual"
    [withSelection]="true"
    (filterClearClick)="resetFilters()"
    class="bex-overview__table-spaced-cols"
  >
    <ng-container *agrTableSearch [formGroup]="searchAndFilters">
      <agr-table-search
        formControlName="search"
        [label]="'search' | translate"
        [placeholder]="'bex.search-placeholder' | translate"
      >
      </agr-table-search>
    </ng-container>

    <ng-container *agrTableFilters [formGroup]="searchAndFilters">
      <agr-table-date-range-filter
        [label]="'daterange-picker.period' | translate"
        [config]="dateRangeConfig"
        formControlName="dateRange"
      >
      </agr-table-date-range-filter>

      <agr-table-select-filter
        [label]="'page-hierarchical-data-filter.brs-numbers' | translate"
        formControlName="brsNumber"
        [options]="brsOptions$ | async"
        [dropdownDefault]="'page-hierarchical-data-filter.all-brs-numbers' | translate"
      >
      </agr-table-select-filter>
    </ng-container>

    <agr-table-top-bar *agrTableTopBar class="bex-overview__top-bar">
      <agr-table-selected-items></agr-table-selected-items>
      <div
        class="flex--row flex--gutter flex--nowrap flex--align-center flex--justify-space-between"
      >
        <mat-slide-toggle
          color="primary"
          [formControl]="showTotalsToggle"
          [attr.data-test]="'filter__item-showTotals'"
        >
          {{ 'page-table-filter.show-totals' | translate }}
        </mat-slide-toggle>
        <agr-totals-dialog-button />
        <agr-export agrTableExport [options]="exportOptions" [dataSource]="dataSource">
        </agr-export>
      </div>
    </agr-table-top-bar>

    <ng-container matColumnDef="deliveryDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.deliveryDate' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.deliveryDate' | translate"
        *matCellDef="let row"
      >
        {{ row.deliveryDate | agrDate : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="allShipToIds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.allShipToIds' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.allShipToIds' | translate"
        *matCellDef="let row"
      >
        <agr-identifier-trigger
          [data]="row.allShipToIds"
          [hideDescription]="true"
          [onlyUnique]="true"
          (click)="$event.stopPropagation()"
        ></agr-identifier-trigger>
      </td>
    </ng-container>

    <ng-container matColumnDef="externalProductId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.externalProductId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.externalProductId' | translate"
        *matCellDef="let row"
      >
        {{ row.externalProductId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.productTitle' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.productTitle' | translate"
        *matCellDef="let row"
      >
        {{ row.productTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="klwArticleGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.klwArticleGroupName' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.klwArticleGroupName' | translate"
        *matCellDef="let row"
      >
        {{ row.klwArticleGroupName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unitQuantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.unitQuantity' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.unitQuantity' | translate"
        *matCellDef="let row"
      >
        {{ row.unitQuantity | agrNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="vem">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.vem' | translate }}
      </th>
      <td mat-cell [attr.aria-label]="'bex.bex-overview.vem' | translate" *matCellDef="let row">
        {{ row.vem | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="roughProtein">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.roughProtein' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.roughProtein' | translate"
        *matCellDef="let row"
      >
        {{ row.roughProtein | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="nitrogen">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.nitrogen' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.nitrogen' | translate"
        *matCellDef="let row"
      >
        {{ row.nitrogen | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phosphorus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.phosphorus' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.phosphorus' | translate"
        *matCellDef="let row"
      >
        {{ row.phosphorus | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dryMatterAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.dryMatterAmount' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.dryMatterAmount' | translate"
        *matCellDef="let row"
      >
        {{ row.dryMatterAmount | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="roughAshes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.roughAshes' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.roughAshes' | translate"
        *matCellDef="let row"
      >
        {{ row.roughAshes | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: displayColumns"></tr>
    <tr *matNoDataRow>
      <td class="text--center" [attr.colspan]="displayColumns.length">
        <agr-notification-empty
          data-test="hierarchical-data__empty"
          [isEmpty]="true"
          [emptyTitle]="'bex.empty-title' | translate"
          [emptyDescription]="'bex.empty-description' | translate"
          [emptyImage]="'/assets/images/no-items-3.png'"
          [emptyActionIcon]="'history'"
          [emptyActionText]="'page-table-filter.reset-filters' | translate"
          [showEmptyAction]="true"
          (emptyAction)="resetFilters()"
          class="spacing--margin-top"
        ></agr-notification-empty>
      </td>
    </tr>
  </agr-table-v2>
</div>
