<div class="file-upload" *ngIf="control.value === null; else fileChosen">
  <button mat-button (click)="fileUpload.click()">
    <mat-icon>{{ chooseIcon }}</mat-icon>
    <span *ngIf="chooseFileText; else defaultChooseFile"> {{ chooseFileText }} </span>
    <ng-template #defaultChooseFile>
      <span>{{ 'file-upload.choose-file' | translate }}</span>
    </ng-template>
  </button>
</div>

<ng-template #fileChosen>
  <div class="file-upload">
    <ng-template
      [ngTemplateOutlet]="errorTemplate || defaultErrorTemplate"
      [ngTemplateOutletContext]="{ errors: control.errors }"
    >
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="previewTemplate || defaultPreviewTemplate"
      [ngTemplateOutletContext]="{ file: control.value }"
    ></ng-template>

    <div class="file-upload__actions">
      <button
        mat-button
        (click)="fileUpload.click()"
        *ngIf="rechooseEnabled"
        data-test="file-upload__rechoose-button"
      >
        <mat-icon>{{ rechooseIcon }}</mat-icon>
        <ng-container *ngIf="chooseOtherFileText; else defaultChooseOtherFile">{{
          chooseOtherFileText
        }}</ng-container>
        <ng-template #defaultChooseOtherFile>
          {{ 'file-upload.choose-other' | translate }}
        </ng-template>
      </button>
      <button
        mat-button
        (click)="control.reset()"
        *ngIf="resetEnabled"
        data-test="file-upload__reset-button"
      >
        <mat-icon>{{ resetIcon }}</mat-icon>
        <ng-container *ngIf="resetText; else defaultReset">{{ resetText }}</ng-container>
        <ng-template #defaultReset>
          {{ 'file-upload.reset' | translate }}
        </ng-template>
      </button>
      <button
        mat-button
        (click)="downloadLink.click()"
        *ngIf="downloadEnabled"
        data-test="file-upload__download-button"
      >
        <mat-icon>{{ downloadIcon }}</mat-icon>
        <ng-container *ngIf="downloadText; else defaultDownload">{{ downloadText }}</ng-container>
        <ng-template #defaultDownload>
          {{ 'file-upload.download' | translate }}
        </ng-template>
      </button>
    </div>
  </div>
  <a #downloadLink hidden [href]="control.value | objectUrl" download>Download</a>
</ng-template>

<input
  #fileUpload
  hidden
  type="file"
  [accept]="allowedMimeTypes | join : ', '"
  (change)="onFileUploadChange(fileUpload)"
/>

<ng-template #defaultPreviewTemplate let-file="file">
  <div class="file-upload__preview-wrapper">
    <img
      [src]="file | objectUrl"
      [alt]="'file-upload.uploaded-image' | translate"
      class="file-upload__preview"
    />
  </div>
  <mat-divider></mat-divider>
</ng-template>

<ng-template #defaultErrorTemplate let-errors="errors">
  <div
    *ngIf="errors?.maxFileSize"
    class="notification notification-error text--input-small"
    data-test="file-upload__error-max-file-size"
  >
    <mat-icon>error</mat-icon>
    {{
      'file-upload.max-file-size-error' | translate : { maxSize: _maxFileSize | agrHumanFileSize }
    }}
  </div>
</ng-template>
