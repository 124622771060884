import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';
import { CustomerBrsNumber } from 'src/app/shared/models/shared.models';

export const displayColumns = [
  DeliveryColumnTypes.deliveryDate,
  DeliveryColumnTypes.allShipToIds,
  DeliveryColumnTypes.externalProductId,
  DeliveryColumnTypes.productTitle,
  DeliveryColumnTypes.klwArticleGroupName,
  DeliveryColumnTypes.unitQuantity,
  DeliveryColumnTypes.vem,
  DeliveryColumnTypes.roughProtein,
  DeliveryColumnTypes.nitrogen,
  DeliveryColumnTypes.phosphorus,
  DeliveryColumnTypes.dryMatterAmount,
  DeliveryColumnTypes.roughAshes,
] as const;

export type BexOverviewViewModel = Pick<
  DeliveryLineWithDelivery,
  (typeof displayColumns)[number] | 'id' | 'deliveryId'
>;

export interface BexOverviewDataFilters {
  brsNumber: CustomerBrsNumber;
  search: string;
  dateRange: DateRange;
}
