import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { map, startWith } from 'rxjs/operators';
import { interval, Observable, of } from 'rxjs';
import { RootState } from '@next/core-lib/app';
import { LocaleService } from '@next/core-lib/i18n';
import { isPlatformBrowser } from '@angular/common';

const NOW_REFRESH_INTERVAL = 60000;

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss'],
})
export class TodayComponent {
  public customer$: Observable<string> = this.store
    .select(UserSelectors.selectCustomer)
    .pipe(map((customer) => customer?.name));

  public now$: Observable<Date> = (isPlatformBrowser(this.platformId)
    ? interval(NOW_REFRESH_INTERVAL).pipe(startWith(0))
    : of(0)
  ).pipe(map((_) => new Date()));

  public greeting$: Observable<string> = this.now$.pipe(
    map((now) => {
      const hour = now.getHours();

      if (hour < 12) {
        return this.localeService.translate('dashboard.today.greeting-morning');
      } else if (hour >= 12 && hour <= 17) {
        return this.localeService.translate('dashboard.today.greeting-afternoon');
      } else if (hour > 17 && hour <= 24) {
        return this.localeService.translate('dashboard.today.greeting-evening');
      }
      return this.localeService.translate('dashboard.today.greeting');
    }),
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}
}
