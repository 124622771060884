import { createReducer, on } from '@ngrx/store';
import * as ContractActions from './contract.actions';
import { ContractState } from '../models/contract-line.model';

export const initialState: ContractState = {
  contracts: [],
};

export const contractReducer = createReducer(
  initialState,
  on(ContractActions.loadContractsSuccess, (state, { contracts }) => ({
    ...state,
    contracts,
  })),
  on(ContractActions.clearContractsRequest, (state) => ({
    ...state,
    contracts: [],
  })),
);
