import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  AgrChipsFilterModule,
  AgrDaterangePickerModule,
  AgrFilterModule,
  AgrNotificationEmptyModule,
} from '@next/core-lib/components';
import { AgrTableModule } from '@next/core-lib/table';
import { PageHierarchicalDataFilterComponent } from './page-hierarchical-data-filter.component';
import { SharedModule } from '../shared.module';
import { AgrExportModule } from '@next/core-lib/export';
import { AgrHierarchicalDataModule } from '../hierarchical-data/hierarchical-data.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PageHierarchicalDataFilterComponent],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatFormFieldModule,
    AgrDaterangePickerModule,
    AgrChipsFilterModule,
    AgrNotificationEmptyModule,
    AgrTableModule,
    AgrExportModule,
    AgrHierarchicalDataModule,
    SharedModule,
    AgrFilterModule,
    ReactiveFormsModule,
  ],
  exports: [PageHierarchicalDataFilterComponent],
})
export class PageHierarchicalDataFilterModule {}
