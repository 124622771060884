import { createAction, props } from '@ngrx/store';
import { PreferenceGroup, UserPreferenceSetting } from '../models/preferences.model';

export const loadPreferenceGroupsRequest = createAction(
  '[Preferences] Load preference groups request',
);

export const loadPreferenceGroupsSuccess = createAction(
  '[Preferences] Load preference groups success',
  props<{ preferenceGroups: PreferenceGroup[] }>(),
);

export const loadPreferenceGroupsFailed = createAction(
  '[Preferences] Load preference groups failed',
);

export const clearPreferenceGroupsRequest = createAction(
  '[Preferences] Clear preference groups request',
);

export const patchPreferenceRequest = createAction(
  '[Preferences] Patch preference request',
  props<{ preference: UserPreferenceSetting }>(),
);

export const patchPreferenceSuccess = createAction(
  '[Preferences] Patch preference success',
  props<{ preferenceGroups: PreferenceGroup[] }>(),
);

export const patchPreferenceFailure = createAction('[Preferences] Patch preference failure');
