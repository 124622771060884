import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { AgrAppModule } from '@next/core-lib/app';
import { AgrNavExtensionPanelModule } from '@next/core-lib/components';
import { AgrFeatureFlagModule, FeatureFlagService } from '@next/core-lib/featureflag';
import { AgrGoogleAnalyticsModule } from '@next/core-lib/google-analytics';
import { AgrI18nSharedModule } from '@next/core-lib/i18n';
import { TableV2Module } from '@next/core-lib/table-v2';
import { AgrWrapperSiteSidenavModule } from '@next/core-lib/wrapper-site-sidenav';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BexModule } from './bex/bex.module';
import { ContractModule } from './contract/contract.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DeliveryDataModule } from './delivery-data/delivery-data.module';
import { DeliveryModule } from './delivery/delivery.module';
import { GbmModule } from './gbm/gbm.module';
import { GreenhouseModule } from './greenhouse-gas/greenhouse.module';
import { IdentificationModule } from './identification/identification.module';
import { InboundDeliveryModule } from './inbound-delivery/inbound-delivery.module';
import { InvoiceModule } from './invoice/invoice.module';
import { MineralModule } from './mineral/mineral.module';
import { PreferencesModule } from './preferences/preferences.module';
import { RegenerativeAgricultureModule } from './regenerative-agriculture/regenerative-agriculture.module';
import { FileUploadModule } from './shared/file-upload/file-upload.module';
import { SharedModule } from './shared/shared.module';
import { AppEffects } from './store/effects';
import { appReducer } from './store/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
  localStorageSync({
    keys: ['filterModule', 'mineralModule', 'greenhouseModule'],
    rehydrate: true,
    restoreDates: false,
    checkStorageAvailability: true,
  })(reducer);

// eslint-disable-next-line
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
  ],
  imports: [
    RouterModule,
    SharedModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
        },
      },
    ),
    StoreModule.forFeature('app', appReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([AppEffects]),
    AgrNavExtensionPanelModule,
    DashboardModule,
    DeliveryModule,
    DeliveryDataModule,
    InvoiceModule,
    BexModule,
    GreenhouseModule,
    MineralModule,
    GbmModule,
    IdentificationModule,
    InboundDeliveryModule,
    ContractModule,
    PreferencesModule,
    RegenerativeAgricultureModule,
    AppRoutingModule,
    AgrAppModule,
    MatListModule,
    AgrWrapperSiteSidenavModule,
    MatExpansionModule,
    AgrGoogleAnalyticsModule,
    AgrI18nSharedModule,
    TableV2Module,
    FileUploadModule,
    AgrFeatureFlagModule,
  ],
})
export class AppModule {
  constructor(private readonly featureFlagService: FeatureFlagService) {
    this.featureFlagService.initialize('dashboard');
  }
}
