import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agrHumanFileSize',
})
export class HumanFileSizePipe implements PipeTransform {
  private readonly thresh = 1000;
  private readonly decimals = 1;

  transform(bytes: number): string {
    if (bytes < 0) {
      throw new Error('Unsupported file size');
    }

    return this.convertToHumanReadableFileSize(bytes);
  }

  private convertToHumanReadableFileSize(bytes: number) {
    if (Math.abs(bytes) < this.thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = Math.pow(10, this.decimals);

    do {
      bytes /= this.thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= this.thresh && u < units.length - 1);

    let result = bytes.toFixed(this.decimals);
    if (result.length >= 3 && result.indexOf('.0') === result.length - 2) {
      result = result.substr(0, result.length - 2);
    }

    return `${result} ${units[u]}`;
  }
}
