import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateFeature, FeatureFlags } from '@next/core-lib/featureflag';
import { MineralComponent } from './mineral.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'mineral',
        component: MineralComponent,
        canActivate: [canActivateFeature],
        data: { featureFlags: FeatureFlags.showMinerals },
      },
    ]),
  ],
})
export class MineralRoutingModule {}
