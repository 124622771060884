import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AgrIdentifierModule, AgrNotificationEmptyModule } from '@next/core-lib/components';
import { AgrExportModule } from '@next/core-lib/export';
import { TableV2Module } from '@next/core-lib/table-v2';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { PageHierarchicalDataFilterModule } from '../shared/page-hierarchical-data-filter/page-hierarchical-data-filter.module';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { SharedModule } from '../shared/shared.module';
import { InboundDeliveryOverviewComponent } from './components/inbound-delivery-overview/inbound-delivery-overview.component';
import { InboundDeliveryTotalsComponent } from './components/inbound-delivery-totals/inbound-delivery-totals.component';
import { InboundDeliveryRoutingModule } from './inbound-delivery-routing.module';
import { InboundDeliveryComponent } from './inbound-delivery.component';

@NgModule({
  declarations: [
    InboundDeliveryComponent,
    InboundDeliveryOverviewComponent,
    InboundDeliveryTotalsComponent,
  ],
  imports: [
    DeliveryDataModule,
    SharedModule,
    CommonModule,
    InboundDeliveryRoutingModule,
    RouterModule,
    PageTableFilterModule,
    MatTableModule,
    MatPaginatorModule,
    PageHierarchicalDataFilterModule,
    TableV2Module,
    MatSortModule,
    ReactiveFormsModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
  ],
})
export class InboundDeliveryModule {}
