import { RootState } from '@next/core-lib/app';
import { IdentifierData } from '@next/core-lib/components';
import { EntityState } from '@ngrx/entity';

export interface DeliveryDataModuleState extends RootState {
  recentDeliveries: EntityState<Delivery>;
  deliveries: EntityState<Delivery>;
  bex: EntityState<Delivery>;
  minerals: EntityState<Delivery>;
  inboundDeliveries: EntityState<Delivery>;
  gbm: EntityState<Delivery>;
  deliveryTotals: DeliveryTotals;
}

export interface GetDeliveriesResponse {
  deliveries: Delivery[];
}

export interface Delivery {
  id: number;
  source: string;
  externalDeliveryId: string;
  deliveryType: string;
  deliveryDate: string;
  customer: CustomerReference;
  shipTo: ShipToReference;
  supplyChainAgreements: string[];
  externalBrsNR: string;
  externalSystem: string;
  externalDocumentType: string;
  externalDeliveryNoteId: string;
  contractDescription: string;
  hasDocument: boolean;
  deliveryLines: DeliveryLine[];
  externalOrderId: string;
  grainWeightDocument: string;
  uniwinNumber: string;
}

export interface DeliveryLineWithDelivery {
  [key: string]:
    | string
    | number
    | boolean
    | Date
    | CustomerReference
    | ShipToReference
    | DeliveryLinePrice
    | IdentifierData
    | EmissionFactor
    | Co2Emissions;
  id: string;
  deliveryId: number;
  externalDeliveryId: string;
  deliveryDate: Date;
  customer: CustomerReference;
  shipTo: ShipToReference;
  externalBrsNR: string;
  currencyKey: string;
  invoiceLineId: string;
  externalProductId: string;
  productTitle: string;
  externalDeliveryNoteId: string;
  contractDescription: string;
  unitQuantity: number;
  unitQuantityMineral?: number;
  unitsDelivered: string;
  productQuantity: number;
  productUnit: string;
  externalPlantId: string;
  externalInvoiceId: string;
  admissionNumber: string;
  deliveryLinePrice?: DeliveryLinePrice;
  shipToId?: number;
  externalShipToId?: string;
  allShipToIds?: IdentifierData;
  germination?: boolean | string;
  nitrogen?: number;
  invoiceId?: number;
  skuId: number;
  unitVolumeType: string;
  emissionFactor?: EmissionFactor;
  co2Emissions?: Co2Emissions;
  itemId: string;
  itemVLDoctype: string;
  itemVLHasPdf: boolean;
}

export interface DeliveryLine {
  id: string;
  currencyKey: string;
  invoiceLineId: string;
  externalProductId: string;
  productTitle: string;
  unitQuantity: number;
  unitsDelivered: string;
  productQuantity: number;
  productUnit: string;
  externalPlantId: string;
  externalInvoiceId: string;
  admissionNumber: string;
  deliveryLinePrice?: DeliveryLinePrice;
  unitVolumeType: string;
  emissionFactor?: EmissionFactor;
  co2Emissions?: Co2Emissions;
  itemId: '0080004473000010';
  itemVLDoctype: 'LIPS';
  itemVLHasPdf: false;
}

export interface DeliveryLinePrice {
  vatPercentage: number;
  unitVatAmount: number;
  unitBrutoAmount: number;
  unitNettoAmount: number;
  brutoAmount: number;
  nettoAmount: number;
  vatAmount: number;
}

// Totals
export interface GetDeliveryTotalsResponse {
  groupedProductCategories: GroupedProductCategory[];
  shipToTotals: ShipToTotal[];
}

export interface DeliveryTotals {
  groupedProductCategories: GroupedProductCategory[];
  shipToTotals: ShipToTotal[];
}

export interface GroupedProductCategory {
  articleTotals: ArticleTotal[];
  name: string;
  totals: Totals[];
}

export interface ArticleTotal {
  externalProductId: string;
  productTitle: string;
  totals: Totals;
}

export interface ShipToTotal {
  shipTo: ShipToReference;
  totals: Totals;
}

export interface Totals {
  totalQuantityMineral: number;
  dryWeightDailyAmount: number;
  grossWeight: number;
  roughProteinTotal: number;
  nitrogenAmount: number;
  phosphorusTotal: number;
  dryMatterAmountTotal: number;
  roughAshesTotal: number;
  vemTotal: number;
  unitQuantity: number;
  unitsDelivered: string;
  productQuantity: number;
  productUnit: string;
}

export interface CustomerReference {
  id: number;
  externalCustomerId: string;
}

export interface ShipToReference {
  id?: number;
  externalShipToId: string;
  name?: string;
  shipToLegacies?: [
    {
      id: string;
      legacyType: string;
    },
  ];
}

export interface EmissionFactor {
  emissionFactorPercent00?: number;
  emissionFactorPercent40?: number;
  emissionFactorPercent80?: number;
}

export interface Co2Emissions {
  co2Total?: number;
  fossilExcludingPeatEmissions?: number;
  fossilPeatEmissions?: number;
  biogenic?: number;
  landUseChange?: number;
}

export enum DeliveryCategories {
  gbm = 'Gbm',
  bex = 'Bex',
  grain = 'Grain',
  mineral = 'Mineral',
  none = 'None',
}

export enum DeliveryColumnTypes {
  description = 'description',
  contractDescription = 'contractDescription',
  deliveryDate = 'deliveryDate',
  externalDeliveryNoteId = 'externalDeliveryNoteId',
  externalDeliveryId = 'externalDeliveryId',
  externalOrderId = 'externalOrderId',
  externalInvoiceId = 'externalInvoiceId',
  externalBrsNR = 'externalBrsNR',
  externalProductId = 'externalProductId',
  allShipToIds = 'allShipToIds',
  productTitle = 'productTitle',
  klwArticleGroupName = 'klwArticleGroupName',
  totalQuantityMineral = 'totalQuantityMineral',
  vem = 'vem',
  roughProtein = 'roughProtein',
  phosphorus = 'phosphorus',
  dryMatterAmount = 'dryMatterAmount',
  dryMatterAmountTotal = 'dryMatterAmountTotal',
  roughAshes = 'roughAshes',
  grossWeight = 'grossWeight',
  dryWeightDailyAmount = 'dryWeightDailyAmount',
  moisturePercentage = 'moisturePercentage',
  moistureDailyAveragePercentage = 'moistureDailyAveragePercentage',
  proteinPercentage = 'proteinPercentage',
  deviation = 'deviation',
  hectoliterWeight = 'hectoliterWeight',
  germination = 'germination',
  fullBarleyPercentage = 'fullBarleyPercentage',
  impurityPercentage = 'impurityPercentage',
  roughProteinAverage = 'roughProteinAverage',
  nitrogen = 'nitrogen',
  nitrogenAverage = 'nitrogenAverage',
  nitrogenTotal = 'nitrogenTotal',
  phosphateTotal = 'phosphateTotal',
  phosphorusAverage = 'phosphorusAverage',
  phosphorusTotal = 'phosphorusTotal',
  dryMatterAmountAverage = 'dryMatterAmountAverage',
  roughAshesAverage = 'roughAshesAverage',
  vemAverage = 'vemAverage',
  productGroupDescription = 'productGroupDescription',
  skuId = 'skuId',
  unitQuantity = 'unitQuantity',
  unitsDelivered = 'unitsDelivered',
  productQuantity = 'productQuantity',
  productUnit = 'productUnit',
  unitVolumeType = 'unitVolumeType',
  baseUnit = 'baseUnit',
  baseQuantity = 'baseQuantity',
  grainWeightDocument = 'grainWeightDocument',
  uniwinNumber = 'uniwinNumber',
  uniwinNumberWithExternalDeliveryNoteIdFallback = 'uniwinNumberWithExternalDeliveryNoteIdFallback',
  crudeProtein = 'crudeProtein',
  emissionFactorPercent00 = 'emissionFactorPercent00',
  emissionFactorPercent40 = 'emissionFactorPercent40',
  emissionFactorPercent80 = 'emissionFactorPercent80',
  co2Total = 'co2Total',
  fossilExcludingPeatEmissions = 'fossilExcludingPeatEmissions',
  fossilPeatEmissions = 'fossilPeatEmissions',
  biogenic = 'biogenic',
  landUseChange = 'landUseChange',
}

export type DeliveryColumn = keyof Totals | DeliveryColumnTypes;
