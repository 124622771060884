import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DaterangeConfig } from '@next/core-lib/components';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { FormatLocationPipe } from '@next/core-lib/pipes';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { settingsContextName, SettingsKeys } from 'src/app/shared/models/shared.models';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import {
  invoiceColumns,
  InvoiceOverviewDataFilters,
  InvoiceOverviewViewModel,
  InvoiceView,
} from '../../models/invoice.model';
import { InvoiceOverviewDataSource } from '../../datasource/invoice-overview.datasource';
import { InvoiceDataService } from 'src/app/invoice-data/services/invoice-data.service';
import { getInvoiceDocumentRequest } from 'src/app/invoice-data/store/invoice-data.actions';
import { Store } from '@ngrx/store';
import { RootState } from '@next/core-lib/app';
import { NotificationActions } from '@next/core-lib/notification';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FormatLocationPipe],
})
export class InvoiceOverviewComponent implements OnInit, OnDestroy {
  readonly dataSource = new InvoiceOverviewDataSource(this._invoiceDataService, this.localeService);
  // Need to add a new display column ('action') in order to display a new table column (matColumnDef)
  readonly displayColumns = [...invoiceColumns, 'action'];
  readonly subscriptions: Subscription[] = [];
  public readonly invoiceViewTypes: string[] = Object.keys(InvoiceView);
  public invoiceView: string;
  public selectedInvoices: InvoiceOverviewViewModel[] = [];

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(),
    locations: new FormControl(),
    dateRange: new FormControl(),
  });

  readonly changeInvoiceView = new FormControl();

  readonly exportOptions: ExportOptionsV2;

  constructor(
    private readonly store: Store<RootState>,
    private readonly _invoiceDataService: InvoiceDataService,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
        {
          name: 'PDF',
          export: () => this.downloadPdf(),
        },
      ],
      fileName: this.localeService.translate('invoice.invoice-overview.fileName'),
      columns: invoiceColumns.map((col) => ({
        id: col,
        name: this.localeService.translate(`invoice.invoice-overview.${col}`),
      })),
    };
  }

  ngOnInit(): void {
    this.changeInvoiceView.setValue(InvoiceView.overview);

    this.subscriptions.push(
      this.searchAndFilters.valueChanges.subscribe(() => {
        this.dataSource.applyFilters(this.searchAndFilters.value as InvoiceOverviewDataFilters);
      }),
    );

    this.activatedRoute.params.subscribe((params) => {
      const invoiceId = params.invoiceId;
      if (invoiceId) {
        setTimeout(() => {
          this.searchAndFilters.get('search').setValue(invoiceId);
        });
      }
    });

    this.subscriptions.push(
      this.changeInvoiceView.valueChanges.subscribe((showInvoiceView) =>
        this.settingsService
          .setSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.invoiceView,
            data: showInvoiceView,
          })
          .pipe(take(1))
          .subscribe((_) => {}),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public downloadInvoice(invoice: InvoiceOverviewViewModel) {
    this.store.dispatch(
      getInvoiceDocumentRequest({
        ids: [invoice.id],
        shipToIds: [Number(invoice.shipToId)],
      }),
    );
  }

  isEqual(a: InvoiceOverviewViewModel, b: InvoiceOverviewViewModel) {
    return a.id === b.id && a.id === b.id;
  }

  resetFilters() {
    this.searchAndFilters.reset({
      search: null,
      locations: null,
      dateRange: new DaterangeConfig().config.initial,
    });
  }

  onSelectionChanged(selection: InvoiceOverviewViewModel[]) {
    this.selectedInvoices = selection;
  }

  public downloadPdf = () => {
    if (this.selectedInvoices.length !== 0) {
      this.store.dispatch(
        getInvoiceDocumentRequest({
          ids: this.selectedInvoices.map((invoice: InvoiceOverviewViewModel) => invoice.id),
          shipToIds: this.selectedInvoices.map((invoice: InvoiceOverviewViewModel) =>
            Number(invoice.shipToId),
          ),
        }),
      );
    } else {
      this.store.dispatch(
        NotificationActions.warningSnackbar({
          message: this.localeService.translate(
            'invoice.invoice-overview.no-selection-for-pdf-download-warning',
          ),
        }),
      );
    }
  };
}
