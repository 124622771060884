export const fileToDateUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
      } else {
        reject('Unexpected result');
      }
    };
    reader.readAsDataURL(file);
  });
