import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomerGuard } from '@next/core-lib/relationships';
import { RegenerativeAgricultureComponent } from './regenerative-agriculture.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'regenerative-agriculture-dashboard',
        component: RegenerativeAgricultureComponent,
        canActivate: [CustomerGuard],
      },
    ]),
  ],
})
export class RegenerativeAgricultureRoutingModule {}
