import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { Invoice, InvoiceDataModuleState, InvoiceTotal } from '../invoice-data.model';
import * as InvoiceDataActions from './invoice-data.actions';

export const adapter: EntityAdapter<Invoice> = createEntityAdapter<Invoice>();

export const totalAdapter: EntityAdapter<InvoiceTotal> = createEntityAdapter<InvoiceTotal>();

export const initialState: InvoiceDataModuleState = {
  recentInvoices: adapter.getInitialState([]),
  invoices: adapter.getInitialState([]),
  invoiceTotals: [],
} as InvoiceDataModuleState;

const reducer = createReducer(
  initialState,
  on(InvoiceDataActions.loadInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    invoices: adapter.setAll(invoices, state.invoices),
  })),
  on(InvoiceDataActions.clearInvoicesRequest, (state) => ({
    ...state,
    invoices: adapter.removeAll(state.invoices),
  })),
  on(InvoiceDataActions.loadInvoiceTotalsSuccess, (state, { invoiceTotals }) => ({
    ...state,
    invoiceTotals,
  })),
  on(InvoiceDataActions.clearInvoiceTotalsRequest, (state) => ({
    ...state,
    invoiceTotals: [],
  })),
  on(InvoiceDataActions.loadRecentInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    recentInvoices: adapter.setAll(invoices, state.recentInvoices),
  })),
  on(InvoiceDataActions.clearRecentInvoicesRequest, (state) => ({
    ...state,
    recentInvoices: adapter.removeAll(state.recentInvoices),
  })),
);

export const invoiceDataReducer = (state: InvoiceDataModuleState | undefined, action: Action) =>
  reducer(state, action);
