<div class="page-table-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ 'invoice.title' | translate }}</h1>

  <agr-table-v2
    matSort
    [dataSource]="dataSource"
    [isEqual]="isEqual"
    [withSelection]="true"
    (selectionChanged)="onSelectionChanged($event)"
    (filterClearClick)="resetFilters()"
    class="invoice-overview__table-spaced-cols"
  >
    <ng-container *agrTableSearch [formGroup]="searchAndFilters">
      <agr-table-search
        formControlName="search"
        [label]="'search' | translate"
        [placeholder]="'invoice.search-placeholder' | translate"
      >
      </agr-table-search>
    </ng-container>

    <ng-container *agrTableFilters [formGroup]="searchAndFilters">
      <agr-table-multi-select-filter
        agrTableFilterLocations
        [label]="'page-hierarchical-data-filter.locations' | translate"
        formControlName="locations"
      >
      </agr-table-multi-select-filter>

      <agr-table-date-range-filter
        [label]="'daterange-picker.period' | translate"
        formControlName="dateRange"
      >
      </agr-table-date-range-filter>
    </ng-container>

    <agr-table-top-bar *agrTableTopBar class="invoice-overview__top-bar">
      <agr-table-selected-items></agr-table-selected-items>
      <div
        class="flex--row flex--gutter flex--nowrap flex--align-center flex--justify-space-between"
      >
        <mat-label class="invoice--label">
          {{ 'page-table-filter.invoices-view.placeholder' | translate }}:
        </mat-label>
        <mat-radio-group [formControl]="changeInvoiceView">
          <mat-radio-button
            *ngFor="let viewType of invoiceViewTypes"
            [value]="viewType"
            class="invoice--radio-button"
            color="primary"
          >
            {{ 'page-table-filter.invoices-view.' + viewType | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <agr-export agrTableExport [options]="exportOptions" [dataSource]="dataSource">
        </agr-export>
      </div>
    </agr-table-top-bar>

    <ng-container matColumnDef="invoiceDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'invoice.invoice-overview.invoiceDate' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'invoice.invoice-overview.invoiceDate' | translate"
        *matCellDef="let row"
      >
        {{ row.invoiceDate | agrDate : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="externalInvoiceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'invoice.invoice-overview.externalInvoiceId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'invoice.invoice-overview.externalInvoiceId' | translate"
        *matCellDef="let row"
      >
        {{ row.externalInvoiceId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="allShipToIds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'invoice.invoice-overview.allShipToIds' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'invoice.invoice-overview.allShipToIds' | translate"
        *matCellDef="let row"
      >
        <agr-identifier-trigger
          [data]="row.allShipToIds"
          [hideDescription]="true"
          [onlyUnique]="true"
          (click)="$event.stopPropagation()"
        ></agr-identifier-trigger>
      </td>
    </ng-container>

    <ng-container matColumnDef="invoiceDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'invoice.invoice-overview.invoiceDescription' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'invoice.invoice-overview.invoiceDescription' | translate"
        *matCellDef="let row"
      >
        {{ row.invoiceDescription }}
      </td>
    </ng-container>

    <ng-container matColumnDef="brutoAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'invoice.invoice-overview.brutoAmount' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'invoice.invoice-overview.brutoAmount' | translate"
        *matCellDef="let row"
      >
        {{ row.brutoAmount | agrCurrency : row.currencyKey : 'symbol-narrow' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>{{ 'table.action' | translate }}</th>
      <td
        mat-cell
        [attr.aria-label]="'invoice.invoice-overview.externalInvoiceId' | translate"
        *matCellDef="let row"
      >
        <button mat-button (click)="downloadInvoice(row)">
          <mat-icon class="action-icon">file_download</mat-icon>
          <span>{{ 'invoice.invoice-overview.download-invoice' | translate }}</span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: displayColumns"></tr>
    <tr *matNoDataRow>
      <td class="text--center" [attr.colspan]="displayColumns.length">
        <agr-notification-empty
          data-test="hierarchical-data__empty"
          [isEmpty]="true"
          [emptyTitle]="'invoice.empty-title' | translate"
          [emptyDescription]="'invoice.empty-description' | translate"
          [emptyImage]="'/assets/images/no-items-3.png'"
          [emptyActionIcon]="'history'"
          [emptyActionText]="'page-table-filter.reset-filters' | translate"
          [showEmptyAction]="true"
          (emptyAction)="resetFilters()"
          class="spacing--margin-top"
        ></agr-notification-empty>
      </td>
    </tr>
  </agr-table-v2>
</div>
