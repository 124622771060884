import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '@next/core-lib/app';
import * as PreferenceActions from '../../store/preferences.actions';
import * as PreferenceSelectors from '../../store/preferences.selectors';
import { UserPreferenceSetting } from '../../models/preferences.model';

@Component({
  selector: 'app-preferences-overview',
  templateUrl: './preferences-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./preferences-overview.component.scss'],
})
export class PreferencesOverviewComponent implements OnDestroy {
  public preferenceGroups$ = this.store.select(PreferenceSelectors.getPreferenceGroups);

  constructor(private readonly store: Store<RootState>) {}

  ngOnDestroy(): void {
    this.store.dispatch(PreferenceActions.clearPreferenceGroupsRequest());
  }

  updateUserSetting(groupKey: string, subGroupKey: string, method: string, enabled: boolean) {
    const userPreferenceSetting: UserPreferenceSetting = {
      groupKey,
      subGroupKey,
      method,
      enabled,
    };

    this.store.dispatch(
      PreferenceActions.patchPreferenceRequest({ preference: userPreferenceSetting }),
    );
  }
}
