import { Injectable, Inject } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../../../environment';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { ReportRequest } from '../models/report.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(
    private readonly http: HttpClient,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  public createReport(data: ReportRequest): Observable<HttpResponse<Blob>> {
    return this.http.post(
      `${this.env.API_BASE_URL}/service/customer-dashboard-report/secure/report/create`,
      data,
      {
        responseType: 'blob',
        observe: 'response',
      },
    );
  }
}
