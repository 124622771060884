<app-page-table-filter
  [pageTitle]="'invoice.title' | translate"
  [searchFieldPlaceholder]="'invoice.search-placeholder' | translate"
  [emptyTitle]="'invoice.empty-title' | translate"
  [emptyDescription]="'invoice.empty-description' | translate"
  [tableConfig]="tableConfig"
  [data$]="data$"
  (loadDataCallback)="loadData()"
  [exportFilename]="exportFilename"
  [showSearch]="false"
  [showTotals]="false"
  [showInvoiceView]="true"
  [settingsActions]="filterActions"
>
</app-page-table-filter>
