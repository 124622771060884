import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from 'src/environment';

@Component({
  selector: 'app-regenerative-agriculture',
  templateUrl: './regenerative-agriculture.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegenerativeAgricultureComponent {
  public readonly workspaceId: string;
  public readonly reportId: string;

  constructor(@Inject(EnvironmentInjector) private readonly env: Environment) {
    this.workspaceId = this.env.REGENERATIVE_AGRICULTURE_WORKSPACE_ID;
    this.reportId = this.env.REGENERATIVE_AGRICULTURE_REPORT_ID;
  }
}
