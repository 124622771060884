import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { DownloadBlobService } from '../shared/services/download-blob.service';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { PageHierarchicalDataFilterModule } from '../shared/page-hierarchical-data-filter/page-hierarchical-data-filter.module';
import { AgrExtendedNotificationModule } from '@next/core-lib/components';
import { AgrDialogModule } from '@next/core-lib/dialog';
import { TableV2Module } from '@next/core-lib/table-v2';
import { DeliveryDataService } from '../delivery-data/services/delivery-data.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { AgrIdentifierModule, AgrNotificationEmptyModule } from '@next/core-lib/components';
import { AgrExportModule } from '@next/core-lib/export';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GreenhouseComponent } from './greenhouse.component';
import { MineralRoutingModule } from '../mineral/mineral-routing.module';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { GreenhouseRoutingModule } from './greenhouse-routing.module';
import { GreenhouseOverviewComponent } from './components/greenhouse-overview/greenhouse-overview.component';
import { GreenhouseNoticeComponent } from './components/greenhouse-notice/greenhouse-notice.component';
import { StoreModule } from '@ngrx/store';
import { greenhouseReducer } from './store/greenhouse.reducer';

@NgModule({
  declarations: [GreenhouseComponent, GreenhouseOverviewComponent, GreenhouseNoticeComponent],
  imports: [
    DeliveryDataModule,
    GreenhouseRoutingModule,
    SharedModule,
    CommonModule,
    MineralRoutingModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    PageTableFilterModule,
    PageHierarchicalDataFilterModule,
    AgrExtendedNotificationModule,
    AgrDialogModule,
    TableV2Module,
    MatSortModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
    StoreModule.forFeature('greenhouseModule', greenhouseReducer)
  ],
  providers: [DownloadBlobService, DeliveryDataService],
})
export class GreenhouseModule {}
