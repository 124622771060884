import { CollectionViewer } from '@angular/cdk/collections';
import { IdentifierData } from '@next/core-lib/components';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  Delivery,
  DeliveryCategories,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';
import {
  OrderOptions,
  PageAndSize,
  Paginated,
} from 'src/app/delivery-data/models/paginated-deliveries.model';
import { DeliveryDataService } from 'src/app/delivery-data/services/delivery-data.service';
import {
  AgrDataSource,
  ExportableDataSource,
  ExportListItemV2,
  FilterableDataSource,
  PaginatedDataSource,
  PaginationModel,
  SortableDataSource,
  Sorting,
} from '@next/core-lib/table-v2';
import { DeliveryOverviewDataFilters, DeliveryOverviewViewModel } from '../models/delivery.model';
import { subYears } from 'date-fns';

export class DeliveryOverviewDataSource
  extends AgrDataSource<DeliveryOverviewViewModel>
  implements
    ExportableDataSource<DeliveryOverviewViewModel>,
    FilterableDataSource<DeliveryOverviewDataFilters, DeliveryOverviewViewModel>,
    PaginatedDataSource<DeliveryOverviewViewModel>,
    SortableDataSource<DeliveryOverviewViewModel> {
  private readonly _filtersSubject = new ReplaySubject<DeliveryOverviewDataFilters>(1);
  private readonly _sortingSubject = new BehaviorSubject<Sorting>({ deliveryDate: 'desc' });
  private readonly _pageAndSizeSubject = new BehaviorSubject<PageAndSize>({
    pageIndex: 0,
    pageSize: 25,
  });
  private readonly _destroyPaginatorSubject = new Subject<void>();
  private readonly _destroySubject = new Subject<void>();
  private readonly _data$: Observable<Paginated<Delivery>>;
  private readonly _results$: Observable<readonly DeliveryOverviewViewModel[]>;
  private readonly _order$: Observable<OrderOptions>;

  constructor(private _deliveryDataService: DeliveryDataService) {
    super();

    this._order$ = this._sortingSubject.pipe(
      map((sorting) => {
        const sortColumn = Object.keys(sorting)[0];
        const sortDirection = sorting[sortColumn];

        return sortColumn && sortDirection
          ? { column: sortColumn, descending: sorting[sortColumn] === 'desc' }
          : undefined;
      }),
    );

    this._data$ = combineLatest([
      this._pageAndSizeSubject,
      this._filtersSubject,
      this._order$,
    ]).pipe(
      // debounceTime prevents sending unnecessary requests to backend when interacting with the table
      // e.g.: ordering/pagination or filtering/pagination actions when not on the first table page
      debounceTime(10),
      tap((_) => this.loadingSubject.next(true)),
      switchMap(([pagination, filters, order]) =>
        this._deliveryDataService.getDeliveriesPaginated(
          filters.dateRange.startDate,
          filters.dateRange.endDate,
          {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
          {
            term: filters.search,
          },
          filters.locations,
          order,
          DeliveryCategories.none,
        ),
      ),
      shareReplay(1),
    );

    this._results$ = this._data$.pipe(
      filter((x) => !!x?.results),
      map((x) => x.results),
      map((results) => results.flatMap(this.mapDeliveryForOverview)),
      tap((_) => this.loadingSubject.next(false)),
      catchError((error) => {
        this.loadingSubject.next(false);
        return throwError(error);
      }),
      shareReplay(1),
    );
  }

  connect(_: CollectionViewer): Observable<readonly DeliveryOverviewViewModel[]> {
    return this._results$;
  }

  disconnect(_: CollectionViewer): void {
    if (!this._destroySubject.isStopped) {
      this._destroySubject.next();
      this._destroySubject.complete();
    }
  }

  applyFilters(filters: DeliveryOverviewDataFilters) {
    this._filtersSubject.next(filters);
    this.resetPagination();
  }

  applyPage(pageIndex: number, pageSize: number): void {
    this._pageAndSizeSubject.next({ pageIndex, pageSize });
  }

  applySorting(sorting: Sorting): void {
    this._sortingSubject.next(sorting);
    this.resetPagination();
  }

  prepareExportData(
    item: ExportListItemV2,
    selection: DeliveryOverviewViewModel[],
    allPagesSelected: boolean,
  ) {
    if (item.name === 'PDF') {
      return allPagesSelected ? this.fetchDeliveryBatched() : of(selection);
    }

    let data$: Observable<DeliveryOverviewViewModel[]>;
    data$ =
      selection?.length > 0 && !allPagesSelected ? of(selection) : this.fetchDeliveryBatched();

    data$ = data$.pipe(
      map((data) =>
        data.map((delivery) => ({
          ...delivery,
          allShipToIds: delivery.allShipToIds.identifiers[0].value as unknown as IdentifierData,
        })),
      ),
    );

    return data$;
  }

  fetchDeliveryBatched(): Observable<DeliveryOverviewViewModel[]> {
    const allDeliveries$ = combineLatest([this._data$, this._filtersSubject])
      .pipe(
        exhaustMap(([data, filters]) => {
          const totalResultCount = data.metadata.totalResultCount;

          if (totalResultCount === data.results.length) {
            return of(data.results).pipe(
              map((results) => results.flatMap(this.mapDeliveryForOverview)),
            );
          }

          const batchSize = 1000;
          const batchCount = Math.ceil(totalResultCount / batchSize);
          const batches: Observable<DeliveryOverviewViewModel[]>[] = [];

          for (let pageIndex = 0; pageIndex < batchCount; pageIndex++) {
            batches.push(this.getDeliveryBatch(filters, { pageIndex, pageSize: batchSize }));
          }

          return this.mergeBatches(batches);
        }),
      )
      .pipe(take(1));

    return allDeliveries$;
  }

  connectPaginator(): Observable<PaginationModel> {
    return combineLatest([this._data$, this._pageAndSizeSubject]).pipe(
      takeUntil(this._destroyPaginatorSubject),
      map(([data, { pageIndex, pageSize }]) => ({
        pageSize,
        pageIndex,
        length: data.metadata.totalResultCount,
      })),
    );
  }

  disconnectPaginator(): void {
    if (!this._destroyPaginatorSubject.isStopped) {
      this._destroyPaginatorSubject.next();
      this._destroyPaginatorSubject.complete();
    }
  }

  resetPagination(): void {
    this._pageAndSizeSubject
      .pipe(take(1))
      .subscribe(({ pageSize }) => this._pageAndSizeSubject.next({ pageIndex: 0, pageSize }));
  }

  getDeliveryBatch(filters: DeliveryOverviewDataFilters, pagination: PageAndSize) {
    return this._deliveryDataService
      .getDeliveriesPaginated(
        filters.dateRange.startDate,
        filters.dateRange.endDate,
        pagination,
        {
          term: filters.search,
        },
        filters.locations,
        undefined,
        DeliveryCategories.none,
        undefined,
        true,
      )
      .pipe(map(({ results }) => results.flatMap(this.mapDeliveryForOverview)));
  }

  mergeBatches(batches: Observable<DeliveryOverviewViewModel[]>[]) {
    return forkJoin(batches).pipe(
      map((batchedResults: DeliveryOverviewViewModel[][]) =>
        ([] as DeliveryOverviewViewModel[]).concat(...batchedResults),
      ),
    );
  }

  private mapDeliveryForOverview = (delivery: Delivery): DeliveryOverviewViewModel[] =>
    (delivery.deliveryLines as DeliveryLineWithDelivery[]).map((line) =>
      this.mapDeliveryLinesForOverview(line, delivery),
    );

  private mapDeliveryLinesForOverview = (
    deliveryLine: DeliveryLineWithDelivery,
    delivery: Delivery,
  ): DeliveryOverviewViewModel => ({
    id: deliveryLine.id,
    deliveryId: delivery.id,
    deliveryDate: new Date(delivery.deliveryDate),
    externalDeliveryId: delivery.externalDeliveryId,
    externalOrderId: delivery.externalOrderId,
    externalInvoiceId: deliveryLine.externalInvoiceId,
    externalProductId: deliveryLine.externalProductId,
    invoiceLineId: deliveryLine.invoiceLineId,
    productTitle: deliveryLine.productTitle,
    unitsDelivered: deliveryLine.unitsDelivered,
    unitVolumeType: deliveryLine.unitVolumeType,
    baseQuantity: deliveryLine.baseQuantity,
    baseUnit: deliveryLine.baseUnit,
    quantity: deliveryLine.quantity,
    skuId: deliveryLine.skuId,
    invoiceId: deliveryLine.invoiceId,
    shipTo: delivery.shipTo,
    externalSystem: delivery.externalSystem,
    itemId: deliveryLine.itemId,
    itemVLDoctype: deliveryLine.itemVLDoctype,
    itemVLHasPdf: deliveryLine.itemVLHasPdf,
    itemCanBeReturned: this.setItemCanBeReturned(deliveryLine, delivery),
    allShipToIds: {
      identifiers: [
        {
          description: '',
          value: delivery.shipTo.externalShipToId,
        },
        ...delivery.shipTo?.shipToLegacies.map((legacyItem) => ({
          description: legacyItem.legacyType,
          value: legacyItem.id,
        })),
      ],
    },
  });

  private setItemCanBeReturned(
    deliveryLine: DeliveryLineWithDelivery,
    delivery: Delivery,
  ): boolean {
    return (
      deliveryLine.invoiceId &&
      deliveryLine.skuId &&
      parseFloat(deliveryLine.unitsDelivered) > 0 &&
      new Date(delivery.deliveryDate) > subYears(new Date(), 2)
    );
  }
}
