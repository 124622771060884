<app-page-hierarchical-data-filter
  [pageTitle]="'bex.title' | translate"
  [searchFieldPlaceholder]="'bex.search-placeholder' | translate"
  [emptyTitle]="'bex.empty-title' | translate"
  [emptyDescription]="'bex.empty-description' | translate"
  [data$]="data$"
  [headings]="headings"
  (loadDataCallback)="loadData()"
  [settingsActions]="filterActions"
  [exportFilename]="exportFilename"
  [exportPdfCallback]="downloadPdf"
  [filterOnBrs]="true"
  [showLegacyTotalWarning]="true"
></app-page-hierarchical-data-filter>
