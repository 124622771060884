import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';

export const deliveryColumns = [
  DeliveryColumnTypes.deliveryDate,
  DeliveryColumnTypes.externalDeliveryId,
  DeliveryColumnTypes.externalOrderId,
  DeliveryColumnTypes.externalInvoiceId,
  DeliveryColumnTypes.allShipToIds,
  DeliveryColumnTypes.externalProductId,
  DeliveryColumnTypes.productTitle,
  DeliveryColumnTypes.unitsDelivered,
  DeliveryColumnTypes.unitVolumeType,
  DeliveryColumnTypes.baseQuantity,
  DeliveryColumnTypes.baseUnit,
] as const;

export type DeliveryOverviewViewModel = Pick<
  DeliveryLineWithDelivery,
  | (typeof deliveryColumns)[number]
  | 'id'
  | 'deliveryId'
  | 'invoiceId'
  | 'skuId'
  | 'quantity'
  | 'shipTo'
  | 'invoiceLineId'
  | 'itemId'
  | 'itemVLDoctype'
  | 'itemVLHasPdf'
  | 'itemCanBeReturned'
  | 'externalSystem'
>;

export interface DeliveryOverviewDataFilters {
  locations: number[];
  search: string;
  dateRange: DateRange;
}
