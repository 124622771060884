import { createReducer, on } from '@ngrx/store';
import { IdentificationState } from '../models/identification.state';
import {
  insertIdentification,
  insertIdentificationFailure,
  insertIdentificationSuccess,
  showIdentification,
} from './actions';

const initialState = {
  show: false,
};

export const identificationReducer = createReducer<IdentificationState>(
  initialState,
  on(showIdentification, () => ({
    show: true,
  })),
  on(insertIdentification, (state, { data, fileName, mediaType }) => ({
    ...state,
    insertIdentification: { data, fileName, mediaType },
  })),
  on(insertIdentificationSuccess, (state) => ({
    ...state,
    insertIdentification: undefined,
    show: false,
  })),
  on(insertIdentificationFailure, (state) => ({
    ...state,
    insertIdentification: undefined,
    show: false,
  })),
);
