import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
  AgrIdentifierModule,
  AgrNotificationEmptyModule,
  AgrTotalsLegacyWarningDialogModule,
} from '@next/core-lib/components';
import { AgrExportModule } from '@next/core-lib/export';
import { TableV2Module } from '@next/core-lib/table-v2';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { DeliveryDataService } from '../delivery-data/services/delivery-data.service';
import { PageHierarchicalDataFilterModule } from '../shared/page-hierarchical-data-filter/page-hierarchical-data-filter.module';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { SharedModule } from '../shared/shared.module';
import { BexRoutingModule } from './bex-routing.module';
import { BexComponent } from './bex.component';
import { BexOverviewComponent } from './components/bex-overview/bex-overview.component';
import { BexTotalsComponent } from './components/bex-totals/bex-totals.component';

@NgModule({
  declarations: [BexComponent, BexOverviewComponent, BexTotalsComponent],
  imports: [
    DeliveryDataModule,
    SharedModule,
    CommonModule,
    BexRoutingModule,
    RouterModule,
    PageTableFilterModule,
    PageHierarchicalDataFilterModule,
    TableV2Module,
    MatSortModule,
    ReactiveFormsModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
    AgrTotalsLegacyWarningDialogModule,
    SharedModule,
  ],
  providers: [DeliveryDataService],
})
export class BexModule {}
