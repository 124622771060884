<div class="page-table-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ 'inbound-delivery.title' | translate }}</h1>

  <agr-table-v2
    matSort
    [dataSource]="dataSource"
    [isEqual]="isEqual"
    [withSelection]="true"
    (filterClearClick)="resetFilters()"
    class="inbound-delivery-overview__table-spaced-cols"
  >
    <ng-container *agrTableSearch [formGroup]="searchAndFilters">
      <agr-table-search
        formControlName="search"
        [label]="'search' | translate"
        [placeholder]="'inbound-delivery.search-placeholder' | translate"
      >
      </agr-table-search>
    </ng-container>

    <ng-container *agrTableFilters [formGroup]="searchAndFilters">
      <agr-table-multi-select-filter
        agrTableFilterLocations
        [label]="'page-hierarchical-data-filter.locations' | translate"
        formControlName="locations"
      >
      </agr-table-multi-select-filter>

      <agr-table-date-range-filter
        [label]="'daterange-picker.period' | translate"
        formControlName="dateRange"
      >
      </agr-table-date-range-filter>
    </ng-container>

    <agr-table-top-bar *agrTableTopBar class="inbound-delivery-overview__top-bar">
      <agr-table-selected-items></agr-table-selected-items>
      <div
        class="flex--row flex--gutter flex--nowrap flex--align-center flex--justify-space-between"
      >
        <mat-slide-toggle
          color="primary"
          [formControl]="showTotalsToggle"
          [attr.data-test]="'filter__item-showTotals'"
        >
          {{ 'page-table-filter.show-totals' | translate }}
        </mat-slide-toggle>
        <agr-export agrTableExport [options]="exportOptions" [dataSource]="dataSource">
        </agr-export>
      </div>
    </agr-table-top-bar>

    <ng-container matColumnDef="deliveryDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.deliveryDate' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'inbound-delivery.inbound-delivery-overview.deliveryDate' | translate"
        *matCellDef="let row"
      >
        {{ row.deliveryDate | agrDate : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="externalDeliveryNoteId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.externalDeliveryNoteId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.externalDeliveryNoteId' | translate
        "
        *matCellDef="let row"
      >
        {{ row.uniwinNumberWithExternalDeliveryNoteIdFallback }}
      </td>
    </ng-container>

    <ng-container matColumnDef="grainWeightDocument">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.grainWeightDocument' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.grainWeightDocument' | translate
        "
        *matCellDef="let row"
      >
        {{ row.grainWeightDocument }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contractDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.contractDescription' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.contractDescription' | translate
        "
        *matCellDef="let row"
      >
        {{ row.contractDescription }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.productTitle' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'inbound-delivery.inbound-delivery-overview.productTitle' | translate"
        *matCellDef="let row"
      >
        {{ row.productTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="grossWeight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.grossWeight' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'inbound-delivery.inbound-delivery-overview.grossWeight' | translate"
        *matCellDef="let row"
      >
        {{ row.grossWeight | agrNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dryWeightDailyAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.dryWeightDailyAmount' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.dryWeightDailyAmount' | translate
        "
        *matCellDef="let row"
      >
        {{ row.dryWeightDailyAmount | agrNumber : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="moisturePercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.moisturePercentage' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.moisturePercentage' | translate
        "
        *matCellDef="let row"
      >
        {{ row.moisturePercentage | agrNumber : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="moistureDailyAveragePercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{
          'inbound-delivery.inbound-delivery-overview.moistureDailyAveragePercentage' | translate
        }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.moistureDailyAveragePercentage' | translate
        "
        *matCellDef="let row"
      >
        {{ row.moistureDailyAveragePercentage | agrNumber : '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="proteinPercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.proteinPercentage' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.proteinPercentage' | translate
        "
        *matCellDef="let row"
      >
        {{ row.proteinPercentage }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deviation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.deviation' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'inbound-delivery.inbound-delivery-overview.deviation' | translate"
        *matCellDef="let row"
      >
        {{ row.deviation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hectoliterWeight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.hectoliterWeight' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.hectoliterWeight' | translate
        "
        *matCellDef="let row"
      >
        {{ row.hectoliterWeight | agrNumber : '1.1-1' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="germination">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.germination' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'inbound-delivery.inbound-delivery-overview.germination' | translate"
        *matCellDef="let row"
      >
        {{
          !!row.germination
            ? ('inbound-delivery.inbound-delivery-overview.germination-true' | translate)
            : ('inbound-delivery.inbound-delivery-overview.germination-false' | translate)
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fullBarleyPercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.fullBarleyPercentage' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.fullBarleyPercentage' | translate
        "
        *matCellDef="let row"
      >
        {{ row.fullBarleyPercentage }}
      </td>
    </ng-container>

    <ng-container matColumnDef="impurityPercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'inbound-delivery.inbound-delivery-overview.impurityPercentage' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'inbound-delivery.inbound-delivery-overview.impurityPercentage' | translate
        "
        *matCellDef="let row"
      >
        {{ row.impurityPercentage }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: displayColumns"></tr>
    <tr *matNoDataRow>
      <td class="text--center" [attr.colspan]="displayColumns.length">
        <agr-notification-empty
          data-test="hierarchical-data__empty"
          [isEmpty]="true"
          [emptyTitle]="'inbound-delivery.empty-title' | translate"
          [emptyDescription]="'inbound-delivery.empty-description' | translate"
          [emptyImage]="'/assets/images/no-items-3.png'"
          [emptyActionIcon]="'history'"
          [emptyActionText]="'page-table-filter.reset-filters' | translate"
          [showEmptyAction]="true"
          (emptyAction)="resetFilters()"
          class="spacing--margin-top"
        ></agr-notification-empty>
      </td>
    </tr>
  </agr-table-v2>
</div>
