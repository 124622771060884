import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RootState } from '@next/core-lib/app';
import { CartActions } from '@next/core-lib/cart';
import { DaterangeConfig } from '@next/core-lib/components';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { NotificationActions } from '@next/core-lib/notification';
import { AuthContext, AuthenticationActions } from '@next/core-lib/authentication';

import { SessionActions, SessionSelectors, UserSession } from '@next/core-lib/session';
import { SettingsActions, SettingsStorage } from '@next/core-lib/settings';
import { TableColumnTypes } from '@next/core-lib/table';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import { ActionsSubject, Store } from '@ngrx/store';
import { getInvoiceDocumentRequest } from 'src/app/invoice-data/store/invoice-data.actions';
import { Environment } from 'src/environment';
import { DeliveryColumnTypes } from '../../../delivery-data/delivery-data.model';
import { DeliveryDataService } from '../../../delivery-data/services/delivery-data.service';
import {
  getDeliveryDocumentRequest,
  getMSDSRequest,
  getQualityCertificateRequest,
} from '../../../delivery-data/store/delivery-data.actions';
import { settingsContextName, SettingsKeys } from '../../../shared/models/shared.models';
import { DeliveryOverviewDataSource } from '../../datasource/deliveries-overview.datasource';
import {
  deliveryColumns,
  DeliveryOverviewDataFilters,
  DeliveryOverviewViewModel,
} from '../../models/delivery.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Observable, race } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { map, switchMap, take } from 'rxjs/operators';
import { UserSelectors } from '@next/core-lib/user';
import { FeatureFlags, FeatureFlagService } from '@next/core-lib/featureflag';
import { AuthorizationSelectors } from '@next/core-lib/authorization';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-delivery-overview',
  templateUrl: './delivery-overview.component.html',
  styleUrls: ['./delivery-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryOverviewComponent implements OnInit {
  readonly dataSource = new DeliveryOverviewDataSource(this.deliveryDataService);
  readonly displayColumns = [...deliveryColumns, 'actions'];

  readonly userSession$ = this.store.select(SessionSelectors.selectUserSession);
  userSession: UserSession = {} as UserSession;

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(),
    locations: new FormControl(),
    dateRange: new FormControl(),
  });

  readonly exportOptions: ExportOptionsV2;
  returnOrdersEnabled$: Observable<boolean>;
  hasCustomerRole$ = this.store.select(AuthorizationSelectors.hasCustomerRole);

  constructor(
    @Inject(EnvironmentInjector) public readonly environment: Environment,
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    private readonly deliveryDataService: DeliveryDataService,
    private readonly actionsListener$: ActionsSubject,
    private featureFlagService: FeatureFlagService,
  ) {
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
        {
          name: 'PDF',
          export: (data: DeliveryOverviewViewModel[]) => this.downloadPdf(data),
        },
      ],
      fileName: this.localeService.translate('delivery.delivery-overview.fileName'),
      columns: deliveryColumns.map((col) => ({
        id: col,
        name: this.localeService.translate(`delivery.delivery-overview.${col}`),
      })),
    };
  }

  ngOnInit(): void {
    this.userSession$.subscribe((userSession: UserSession) => (this.userSession = userSession));

    this.searchAndFilters.valueChanges.subscribe(() =>
      this.dataSource.applyFilters(this.searchAndFilters.value as DeliveryOverviewDataFilters),
    );

    const salesOrganization$ = this.store
      .select(UserSelectors.selectCustomer)
      .pipe(
        map((selectedCustomer) => selectedCustomer?.shipTos[0]?.salesAreas[0]?.salesOrganization),
      );

    const returFfEnabled$ = salesOrganization$.pipe(
      switchMap((salesArea) =>
        this.featureFlagService.isSalesAreaFeatureFlagEnabled(
          salesArea,
          FeatureFlags.returnOrdersEnabled,
        ),
      ),
    );

    this.returnOrdersEnabled$ = combineLatest(
      [this.hasCustomerRole$, returFfEnabled$],
      (hasCustomerRole, returnEnabled) => !hasCustomerRole && returnEnabled,
    );
  }

  isEqual(a: DeliveryOverviewViewModel, b: DeliveryOverviewViewModel) {
    return a.id === b.id && a.deliveryId === b.deliveryId;
  }

  resetFilters() {
    this.searchAndFilters.reset({
      search: null,
      locations: null,
      dateRange: new DaterangeConfig().config.initial,
    });
  }

  getTableColumnType(dCol: DeliveryColumnTypes) {
    return dCol === 'deliveryDate'
      ? TableColumnTypes.DATE
      : ['unitsDelivered', 'baseQuantity'].includes(dCol)
      ? TableColumnTypes.NUMBER
      : TableColumnTypes.STRING;
  }

  public openMSDS(delivery: DeliveryOverviewViewModel) {
    return this.store.dispatch(
      getMSDSRequest({
        shipToId: delivery.shipTo.id,
        externalProductId: delivery.externalProductId,
      }),
    );
  }

  public showInvoice(searchValue: string = ''): void {
    this.store.dispatch(
      SettingsActions.setSettings({
        settings: {
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: SettingsKeys.search,
          data: searchValue,
        },
      }),
    );
  }

  public downloadPdf(selectedDeliveries: DeliveryOverviewViewModel[]) {
    if (selectedDeliveries.length > 0) {
      this.store.dispatch(
        getDeliveryDocumentRequest({
          ids: selectedDeliveries.map((delivery) => delivery.deliveryId),
          shipToIds: selectedDeliveries.map((delivery) => delivery.shipTo.id),
        }),
      );
    } else {
      this.store.dispatch(
        NotificationActions.warningSnackbar({
          message: this.localeService.translate(
            'delivery.delivery-overview.no-selection-for-pdf-download-warning',
          ),
        }),
      );
    }
  }

  public downloadInvoice(delivery: DeliveryOverviewViewModel) {
    this.store.dispatch(
      getInvoiceDocumentRequest({
        ids: [delivery.invoiceId],
      }),
    );
  }

  public downloadDelivery(delivery: DeliveryOverviewViewModel) {
    this.store.dispatch(
      getDeliveryDocumentRequest({
        ids: [delivery.deliveryId],
        shipToIds: [delivery.shipTo.id],
      }),
    );
  }

  public downloadQualityCertificate(delivery: DeliveryOverviewViewModel) {
    this.store.dispatch(
      getQualityCertificateRequest({
        itemId: delivery.itemId as string,
        shipToId: delivery.shipTo.id,
        itemVLDoctype: delivery.itemVLDoctype as string,
        system: delivery.externalSystem as string,
      }),
    );
  }

  public addSkuToReturnOrder(delivery: DeliveryOverviewViewModel) {
    // Set up correct session variables in order to send the locationId as a header in the HTTP request.
    this.setSessionVariablesForAddSkuToReturnOrder(
      AuthContext.CustomerLocation,
      delivery.shipTo.id,
    );

    this.sendAddLineToReturnCartAction(delivery);

    // Either redirect to the cart site when the return order was successfully added,
    // or 'reset' the session variables and stay on the my-site.
    this.getAddSkuToReturnOrderResult().subscribe((result) => {
      if (result.type === CartActions.postCartSuccess.type) {
        this.redirectToCartSite();
      } else {
        this.setSessionVariablesForAddSkuToReturnOrder(AuthContext.Customer, null);
      }
    });
  }

  private setSessionVariablesForAddSkuToReturnOrder(
    authContext: AuthContext,
    deliveryShipToId: number | null,
  ) {
    this.store.dispatch(
      AuthenticationActions.setAuthContext({
        context: authContext,
      }),
    );

    this.store.dispatch(
      SessionActions.setUserSession({
        data: { ...this.userSession, locationId: deliveryShipToId },
      }),
    );
  }

  private sendAddLineToReturnCartAction(delivery: DeliveryOverviewViewModel) {
    this.store.dispatch(
      CartActions.addLineToReturnCart({
        cartLine: {
          skuId: delivery.skuId,
          quantity: Number.parseFloat(delivery.unitsDelivered) || 0,
          externalInvoiceId: delivery.externalInvoiceId,
          externalInvoiceLineId: delivery.invoiceLineId,
        },
      }),
    );
  }

  private getAddSkuToReturnOrderResult() {
    return race(
      [CartActions.postCartSuccess, NotificationActions.errorSnackbar].map((action) =>
        this.actionsListener$.pipe(ofType(action), take(1)),
      ),
    );
  }

  private redirectToCartSite() {
    const cartUrl = `${this.environment.SHOP_URL}/cart`;
    window.location.href = cartUrl;
  }
}
