import { Action, createReducer, on } from '@ngrx/store';
import { MineralModuleState } from '../models/mineral.model';
import * as MineralActions from './mineral.actions';

export const initialState: MineralModuleState = {
  mineralLegalNoticeVisible: true,
} as MineralModuleState;

const reducer = createReducer(
  initialState,
  on(MineralActions.setMineralLegalNoticeVisibility, (state, { visible }) => ({
    ...state,
    mineralLegalNoticeVisible: visible,
  })),
);

export const mineralReducer = (state: MineralModuleState | undefined, action: Action) =>
  reducer(state, action);
