import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  map,
  skipWhile,
  startWith,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { RootState } from '@next/core-lib/app';
import { ThemePalette } from '@angular/material/core';
import { TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import {
  DefaultFilterOptions,
  FilterAction,
  FilterConfig,
  FilterOption,
  FiltersConfig,
  FilterTypes,
  IdentifierData,
} from '@next/core-lib/components';
import { Settings, SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { FormControl } from '@angular/forms';
import { LocaleService } from '@next/core-lib/i18n';
import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import { ExportActions, ExportColumn, ExportFormat } from '@next/core-lib/export';
import { settingsContextName, SettingsKeys } from '../models/shared.models';
import { InvoiceView } from '../../invoice/models/invoice.model';
import { DeliveryColumnTypes } from '../../delivery-data/delivery-data.model';
import { DateService } from '../services/date.service';
import { selectCustomerBrsNumbers } from '../../store/selectors';
import { FormatBrsNumberPipe } from '../pipes/format-brs-number.pipe';
import { FormatLocationPipe } from '@next/core-lib/pipes';

@Component({
  selector: 'app-page-table-filter',
  templateUrl: './page-table-filter.component.html',
  providers: [FormatBrsNumberPipe, FormatLocationPipe],
})
export class PageTableFilterComponent implements OnInit, OnDestroy {
  @Input() pageTitle: string;
  @Input() searchFieldPlaceholder: string;
  @Input() emptyTitle: string;
  @Input() emptyDescription: string;
  @Input() emptyImage: string;
  @Input() emptyActionIcon = 'history';
  @Input() emptyActionText = this.localeService.translate('page-table-filter.reset-filters');
  @Input() emptyActionColor: ThemePalette;
  @Input() filterOnBrs: boolean;
  @Input() showEmptyAction = true;
  @Input() isEmpty = false;

  @Output() selectionChange = new EventEmitter();
  @Output() emptyAction = new EventEmitter();
  @Output() loadDataCallback = new EventEmitter();

  @Input() showSearch = true;
  @Input() showTotals = true;
  @Input() showLocations = true;
  @Input() showInvoiceView = false;

  @Input() data$: Observable<unknown[]>;
  @Input() settingsActions: FilterAction[];
  @Input() exportFilename: string;
  @Input() exportPdfCallback: () => void;
  @Input() filterAndSortFunction: (
    data: unknown[],
    locationIds: number[],
    brsNumber: string,
  ) => unknown[];

  filterConfig: FilterConfig<FiltersConfig>;
  settingsConfig: FilterConfig<FiltersConfig>;

  _tableConfig: TableConfig;
  filterControl: FormControl = new FormControl();
  selectionText = '';
  exportColumns: ExportColumn[];

  readonly destroy$ = new Subject<void>();
  brsNumbers$ = this.store.select(selectCustomerBrsNumbers).pipe(
    skipWhile((data) => !data?.length),
    distinctUntilChanged(
      (a, b) => a.length === b.length && a.every((p, index) => p.brsNumber === b[index].brsNumber),
    ),
    map((customerBrsNumbers) =>
      customerBrsNumbers?.map(
        (customerBrsNumber) =>
          ({
            value: customerBrsNumber.brsNumber,
            viewValue: this.formatBrsNumberPipe.transform(customerBrsNumber),
          } as FilterOption<unknown>),
      ),
    ),
    takeUntil(this.destroy$),
  );
  defaultLocations$ = this.store.select(UserSelectors.selectLocations).pipe(
    skipWhile((value) => !value?.length),
    map((locations) => locations?.map((location) => location.id)),
    distinctUntilChanged((a, b) => a.length === b.length && a.every((p) => a[p] === b[p])),
    takeUntil(this.destroy$),
  );
  invoiceViewOptions$: Observable<FilterOption<InvoiceView>[]> = of([
    {
      value: InvoiceView.overview,
      viewValue: this.localeService.translate('page-table-filter.invoices-view.overview'),
    },
    {
      value: InvoiceView.detailedOverview,
      viewValue: this.localeService.translate('page-table-filter.invoices-view.detailedOverview'),
    },
    {
      value: InvoiceView.totals,
      viewValue: this.localeService.translate('page-table-filter.invoices-view.totals'),
    },
  ]);

  resetFilterAction$ = new Subject<void>();

  selection: SelectionModel<unknown> = new SelectionModel<unknown>(true, [], true);

  constructor(
    private readonly store: Store<RootState>,
    private readonly settingsService: SettingsService,
    private readonly localeService: LocaleService,
    private readonly dateService: DateService,
    private readonly formatBrsNumberPipe: FormatBrsNumberPipe,
  ) {}

  get tableConfig(): TableConfig {
    return this._tableConfig;
  }
  @Input() set tableConfig(value: TableConfig) {
    this._tableConfig = {
      ...value,
      header: true,
      alternateRows: true,
    };

    this.exportColumns = this._tableConfig.columns.map((col) => ({
      id: col.id,
      name: this.localeService.translate(col.label),
    }));
  }

  ngOnInit() {
    combineLatest([this.data$, this.filterControl.valueChanges])
      .pipe(
        tap(
          ([data, filterValues]: [
            unknown[],
            {
              locations: number[];
              search: string;
              range: DateRange;
              totals: boolean;
              brsNumber: string;
            },
          ]) => {
            const dataSource = this.tableConfig.dataSource;
            const locationIds = filterValues?.locations;
            const brsNumber = filterValues?.brsNumber;

            dataSource.data = !!this.filterAndSortFunction
              ? this.filterAndSortFunction(data, locationIds, brsNumber)
              : data;

            if (typeof filterValues?.search === 'string') {
              dataSource.filter = filterValues?.search?.trim()?.toLowerCase()?.replace(',', '.');
            }

            this.tableConfig = {
              ...this.tableConfig,
              dataSource,
            };
          },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.loadDataCallback.emit();
    this.setSelection();

    this.settingsService
      .getSettingsUpdates({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.dateRange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.loadDataCallback.emit();
      });

    this.createFilterConfigs();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resetFilters() {
    this.resetFilterAction$.next();
  }

  public export(format: unknown) {
    combineLatest([
      this.settingsService
        .getSettings({
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: SettingsKeys.dateRange,
        })
        .pipe(startWith({} as Settings[])),
      this.store.select(UserSelectors.selectCustomer),
    ])
      .pipe(take(1))
      .subscribe(([dateRange, customer]) => {
        if (format === 'pdf') {
          this.exportPdfCallback();
        } else {
          const startDate = new Date((dateRange?.[0].data as { startDate: string })?.startDate);
          const endDate = new Date((dateRange?.[0].data as { endDate: string })?.endDate);

          const dateColumns = this.tableConfig.columns
            .filter((col) => col.type === TableColumnTypes.DATE)
            .map((col) => col.id);

          this.store.dispatch(
            ExportActions.exportFile({
              data: this.formatExportData(this.tableConfig.dataSource.filteredData, dateColumns),
              format: format as ExportFormat,
              fileName: `${this.exportFilename}_${customer?.externalId}_${formatDate(
                startDate,
                'shortDate',
                this.localeService.getLocale(),
              ).replace(/\//g, '-')}_${formatDate(
                endDate,
                'shortDate',
                this.localeService.getLocale(),
              ).replace(/\//g, '-')}`,
              columns: this.exportColumns,
            }),
          );
        }
      });
  }

  public formatExportData(data: unknown[], dateColumns: string[]) {
    return data.map((row) =>
      Object.entries(row).reduce((acc: { [key: string]: unknown }, [key, value]) => {
        if (!this.exportColumns || this.exportColumns.find((col: ExportColumn) => col.id === key)) {
          if (key === DeliveryColumnTypes.allShipToIds) {
            value = (value as IdentifierData).identifiers[0].value;
          }

          acc[key] = dateColumns.includes(key)
            ? formatDate(value as Date, 'shortDate', this.localeService.getLocale())
            : value;
        }
        return acc;
      }, {}),
    );
  }

  public setSelection(selection?: SelectionChange<unknown>) {
    if (!!selection) {
      this.selection = selection.source;
      this.selectionChange.emit(selection);
    }

    this.selectionText = `${this.selection?.selected?.length ?? 0} ${this.localeService.translate(
      'page-table-filter.selected',
    )}`;
  }

  private createFilterConfigs(): void {
    this.filterConfig = {
      altButton: true,
      altButtonText: this.localeService.translate('reset'),
      filters: {
        ...(!!this.showSearch
          ? {
              search: {
                label: this.searchFieldPlaceholder,
                type: FilterTypes.Text,
                order: 1,
                defaultValue: of(''),
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.search,
                },
              },
            }
          : {}),
        ...(!!this.showLocations
          ? {
              locations: {
                type: FilterTypes.MultiSelect,
                options: DefaultFilterOptions.Locations,
                value: this.defaultLocations$,
                defaultValue: this.defaultLocations$,
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.locations,
                },
                order: 2,
              },
            }
          : {}),
        dateRange: {
          type: FilterTypes.Daterange,
          defaultValue: this.dateService.defaultDateRange(),
          daterangeConfig: this.dateService.createDateConfigWithCalendarYearOptions(),
          store: {
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.dateRange,
          },
          order: 3,
        },
        ...(this.filterOnBrs
          ? {
              brsNumber: {
                label: this.localeService.translate('page-table-filter.brs-numbers'),
                type: FilterTypes.Select,
                options: this.brsNumbers$,
                defaultValue: of(''),
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.brsNumber,
                },
                order: 4,
              },
            }
          : {}),
      },
    };
    this.settingsConfig = {
      store: {
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
      },
      settings: true,
      filters: {
        ...(!!this.showInvoiceView
          ? {
              invoiceView: {
                label: this.localeService.translate('page-table-filter.invoices-view.placeholder'),
                type: FilterTypes.Radio,
                options: this.invoiceViewOptions$,
                defaultValue: of(InvoiceView.overview),
                priority: true,
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.invoiceView,
                },
              },
            }
          : {}),
        ...(!!this.showTotals
          ? {
              totals: {
                label: this.localeService.translate('page-table-filter.show-totals'),
                type: FilterTypes.Toggle,
                defaultValue: of(false),
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.totals,
                },
              },
            }
          : {}),
      },
    };
  }
}
