import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationWithBRS } from '../models/mineral.model';
import { UserSelectors, SelectedLocation } from '@next/core-lib/user';
import { MineralModuleState } from '../models/mineral.model';

export const selectLocationsWithBRS = createSelector(
  UserSelectors.selectLocations,
  (locations: SelectedLocation[]): LocationWithBRS[] =>
    locations?.map((loc) => ({
      ...loc,
      brs: loc.identifications
        .filter((i) => i.name === 'ZBRS01')
        .sort((a, b) => new Date(b.validTo).getTime() - new Date(a.validTo).getTime()),
    })),
);

export const getMineralModuleState = createFeatureSelector<MineralModuleState>('mineralModule');

export const selectMineralLegalNoticeVisibility = createSelector(
  getMineralModuleState,
  (state) => state.mineralLegalNoticeVisible,
);
