import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { PreferenceGroup, PreferencesState } from '../models/preferences.model';
import { createReducer, on } from '@ngrx/store';
import * as PreferencesActions from './preferences.actions';

export const preferenceGroupAdapter: EntityAdapter<PreferenceGroup> =
  createEntityAdapter<PreferenceGroup>({
    selectId: (preferenceGroup) => preferenceGroup.groupKey,
  });

export const initialState: PreferencesState = {
  preferenceGroups: preferenceGroupAdapter.getInitialState([]),
};

export const preferencesReducer = createReducer<PreferencesState>(
  initialState,
  on(PreferencesActions.loadPreferenceGroupsSuccess, (state, { preferenceGroups }) => ({
    ...state,
    preferenceGroups: preferenceGroupAdapter.setAll(preferenceGroups, state.preferenceGroups),
  })),
  on(PreferencesActions.loadPreferenceGroupsFailed, (state) => ({
    ...state,
    preferenceGroups: preferenceGroupAdapter.removeAll(state.preferenceGroups),
  })),
  on(PreferencesActions.clearPreferenceGroupsRequest, (state) => ({
    ...state,
    preferenceGroups: preferenceGroupAdapter.removeAll(state.preferenceGroups),
  })),
  on(PreferencesActions.patchPreferenceSuccess, (state, { preferenceGroups }) => ({
    ...state,
    preferenceGroups: preferenceGroupAdapter.setAll(preferenceGroups, state.preferenceGroups),
  })),
);
