import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import { Invoice, InvoiceColumnTypes } from 'src/app/invoice-data/invoice-data.model';

export enum InvoiceView {
  overview = 'overview',
  detailedOverview = 'detailedOverview',
  totals = 'totals',
}

export const invoiceColumns = [
  InvoiceColumnTypes.invoiceDate,
  InvoiceColumnTypes.externalInvoiceId,
  InvoiceColumnTypes.allShipToIds,
  InvoiceColumnTypes.invoiceDescription,
  InvoiceColumnTypes.brutoAmount,
] as const;

export type InvoiceOverviewViewModel = Pick<
  Invoice,
  (typeof invoiceColumns)[number] | 'id' | 'shipToId'
>;

export interface InvoiceOverviewDataFilters {
  locations: number[];
  search: string;
  dateRange: DateRange;
}
