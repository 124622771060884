<div class="page-table-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ 'app.greenhouse' | translate }}</h1>

  <app-greenhouse-notice></app-greenhouse-notice>

  <agr-table-v2
    matSort
    [dataSource]="dataSource"
    [isEqual]="isEqual"
    [withSelection]="true"
    (filterClearClick)="resetFilters()"
    class="greenhouse-overview__table-spaced-cols"
  >
    <ng-container *agrTableSearch [formGroup]="searchAndFilters">
      <agr-table-search
        formControlName="search"
        [label]="'search' | translate"
        [placeholder]="'bex.search-placeholder' | translate"
      >
      </agr-table-search>
    </ng-container>

    <ng-container *agrTableFilters [formGroup]="searchAndFilters">
      <agr-table-date-range-filter
        [label]="'daterange-picker.period' | translate"
        [config]="dateRangeConfig"
        formControlName="dateRange"
      >
      </agr-table-date-range-filter>

      <agr-table-multi-select-filter
        agrTableFilterLocations
        [label]="'delivery.locations-filter' | translate"
        formControlName="locations"
      >
      </agr-table-multi-select-filter>
    </ng-container>

    <agr-table-top-bar *agrTableTopBar class="greenhouse-overview__top-bar">
      <agr-table-selected-items></agr-table-selected-items>
      <div
        class="flex--row flex--gutter flex--nowrap flex--align-center flex--justify-space-between"
      >
        <agr-export agrTableExport [options]="exportOptions" [dataSource]="dataSource">
        </agr-export>
      </div>
    </agr-table-top-bar>

    <ng-container matColumnDef="deliveryDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.deliveryDate' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.deliveryDate' | translate"
        *matCellDef="let row"
      >
        {{ row.deliveryDate | agrDate : 'shortDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="allShipToIds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.allShipToIds' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.allShipToIds' | translate"
        *matCellDef="let row"
      >
        <agr-identifier-trigger
          [data]="row.allShipToIds"
          [hideDescription]="true"
          [onlyUnique]="true"
          (click)="$event.stopPropagation()"
        ></agr-identifier-trigger>
      </td>
    </ng-container>

    <ng-container matColumnDef="externalProductId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.externalProductId' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.externalProductId' | translate"
        *matCellDef="let row"
      >
        {{ row.externalProductId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'bex.bex-overview.productTitle' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'bex.bex-overview.productTitle' | translate"
        *matCellDef="let row"
      >
        {{ row.productTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="klwArticleGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.klwArticleGroupName' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.klwArticleGroupName' | translate"
        *matCellDef="let row"
      >
        {{ row.klwArticleGroupName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unitQuantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.unitQuantity' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.unitQuantity' | translate"
        *matCellDef="let row"
      >
        {{ row.unitQuantity | agrNumber }}
      </td>
    </ng-container>

    <!-- Emission factors specific data -->
    <ng-container matColumnDef="emissionFactorPercent00">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.emissionFactorPercent00' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.emissionFactorPercent00' | translate"
        *matCellDef="let row"
      >
        {{ row.emissionFactorPercent00 | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="emissionFactorPercent40">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.emissionFactorPercent40' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.emissionFactorPercent40' | translate"
        *matCellDef="let row"
      >
        {{ row.emissionFactorPercent40 | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="emissionFactorPercent80">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.emissionFactorPercent80' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.emissionFactorPercent80' | translate"
        *matCellDef="let row"
      >
        {{ row.emissionFactorPercent80 | agrNumber : '1.0-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="co2Total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.co2Total' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.co2Total' | translate"
        *matCellDef="let row"
      >
        {{ row.co2Total | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fossilExcludingPeatEmissions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.fossilExcludingPeatEmissions' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="
          'greenhouse.greenhouse-overview.fossilExcludingPeatEmissions' | translate
        "
        *matCellDef="let row"
      >
        {{ row.fossilExcludingPeatEmissions | agrNumber : '1.0-0'  }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fossilPeatEmissions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.fossilPeatEmissions' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.fossilPeatEmissions' | translate"
        *matCellDef="let row"
      >
        {{ row.fossilPeatEmissions | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="biogenic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.biogenic' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.biogenic' | translate"
        *matCellDef="let row"
      >
        {{ row.biogenic | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="landUseChange">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'greenhouse.greenhouse-overview.landUseChange' | translate }}
      </th>
      <td
        mat-cell
        [attr.aria-label]="'greenhouse.greenhouse-overview.landUseChange' | translate"
        *matCellDef="let row"
      >
        {{ row.landUseChange | agrNumber : '1.0-0' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: displayColumns"></tr>
    <tr *matNoDataRow>
      <td class="text--center" [attr.colspan]="displayColumns.length">
        <agr-notification-empty
          data-test="hierarchical-data__empty"
          [isEmpty]="true"
          [emptyTitle]="'greenhouse.empty-title' | translate"
          [emptyDescription]="'greenhouse.empty-description' | translate"
          [emptyImage]="'/assets/images/no-items-3.png'"
          [emptyActionIcon]="'history'"
          [emptyActionText]="'page-table-filter.reset-filters' | translate"
          [showEmptyAction]="true"
          (emptyAction)="resetFilters()"
          class="spacing--margin-top"
        ></agr-notification-empty>
      </td>
    </tr>
  </agr-table-v2>
</div>
