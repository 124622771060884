<ng-container *ngIf="data$ | async as theData">
  <agr-dialog
    class="identification-add"
    [data]="theData"
    (cancel)="cancel()"
    (confirm)="saveIdentification()"
  >
    <form [formGroup]="form" class="spacing--margin-top">
      <mat-card appearance="outlined" class="mat-elevation-z1 spacing--margin-vertical">
        <mat-card-header>
          <mat-label>
            {{ 'identification-add.image-field-title' | translate }}
          </mat-label>
        </mat-card-header>
        <mat-card-content>
          <agr-file-upload
            formControlName="image"
            [maxFileSize]="twoMegaBytes"
            [transformer]="transformer"
          ></agr-file-upload>
        </mat-card-content>
      </mat-card>
    </form>
  </agr-dialog>
</ng-container>
