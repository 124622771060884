<div class="page-hierarchical-data-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ pageTitle }}</h1>

  <ng-content select="[tableIntro]"></ng-content>

  <agr-filter
    *ngIf="filterConfig"
    [config]="filterConfig"
    [formControl]="filterControl"
  ></agr-filter>

  <div class="spacing--margin-bottom" *ngIf="settingsConfig">
    <agr-filter
      [config]="settingsConfig"
      [actions]="settingsActions"
      [selectionTitle]="selectionText"
      [actionsTitle]="'export.export_as' | translate"
      (action)="export($event)"
      [settingsButtonIcon]="'visibility'"
      [settingsButtonLabel]="'page-hierarchical-data-filter.settings' | translate"
    ></agr-filter>
  </div>

  <app-hierarchical-data
    [data]="data$ | async"
    [headings]="headings"
    [emptyTitle]="emptyTitle"
    [emptyDescription]="emptyDescription"
    [emptyImage]="emptyImage"
    [emptyActionIcon]="emptyActionIcon"
    [emptyActionText]="emptyActionText"
    [emptyActionColor]="emptyActionColor"
    [showEmptyAction]="showEmptyAction"
    [isEmpty]="isEmpty"
  ></app-hierarchical-data>
</div>
