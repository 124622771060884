import { SelectionModel } from '@angular/cdk/collections';
import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { RootState } from '@next/core-lib/app';
import {
  FilterAction,
  FilterConfig,
  FilterOption,
  FilterTypes,
  FiltersConfig,
} from '@next/core-lib/components';
import { ExportActions, ExportColumn, ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { FormatLocationPipe } from '@next/core-lib/pipes';
import { Settings, SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, startWith, take, takeUntil } from 'rxjs/operators';
import { selectCustomerBrsNumbers } from '../../store/selectors';
import {
  ColTypes,
  HierarchicalData,
  HierarchicalDataColumn,
  RowTypes,
} from '../hierarchical-data/hierarchical-data.model';
import { SettingsKeys, settingsContextName } from '../models/shared.models';
import { FormatBrsNumberPipe } from '../pipes/format-brs-number.pipe';
import { DateService } from '../services/date.service';

@Component({
  selector: 'app-page-hierarchical-data-filter',
  templateUrl: './page-hierarchical-data-filter.component.html',
  providers: [FormatBrsNumberPipe, FormatLocationPipe],
})
export class PageHierarchicalDataFilterComponent implements OnInit, OnDestroy {
  @Input() pageTitle: string;
  @Input() searchFieldPlaceholder: string;
  @Input() emptyTitle: string;
  @Input() emptyDescription: string;
  @Input() emptyImage: string;
  @Input() emptyActionIcon: string;
  @Input() emptyActionText: string;
  @Input() emptyActionColor: ThemePalette;
  @Input() filterOnBrs: boolean;
  @Input() showEmptyAction = false;
  @Input() isEmpty = false;
  @Output() emptyAction = new EventEmitter();
  @Output() loadDataCallback = new EventEmitter();
  @Input() settingsActions: FilterAction[];
  @Input() exportFilename: string;
  @Input() exportPdfCallback: () => void;
  @Input() showTotals = true;
  @Input() showLegacyTotalWarning = false;

  @Input() data$: Observable<HierarchicalData[]>;
  @Input() headings: HierarchicalDataColumn[];

  filterConfig: FilterConfig<FiltersConfig>;
  settingsConfig: FilterConfig<FiltersConfig>;

  filterControl: FormControl = new FormControl();
  selectionText = '';

  public selection: SelectionModel<unknown> = new SelectionModel<unknown>(true, [], true);
  readonly destroy$ = new Subject<void>();
  brsNumbers$ = this.store.select(selectCustomerBrsNumbers).pipe(
    skipWhile((data) => !data?.length),
    distinctUntilChanged(
      (a, b) => a.length === b.length && a.every((p, index) => p.brsNumber === b[index].brsNumber),
    ),
    map((customerBrsNumbers) =>
      customerBrsNumbers?.map(
        (customerBrsNumber) =>
          ({
            value: customerBrsNumber.brsNumber,
            viewValue: this.formatBrsNumberPipe.transform(customerBrsNumber),
          } as FilterOption<unknown>),
      ),
    ),
    takeUntil(this.destroy$),
  );

  defaultBrsNumber$: Observable<string> = this.brsNumbers$.pipe(
    map((brsNumbers) => brsNumbers?.[0]?.value + ''),
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly dateService: DateService,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
    private readonly formatBrsNumberPipe: FormatBrsNumberPipe,
  ) {}

  ngOnInit() {
    this.loadDataCallback.emit();

    this.settingsService
      .getSettingsUpdates({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.dateRange,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.loadDataCallback.emit();
      });

    this.settingsService
      .getSettingsUpdates({
        contextName: settingsContextName,
        storage: SettingsStorage.PlatformSession,
        key: SettingsKeys.brsNumber,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.loadDataCallback.emit();
      });

    this.createFilterConfigs();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public export(format: unknown) {
    combineLatest([
      this.settingsService
        .getSettings({
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: SettingsKeys.dateRange,
        })
        .pipe(startWith({} as Settings[])),
      this.store.select(UserSelectors.selectCustomer),
      this.data$,
    ])
      .pipe(take(1))
      .subscribe(([dateRange, customer, data]) => {
        if (format === 'pdf') {
          this.exportPdfCallback();
        } else {
          const startDate = new Date((dateRange?.[0].data as { startDate: string })?.startDate);
          const endDate = new Date((dateRange?.[0].data as { endDate: string })?.endDate);

          this.store.dispatch(
            ExportActions.exportFile({
              data: this.formatExportData(data),
              format: format as ExportFormat,
              fileName: `${this.exportFilename}_${customer?.externalId}_${formatDate(
                startDate,
                'shortDate',
                this.localeService.getLocale(),
              ).replace(/\//g, '-')}_${formatDate(
                endDate,
                'shortDate',
                this.localeService.getLocale(),
              ).replace(/\//g, '-')}`,
              columns: this.headings.map(
                (heading) =>
                  ({
                    id: this.localeService.translate(heading.id),
                    name: heading.value,
                  } as ExportColumn),
              ),
            }),
          );
        }
      });
  }

  private formatExportData(data: HierarchicalData[]) {
    return data
      ?.filter((row) => row.type !== RowTypes.title)
      ?.map((row) =>
        row?.columns
          ?.filter(({ id }) => this?.headings?.find((heading) => heading.id === id))
          ?.reduce((acc, column) => {
            let columnValue = column.value;
            if (column.type === ColTypes.date) {
              columnValue = formatDate(
                column.value as Date,
                'shortDate',
                this.localeService.getLocale(),
              );
            } else if (column.type === ColTypes.number) {
              columnValue = formatNumber(
                Number(columnValue),
                this.localeService.getLocale(),
                column.digitsInfo,
              );
            } else if (column.type === ColTypes.currency) {
              columnValue = formatCurrency(
                Number(column),
                this.localeService.getLocale(),
                this.localeService.getCurrency(),
              );
            }

            if (column.unit) {
              columnValue += ' ' + column.unit;
            }

            return { ...acc, [column.id]: columnValue };
          }, {}),
      );
  }

  private createFilterConfigs(): void {
    this.filterConfig = {
      filters: {
        dateRange: {
          type: FilterTypes.Daterange,
          defaultValue: this.dateService.defaultDateRange(),
          daterangeConfig: this.dateService.createDateConfigWithCalendarYearOptions(),
          store: {
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.dateRange,
          },
          order: 2,
        },
        ...(this.filterOnBrs
          ? {
              brsNumber: {
                label: this.localeService.translate('page-hierarchical-data-filter.brs-numbers'),
                type: FilterTypes.Select,
                options: this.brsNumbers$,
                defaultValue: this.defaultBrsNumber$,
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.brsNumber,
                },
                order: 3,
              },
            }
          : {}),
      },
    };
    this.settingsConfig = {
      settings: true,
      filters: {
        ...(!!this.showTotals
          ? {
              totals: {
                label: this.localeService.translate('page-hierarchical-data-filter.show-totals'),
                type: FilterTypes.Toggle,
                defaultValue: of(true),
                priority: true,
                store: {
                  contextName: settingsContextName,
                  storage: SettingsStorage.PlatformSession,
                  key: SettingsKeys.totals,
                },
              },
            }
          : {}),
      },
    };
  }
}
