export enum ContractColumns {
  externalId = 'externalId',
  validFrom = 'validFrom',
  validTo = 'validTo',
  status = 'status',
  contractPosition = 'contractPosition',
  materialDescription = 'materialDescription',
  businessPartnerExternalId = 'businessPartner.externalId',
  contractQuantity = 'contractQuantity',
  consumedQuantity = 'consumedQuantity',
  openQuantity = 'openQuantity',
  unit = 'unit',
}
