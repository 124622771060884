import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import {
  Delivery,
  DeliveryCategories,
  DeliveryDataModuleState,
  DeliveryTotals,
} from '../delivery-data.model';
import * as DeliveryDataActions from './delivery-data.actions';

const emptyDeliveryTotals: DeliveryTotals = {
  groupedProductCategories: [],
  shipToTotals: [],
};

export const adapter: EntityAdapter<Delivery> = createEntityAdapter<Delivery>();

export const initialState: DeliveryDataModuleState = {
  recentDeliveries: adapter.getInitialState([]),
  deliveries: adapter.getInitialState([]),
  bex: adapter.getInitialState([]),
  minerals: adapter.getInitialState([]),
  inboundDeliveries: adapter.getInitialState([]),
  gbm: adapter.getInitialState([]),
  deliveryTotals: emptyDeliveryTotals,
} as DeliveryDataModuleState;

const reducer = createReducer(
  initialState,
  on(DeliveryDataActions.loadRecentDeliveriesSuccess, (state, { deliveries }) => ({
    ...state,
    recentDeliveries: adapter.setAll(deliveries, state.recentDeliveries),
  })),
  on(
    DeliveryDataActions.loadDeliveriesSuccess,
    (
      state,
      { deliveries, category }: { deliveries: Delivery[]; category?: DeliveryCategories },
    ) => {
      switch (category) {
        case DeliveryCategories.bex:
          return {
            ...state,
            bex: adapter.setAll(deliveries, state.bex),
          };
        case DeliveryCategories.gbm:
          return {
            ...state,
            gbm: adapter.setAll(deliveries, state.gbm),
          };
        case DeliveryCategories.grain:
          return {
            ...state,
            inboundDeliveries: adapter.setAll(deliveries, state.inboundDeliveries),
          };
        case DeliveryCategories.mineral:
          return {
            ...state,
            minerals: adapter.setAll(deliveries, state.minerals),
          };
        default:
          return {
            ...state,
            deliveries: adapter.setAll(deliveries, state.deliveries),
          };
      }
    },
  ),
  on(DeliveryDataActions.loadDeliveryTotalsSuccess, (state, { deliveryTotals }) => ({
    ...state,
    deliveryTotals,
  })),
  on(DeliveryDataActions.clearRecentDeliveriesRequest, (state) => ({
    ...state,
    recentDeliveries: adapter.removeAll(state.recentDeliveries),
  })),
  on(
    DeliveryDataActions.clearDeliveriesRequest,
    (state, { category }: { category?: DeliveryCategories }) => {
      switch (category) {
        case DeliveryCategories.bex:
          return {
            ...state,
            bex: adapter.removeAll(state.bex),
          };
        case DeliveryCategories.gbm:
          return {
            ...state,
            gbm: adapter.removeAll(state.gbm),
          };
        case DeliveryCategories.grain:
          return {
            ...state,
            inboundDeliveries: adapter.removeAll(state.inboundDeliveries),
          };
        case DeliveryCategories.mineral:
          return {
            ...state,
            minerals: adapter.removeAll(state.minerals),
          };
        default:
          return {
            ...state,
            deliveries: adapter.removeAll(state.deliveries),
          };
      }
    },
  ),
  on(DeliveryDataActions.clearDeliveryTotalsRequest, (state) => ({
    ...state,
    deliveryTotals: emptyDeliveryTotals,
  })),
);

export const deliveryDataReducer = (state: DeliveryDataModuleState | undefined, action: Action) =>
  reducer(state, action);
