import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AgrPageModule, AgrPrimaryActionModule } from '@next/core-lib/components';
import { AgrDialogModule } from '@next/core-lib/dialog';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { AgrCartModule } from '@next/core-lib/cart';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { RecentDeliveriesComponent } from './components/recent-deliveries/recent-deliveries.component';
import { TodayComponent } from './components/today/today.component';
import { SharedModule } from '../shared/shared.module';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { InvoiceDataModule } from '../invoice-data/invoice-data.module';
import { RecentInvoicesComponent } from './components/recent-invoices/recent-invoices.component';
import { AgrTableModule } from '@next/core-lib/table';
import { DeliveryDataService } from '../delivery-data/services/delivery-data.service';

@NgModule({
  declarations: [
    DashboardComponent,
    RecentDeliveriesComponent,
    RecentInvoicesComponent,
    TodayComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    DashboardRoutingModule,
    InvoiceDataModule,
    DeliveryDataModule,
    MatButtonModule,
    MatIconModule,
    AgrPageModule,
    AgrDialogModule,
    MatListModule,
    MatCardModule,
    AgrPrimaryActionModule,
    RouterModule,
    AgrTableModule,
    AgrCartModule,
  ],
  providers: [DeliveryDataService],
})
export class DashboardModule {}
