import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RootState } from '@next/core-lib/app';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { FormatLocationPipe } from '@next/core-lib/pipes';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { TableColumnTypes } from '@next/core-lib/table';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';
import { DeliveryDataService } from 'src/app/delivery-data/services/delivery-data.service';
import {
  getDeliveryReportRequest,
  getMSDSRequest,
} from 'src/app/delivery-data/store/delivery-data.actions';
import { settingsContextName, SettingsKeys } from 'src/app/shared/models/shared.models';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import { GbmOverviewDataSource } from '../../datasource/gbm-overview.datasource';
import { GbmOverviewDataFilters, GbmOverviewViewModel, gbmColumns } from '../models/overview.model';
import { DateService } from 'src/app/shared/services/date.service';
import { DaterangeConfig } from '@next/core-lib/components';

@Component({
  selector: 'app-gbm-overview',
  templateUrl: './gbm-overview.component.html',
  styleUrls: ['./gbm-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FormatLocationPipe],
})
export class GbmOverviewComponent implements OnInit, OnDestroy {
  readonly dataSource = new GbmOverviewDataSource(this._deliveryDataService);
  // Need to add a new display column ('action') in order to display a new table column (matColumnDef)
  readonly displayColumns = [...gbmColumns, 'action'];
  readonly subscriptions: Subscription[] = [];

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(),
    locations: new FormControl(),
    dateRange: new FormControl(),
  });

  readonly showTotalsToggle = new FormControl();

  readonly exportOptions: ExportOptionsV2;

  readonly dateRangeConfig = this.dateService.createDateConfigWithCalendarYearOptions();

  constructor(
    private readonly _deliveryDataService: DeliveryDataService,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
    private readonly store: Store<RootState>,
    private readonly dateService: DateService,
  ) {
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
        {
          name: 'PDF',
          export: (data: GbmOverviewViewModel[]) => this.downloadPdf(data),
        },
      ],
      fileName: this.localeService.translate('gbm.gbm-overview.fileName'),
      columns: gbmColumns.map((col) => ({
        id: col,
        name: this.localeService.translate(`gbm.gbm-overview.${col}`),
      })),
    };
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchAndFilters.valueChanges.subscribe(() =>
        this.dataSource.applyFilters(this.searchAndFilters.value as GbmOverviewDataFilters),
      ),
    );

    this.subscriptions.push(
      this.showTotalsToggle.valueChanges.subscribe((showTotals) =>
        this.settingsService
          .setSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.totals,
            data: showTotals,
          })
          .pipe(take(1))
          .subscribe((_) => {}),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public openMSDS(model: GbmOverviewViewModel): void {
    return this.store.dispatch(
      getMSDSRequest({ shipToId: model.shipToId, externalProductId: model.externalProductId }),
    );
  }

  isEqual(a: GbmOverviewViewModel, b: GbmOverviewViewModel) {
    return a.id === b.id && a.deliveryId === b.deliveryId;
  }

  resetFilters() {
    this.searchAndFilters.reset({
      search: null,
      locations: null,
      dateRange: new DaterangeConfig().config.initial,
    });
  }

  getTableColumnType(dCol: DeliveryColumnTypes) {
    return dCol === 'deliveryDate'
      ? TableColumnTypes.DATE
      : ['unitQuantity', 'productQuantity'].includes(dCol)
      ? TableColumnTypes.NUMBER
      : TableColumnTypes.STRING;
  }

  private downloadPdf = (data: GbmOverviewViewModel[]) =>
    combineLatest([
      of(this.searchAndFilters.value as GbmOverviewDataFilters),
      this.store.select(UserSelectors.selectCustomer),
      this.store.select(UserSelectors.selectLocations),
    ])
      .pipe(take(1))
      .subscribe(([filters, customer, locations]) => {
        const startDate = filters.dateRange.startDate;
        const endDate = filters.dateRange.endDate;

        this.store.dispatch(
          getDeliveryReportRequest({
            report: {
              title: this.localeService.translate('gbm.title-details'),
              subTitle: `${this.localeService.translate(
                'gbm.report.sub-title',
              )} ${this._deliveryDataService.formatDateTime(new Date())}`,
              fileName: this._deliveryDataService.formatExportFileName(
                this.localeService.translate('gbm.gbm-overview.fileName'),
                customer.externalId,
                startDate,
                endDate,
              ),
              legend: {
                [this.localeService.translate(
                  'gbm.report.legend.customer',
                )]: `${customer.name} (${customer.externalId})`,
                [this.localeService.translate('gbm.report.legend.locations')]:
                  this._deliveryDataService.formatLocations(
                    filters.locations
                      ? locations.filter((s) => filters?.locations.includes(s.id))
                      : locations,
                  ),
                [this.localeService.translate('gbm.report.legend.start-date')]:
                  this._deliveryDataService.formatDateTime(startDate),
                [this.localeService.translate('gbm.report.legend.end-date')]:
                  this._deliveryDataService.formatDateTime(endDate),
              },
              tableHeadings: this.exportOptions.columns.map((col) => col.name) ?? [],
              tableFooter: [
                this.localeService.translate('gbm.report.disclaimer1'),
                this.localeService.translate('gbm.report.disclaimer2'),
              ],
              firstPageRowsPerPage: 32,
              rowsPerPage: 47,
              contactInformation: {
                email: this.localeService.translate('gbm.report.contact.email'),
                phoneNumber: this.localeService.translate('gbm.report.contact.phone-number'),
              },
              rows: this._deliveryDataService.mapToPdfRows(
                gbmColumns.map((dCol) => ({
                  id: dCol,
                  type: this.getTableColumnType(dCol),
                  digitsInfo: '1.0-2',
                })),
                data as unknown as DeliveryLineWithDelivery[],
              ),
            },
          }),
        );
      });
}
