import { Component, OnDestroy, OnInit } from '@angular/core';
import { RootState } from '@next/core-lib/app';
import { LocaleService } from '@next/core-lib/i18n';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setGreenhouseNoticeVisibility } from '../../store/greenhouse.actions';
import { selectGreenhouseNoticeVisibility } from '../../store/greenhouse.selectors';

@Component({
  selector: 'app-greenhouse-notice',
  templateUrl: './greenhouse-notice.component.html',
  styleUrls: ['./greenhouse-notice.component.scss'],
})
export class GreenhouseNoticeComponent implements OnInit, OnDestroy {
  public showNotification = true;
  private subscription: Subscription;

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.store
      .select(selectGreenhouseNoticeVisibility)
      .subscribe((visible) => (this.showNotification = visible));
  }

  public getActionText() {
    return this.showNotification
      ? this.localeService.translate('mineral.mineral-legal-notice.action-text-close')
      : this.localeService.translate('mineral.mineral-legal-notice.action-text-open');
  }

  public toggleNotification() {
    this.store.dispatch(setGreenhouseNoticeVisibility({ visible: !this.showNotification }));
  }
}
