import { Action, createReducer, on } from '@ngrx/store';
import { GreenhouseModuleState } from '../models/greenhouse.model';
import * as GreenhouseAction from './greenhouse.actions';

export const initialState: GreenhouseModuleState = {
  greenhouseNoticeVisible: true,
} as GreenhouseModuleState;

const reducer = createReducer(
  initialState,
  on(GreenhouseAction.setGreenhouseNoticeVisibility, (state, { visible }) => ({
    ...state,
    greenhouseNoticeVisible: visible,
  })),
);

export const greenhouseReducer = (state: GreenhouseModuleState | undefined, action: Action) =>
  reducer(state, action);
