import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from '../../../environment';
import { LicenseRequestModel } from '../models/license.request.model';

@Injectable()
export class IdentificationService {
  private baseUrl = `${this.env.API_BASE_URL}/service/business-partner`;

  constructor(
    private readonly http: HttpClient,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  insert(request: LicenseRequestModel): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/secure/license`, request);
  }
}
