import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateFeature, FeatureFlags } from '@next/core-lib/featureflag';
import { ContractComponent } from './contract.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'contract',
        component: ContractComponent,
        canActivate: [canActivateFeature],
        data: { featureFlags: FeatureFlags.showContracts },
      },
    ]),
  ],
})
export class ContractRoutingModule {}
