import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter } from './invoice-data.reducer';
import { InvoiceDataModuleState, Invoice, DetailedInvoice } from '../invoice-data.model';
import { UserSelectors } from '@next/core-lib/user';

const { selectAll } = adapter.getSelectors();

const selectInvoiceModuleState = createFeatureSelector<InvoiceDataModuleState>('invoiceDataModule');
const selectInvoicesState = createSelector(selectInvoiceModuleState, (state) => state.invoices);

export const selectPlainInvoices = createSelector(selectInvoicesState, selectAll);
export const selectOrderedInvoices = createSelector(selectPlainInvoices, (invoices: Invoice[]) =>
  invoices.sort((invoice1, invoice2) => (invoice1.invoiceDate < invoice2.invoiceDate ? 1 : -1)),
);

export const selectInvoices = createSelector(selectOrderedInvoices, (invoices: Invoice[]) =>
  invoices.map((invoice: Invoice) => ({
    ...invoice,
    allShipToIds: {
      identifiers: [
        {
          description: '',
          value: invoice.shipTo.externalShipToId,
        },
        ...invoice.shipTo?.shipToLegacies.map((legacyItem) => ({
          description: legacyItem.legacyType,
          value: legacyItem.id,
        })),
      ],
    },
  })),
);

export const selectDetailedInvoices = createSelector(
  UserSelectors.selectLocations,
  selectInvoices,
  (locations, invoices) => {
    let detailedInvoices: DetailedInvoice[] = [];
    invoices.forEach((invoice) => {
      detailedInvoices = [
        ...detailedInvoices,
        ...invoice.invoiceLines.map((invoiceLine) => {
          const location = locations.find((loc) => loc.id === invoice.shipTo.id);
          return {
            ...invoiceLine,
            externalCustomerId: invoice.customer.externalCustomerId,
            locationAddress: `${location.street}`,
            allShipToIds: invoice.allShipToIds,
            invoiceDate: invoice.invoiceDate,
            invoiceDescription: invoice.invoiceDescription,
            externalInvoiceId: invoice.externalInvoiceId,
            vendor: invoice.vendor.name,
            currencyKey: invoice.currencyKey,
            shipTo: invoice.shipTo,
          };
        }),
      ];
    });

    return detailedInvoices;
  },
);

export const selectPlainInvoiceTotals = createSelector(
  selectInvoiceModuleState,
  (state) => state.invoiceTotals,
);

// Recent invoices
const selectRecentInvoicesState = createSelector(
  selectInvoiceModuleState,
  (state) => state.recentInvoices,
);
export const selectPlainRecentInvoices = createSelector(selectRecentInvoicesState, selectAll);

export const selectRecentInvoices = createSelector(
  selectPlainRecentInvoices,
  (invoices: Invoice[]) =>
    invoices.map((invoice: Invoice) => ({
      ...invoice,
      allShipToIds: {
        identifiers: [
          {
            description: '',
            value: invoice.shipTo.externalShipToId,
          },
          ...invoice.shipTo?.shipToLegacies.map((legacyItem) => ({
            description: legacyItem.legacyType,
            value: legacyItem.id,
          })),
        ],
      },
    })),
);

export const selectRecentInvoicesByDateDesc = createSelector(selectRecentInvoices, (state) =>
  state.sort((invoice1, invoice2) => (invoice1.invoiceDate < invoice2.invoiceDate ? 1 : -1)),
);

export const selectTopRecentInvoices = (amountOfInvoicesToSelect: number) =>
  createSelector(selectRecentInvoicesByDateDesc, (invoices: Invoice[]) =>
    invoices.slice(0, amountOfInvoicesToSelect),
  );
