import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { FileUploadPreviewTemplateDirective } from '../directives/file-upload-preview-template.directive';
import { maxFileSize } from '../validators/max-file-size.validator';
import { FileUploadErrorsTemplateDirective } from '../directives/file-upload-errors-template.directive';
import { FileTransformerInterface } from '../models/file-transformer.interface';

@Component({
  selector: 'agr-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploadComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FileUploadComponent,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() chooseFileText: string | null;
  @Input() chooseOtherFileText: string | null;
  @Input() resetText: string | null;
  @Input() downloadText: string | null;
  @Input() allowedMimeTypes: string[] = ['image/png', 'image/jpeg'];
  @Input() rechooseEnabled = true;
  @Input() resetEnabled = true;
  @Input() downloadEnabled = true;
  @Input() chooseIcon = 'attachment';
  @Input() rechooseIcon = 'link';
  @Input() resetIcon = 'delete_outline';
  @Input() downloadIcon = 'file_download';
  @Input() transformer: FileTransformerInterface | null;

  @ContentChild(FileUploadPreviewTemplateDirective, { read: TemplateRef })
  previewTemplate: TemplateRef<unknown>;
  @ContentChild(FileUploadErrorsTemplateDirective, { read: TemplateRef })
  errorTemplate: TemplateRef<unknown>;

  control = new FormControl();
  _maxFileSize: number | null = null; // In bytes

  private subscriptions: Subscription[] = [];

  @Input()
  set maxFileSize(sizeInBytes: number | null) {
    if (sizeInBytes) {
      this.control.addValidators(maxFileSize(sizeInBytes));
    } else {
      this.control.setValidators([]);
    }
    this._maxFileSize = sizeInBytes;
  }

  onChange = (_: unknown) => {};
  onTouched = () => {};

  ngOnInit(): void {
    this.subscriptions.push(
      this.control.valueChanges.subscribe((it) => this.onChange?.call(this, it)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: unknown): void {
    this.control.setValue(value);
  }

  async onFileUploadChange(input: HTMLInputElement) {
    const file = input.files.item(0);

    if (this.transformer) {
      const transformedFile = await this.transformer.transform(file);
      this.control.setValue(transformedFile);
    } else {
      this.control.setValue(file);
    }
  }

  validate(): ValidationErrors | null {
    return this.control.errors;
  }
}
