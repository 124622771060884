import { NgModule } from '@angular/core';
import { ContractComponent } from './contract.component';
import { ContractOverviewComponent } from './components/contract-overview/contract-overview.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ContractRoutingModule } from './contract-routing.module';
import { ContractEffects } from './store/contract.effects';
import { contractReducer } from './store/contract.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  AgrFilterModule,
  AgrPageDetailModule,
  AgrPageSidesheetModule,
} from '@next/core-lib/components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { AgrTableModule } from '@next/core-lib/table';
import { AgrDialogModule } from '@next/core-lib/dialog';
import { TrimZeroesPipe } from './pipes/trim-zeroes.pipe';

@NgModule({
  declarations: [ContractComponent, ContractOverviewComponent, TrimZeroesPipe],
  imports: [
    CommonModule,
    SharedModule,
    ContractRoutingModule,
    RouterModule,
    AgrPageSidesheetModule,
    AgrPageDetailModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSlideToggleModule,
    StoreModule.forFeature('contractModule', contractReducer),
    EffectsModule.forFeature([ContractEffects]),
    AgrTableModule,
    AgrFilterModule,
    AgrDialogModule,
  ],
})
export class ContractModule {}
