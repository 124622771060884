import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdentificationComponent } from './identification.component';
import { IdentificationOverviewComponent } from './components/identification-overview/identification-overview.component';
import { IdentificationRoutingModule } from './identification-routing.module';
import { AgrTableModule } from '@next/core-lib/table';
import { SharedModule } from '../shared/shared.module';
import { AgrFilterModule } from '@next/core-lib/components';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IdentificationAddComponent } from './components/identification-add/identification-add.component';
import { AgrDialogModule } from '@next/core-lib/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FileUploadModule } from '../shared/file-upload/file-upload.module';
import { MatInputModule } from '@angular/material/input';
import { StoreModule } from '@ngrx/store';
import { identificationReducer } from './store/reducer';
import { IdentificationService } from './services/identification.service';
import { IdentificationEffects } from './store/effects';
import { EffectsModule } from '@ngrx/effects';
import { AgrI18nModule } from '@next/core-lib/i18n';

@NgModule({
  declarations: [
    IdentificationComponent,
    IdentificationAddComponent,
    IdentificationOverviewComponent,
  ],
  imports: [
    AgrDialogModule,
    AgrTableModule,
    AgrFilterModule,
    AgrI18nModule,
    CommonModule,
    FileUploadModule,
    IdentificationRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('identification', identificationReducer),
    EffectsModule.forFeature([IdentificationEffects]),
  ],
  providers: [IdentificationService],
})
export class IdentificationModule {}
