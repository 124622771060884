import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AgrIdentifierModule, AgrNotificationEmptyModule } from '@next/core-lib/components';
import { AgrExportModule } from '@next/core-lib/export';
import { TableV2Module } from '@next/core-lib/table-v2';
import { DeliveryDataModule } from '../delivery-data/delivery-data.module';
import { DeliveryDataService } from '../delivery-data/services/delivery-data.service';
import { PageHierarchicalDataFilterModule } from '../shared/page-hierarchical-data-filter/page-hierarchical-data-filter.module';
import { PageTableFilterModule } from '../shared/page-table-filter/page-table-filter.module';
import { SharedModule } from '../shared/shared.module';
import { GbmOverviewComponent } from './components/gbm-overview/gbm-overview.component';
import { GbmTotalsComponent } from './components/gbm-totals/gbm-totals.component';
import { GbmRoutingModule } from './gbm-routing.module';
import { GbmComponent } from './gbm.component';

@NgModule({
  declarations: [GbmComponent, GbmOverviewComponent, GbmTotalsComponent],
  imports: [
    DeliveryDataModule,
    SharedModule,
    CommonModule,
    GbmRoutingModule,
    RouterModule,
    PageTableFilterModule,
    PageHierarchicalDataFilterModule,
    TableV2Module,
    MatSortModule,
    ReactiveFormsModule,
    AgrExportModule,
    AgrIdentifierModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    AgrNotificationEmptyModule,
  ],
  providers: [DeliveryDataService],
})
export class GbmModule {}
