import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateFeature, FeatureFlags } from '@next/core-lib/featureflag';
import { BexComponent } from './bex.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'bex',
        component: BexComponent,
        canActivate: [canActivateFeature],
        data: { featureFlags: FeatureFlags.showMinerals },
      },
    ]),
  ],
})
export class BexRoutingModule {}
