import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { UserSelectors, Customer } from '@next/core-lib/user';
import {
  catchError,
  map,
  mergeMap,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Environment } from '../../../environment';
import {
  Invoice,
  GetInvoicesResponse,
  InvoiceTotal,
  GetInvoiceTotalsResponse,
  InvoiceColumn,
  DetailedInvoice,
} from '../invoice-data.model';
import { Store } from '@ngrx/store';
import { RootState } from '@next/core-lib/app';
import { HierarchicalDataColumn } from '../../shared/hierarchical-data/hierarchical-data.model';
import { LocaleService } from '@next/core-lib/i18n';
import {
  OrderOptions,
  PageAndSize,
  Paginated,
  SearchOptions,
} from 'src/app/delivery-data/models/paginated-deliveries.model';
import { CoreActions } from '@next/core-lib';
import { NotificationActions } from '@next/core-lib/notification';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataService {
  protected baseUrl = `${this.env.API_BASE_URL}/service/invoice-v2/secure`;
  private readonly customerId$ = this.store.select(UserSelectors.selectCustomer);

  constructor(
    private readonly store: Store<RootState>,
    private readonly http: HttpClient,
    private readonly localeService: LocaleService,
    @Inject(EnvironmentInjector) private readonly env: Environment,
  ) {}

  public getInvoices(startDate: string, endDate: string): Observable<Invoice[]> {
    return this.customerId$.pipe(
      skipWhile((customerId) => !customerId),
      take(1),
      mergeMap((customer: Customer) => {
        if (!customer) {
          return of([] as Invoice[]);
        }

        return this.http
          .get<GetInvoicesResponse>(`${this.baseUrl}/invoice`, {
            params: {
              CustomerId: customer.id.toString(), // eslint-disable-line @typescript-eslint/naming-convention
              StartDate: startDate, // eslint-disable-line @typescript-eslint/naming-convention
              EndDate: endDate, // eslint-disable-line @typescript-eslint/naming-convention
              ShipToIds: customer.shipTos.map((shipTo) => shipTo.id.toString()), // eslint-disable-line @typescript-eslint/naming-convention
            },
          })
          .pipe(map((response: GetInvoicesResponse) => response.invoices));
      }),
    );
  }

  public getInvoiceTotals(
    startDate: string,
    endDate: string,
    selectedLocations: number[],
  ): Observable<InvoiceTotal[]> {
    return this.customerId$.pipe(
      skipWhile((customerId) => !customerId),
      take(1),
      mergeMap((customer: Customer) => {
        if (!customer) {
          return of([] as InvoiceTotal[]);
        }

        const shipToIds =
          selectedLocations?.length > 0
            ? selectedLocations.map((loc) => loc.toString())
            : customer.shipTos.map((shipTo) => shipTo.id.toString());

        return this.http
          .get<GetInvoiceTotalsResponse>(`${this.baseUrl}/invoice/totals`, {
            params: {
              CustomerId: customer.id.toString(), // eslint-disable-line @typescript-eslint/naming-convention
              StartDate: startDate, // eslint-disable-line @typescript-eslint/naming-convention
              EndDate: endDate, // eslint-disable-line @typescript-eslint/naming-convention
              ShipToIds: shipToIds, // eslint-disable-line @typescript-eslint/naming-convention
            },
          })
          .pipe(map((response: GetInvoiceTotalsResponse) => response.invoiceTotals));
      }),
    );
  }

  public getInvoiceDocument(ids: number[], shipToIds?: number[]): Observable<HttpResponse<Blob>> {
    return this.customerId$.pipe(
      take(1),
      mergeMap((customer: Customer) => {
        if (!customer) {
          return of({} as HttpResponse<Blob>);
        }

        return this.http.get(
          `${this.env.API_BASE_URL}/service/document/secure/document?DocumentType=Invoice`,
          {
            params: {
              Ids: ids.map((id) => id.toString()), // eslint-disable-line @typescript-eslint/naming-convention
              ShipToIds: shipToIds ?? customer.shipTos.map((shipTo) => shipTo.id.toString()), // eslint-disable-line @typescript-eslint/naming-convention, max-len
            },
            responseType: 'blob',
            observe: 'response',
          },
        );
      }),
    );
  }

  public getHeadings(columns: InvoiceColumn[], prefix: string): HierarchicalDataColumn[] {
    return columns.map((col: string) => ({
      value: this.localeService.translate(`${prefix}${col}`),
      id: col,
    }));
  }

  public filterAndSortInvoices(invoices: Invoice[], shipToIds: number[]) {
    return invoices
      .filter((i: Invoice) => shipToIds.length === 0 || shipToIds?.indexOf(i?.shipTo?.id) !== -1)
      .sort((i1: Invoice, i2: Invoice) => (i1.invoiceDate < i2.invoiceDate ? 1 : -1));
  }

  public filterAndSortDetailedInvoices(detailedInvoices: DetailedInvoice[], shipToIds: number[]) {
    return detailedInvoices
      .filter(
        (d: DetailedInvoice) => shipToIds.length === 0 || shipToIds?.indexOf(d?.shipTo?.id) !== -1,
      )
      .sort((d1: DetailedInvoice, d2: DetailedInvoice) =>
        d1.invoiceDate < d2.invoiceDate ? 1 : -1,
      );
  }

  public getInvoicesPaginated(
    startDate: Date,
    endDate: Date,
    paginationOptions: PageAndSize,
    search?: SearchOptions,
    shipToIds?: number[],
    order?: OrderOptions,
    isExport: boolean = false,
  ): Observable<Paginated<Invoice>> {
    const { pageIndex, pageSize } = paginationOptions;

    return this.customerId$.pipe(
      skipWhile((customer) => !customer && !shipToIds),
      take(1),
      map((customer) =>
        shipToIds?.length ? shipToIds : customer.shipTos.map((shipTo) => shipTo.id),
      ),
      tap((_) =>
        isExport ? this.store.dispatch(CoreActions.setLoading({ loading: true })) : undefined,
      ),
      map((_shipToIds: number[]) => ({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        pageIndex,
        pageSize,
        shipToIds: _shipToIds,
        order: order ? order : { column: 'InvoiceDate', descending: true },
        searchTerm: search.term,
      })),
      switchMap((body) => this.http.post<Paginated<Invoice>>(`${this.baseUrl}/invoice`, body)),
      tap((_) =>
        isExport ? this.store.dispatch(CoreActions.setLoading({ loading: false })) : undefined,
      ),
      catchError((error) => {
        this.store.dispatch(NotificationActions.errorSnackbar({ message: error.message }));
        if (isExport) {
          this.store.dispatch(CoreActions.setLoading({ loading: false }));
        }
        return of({
          metadata: {
            currentPage: 0,
            pageSize: 0,
            totalResultCount: 0,
            totalPages: 0,
          },
          results: [],
        });
      }),
      shareReplay(1),
    );
  }
}
