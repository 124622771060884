import { ParentNavigationItem } from '@next/core-lib/navigation';
import { createAction, props } from '@ngrx/store';
import { CustomerBrsNumber } from '../shared/models/shared.models';

export const getUserNavigationItemsSuccess = createAction(
  '[App] Get user navigation items success',
  props<{ userNavigationItems: ParentNavigationItem[] }>(),
);

export const getCustomerBrsNumbersSuccess = createAction(
  '[App] Get customer brs numbers success',
  props<{ customerBrsNumbers: CustomerBrsNumber[] }>(),
);
