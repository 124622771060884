import { createReducer, on } from '@ngrx/store';
import * as AppActions from './actions';
import { AppState } from '../shared/models/shared.models';

export const initialState: AppState = {
  userNavigationItems: null,
  customerBrsNumbers: null,
};

export const appReducer = createReducer<AppState>(
  initialState,
  on(AppActions.getUserNavigationItemsSuccess, (state, { userNavigationItems }) => ({
    ...state,
    userNavigationItems,
  })),
  on(AppActions.getCustomerBrsNumbersSuccess, (state, { customerBrsNumbers }) => ({
    ...state,
    customerBrsNumbers,
  })),
);
