import { Component, Inject } from '@angular/core';
import { EnvironmentInjector } from '@next/core-lib/environment';
import { Environment } from '../../environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  constructor(@Inject(EnvironmentInjector) public readonly env: Environment) {}
}
