import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateFeature, FeatureFlags } from '@next/core-lib/featureflag';
import { CustomerGuard } from '@next/core-lib/relationships';
import { InboundDeliveryComponent } from './inbound-delivery.component';

const routes: Routes = [
  {
    path: '',
    component: InboundDeliveryComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'inbound-deliveries',
        component: InboundDeliveryComponent,
        children: routes,
        canActivate: [CustomerGuard, canActivateFeature],
        data: { featureFlags: FeatureFlags.showGrain },
      },
    ]),
  ],
})
export class InboundDeliveryRoutingModule {}
