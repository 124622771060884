<app-page-hierarchical-data-filter
  [pageTitle]="'gbm.title' | translate"
  [searchFieldPlaceholder]="'gbm.search-placeholder' | translate"
  [emptyTitle]="'gbm.empty-title' | translate"
  [emptyDescription]="'gbm.empty-description' | translate"
  [data$]="data$"
  (loadDataCallback)="loadData()"
  [settingsActions]="filterActions"
  [exportFilename]="exportFilename"
  [exportPdfCallback]="downloadPdf"
  [headings]="headings"
  [showLegacyTotalWarning]="false"
></app-page-hierarchical-data-filter>
