import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RootState } from '@next/core-lib/app';
import { DaterangeConfig } from '@next/core-lib/components';
import { ExportFormat } from '@next/core-lib/export';
import { LocaleService } from '@next/core-lib/i18n';
import { FormatLocationPipe } from '@next/core-lib/pipes';
import { SettingsService, SettingsStorage } from '@next/core-lib/settings';
import { TableColumnTypes } from '@next/core-lib/table';
import { UserSelectors } from '@next/core-lib/user';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';
import { DeliveryDataService } from 'src/app/delivery-data/services/delivery-data.service';
import { getDeliveryReportRequest } from 'src/app/delivery-data/store/delivery-data.actions';
import { settingsContextName, SettingsKeys } from 'src/app/shared/models/shared.models';
import { ExportOptionsV2 } from '@next/core-lib/table-v2';
import { InboundDeliveryOverviewDataSource } from '../../datasource/inbound-delivery-overview.datasource';
import {
  inboundDeliveryColumns,
  InboundDeliveryOverviewDataFilters,
  InboundDeliveryOverviewViewModel,
} from '../../models/overview.model';

@Component({
  selector: 'app-inbound-delivery-overview',
  templateUrl: './inbound-delivery-overview.component.html',
  styleUrls: ['./inbound-delivery-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FormatLocationPipe],
})
export class InboundDeliveryOverviewComponent implements OnInit, OnDestroy {
  readonly dataSource = new InboundDeliveryOverviewDataSource(this._deliveryDataService);
  // Need to add a new display column ('action') in order to display a new table column (matColumnDef)
  readonly displayColumns = [...inboundDeliveryColumns];
  readonly subscriptions: Subscription[] = [];

  readonly searchAndFilters = new FormGroup({
    search: new FormControl(),
    locations: new FormControl(),
    dateRange: new FormControl(),
  });

  readonly showTotalsToggle = new FormControl();

  readonly exportOptions: ExportOptionsV2;

  constructor(
    private readonly _deliveryDataService: DeliveryDataService,
    private readonly localeService: LocaleService,
    private readonly settingsService: SettingsService,
    private readonly store: Store<RootState>,
  ) {
    this.exportOptions = {
      formats: [
        {
          format: ExportFormat.xlsx,
          name: 'XLSX',
        },
        {
          format: ExportFormat.csv,
          name: 'CSV',
        },
        {
          name: 'PDF',
          export: (data: InboundDeliveryOverviewViewModel[]) => this.downloadPdf(data),
        },
      ],
      fileName: this.localeService.translate('inbound-delivery.inbound-delivery-overview.fileName'),
      columns: inboundDeliveryColumns.map((col) => ({
        id: col,
        name: this.localeService.translate(`inbound-delivery.inbound-delivery-overview.${col}`),
      })),
    };
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchAndFilters.valueChanges.subscribe(() =>
        this.dataSource.applyFilters(
          this.searchAndFilters.value as InboundDeliveryOverviewDataFilters,
        ),
      ),
    );

    this.subscriptions.push(
      this.showTotalsToggle.valueChanges.subscribe((showTotals) =>
        this.settingsService
          .setSettings({
            contextName: settingsContextName,
            storage: SettingsStorage.PlatformSession,
            key: SettingsKeys.totals,
            data: showTotals,
          })
          .pipe(take(1))
          .subscribe((_) => {}),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  isEqual(a: InboundDeliveryOverviewViewModel, b: InboundDeliveryOverviewViewModel) {
    return a.id === b.id && a.deliveryId === b.deliveryId;
  }

  resetFilters() {
    this.searchAndFilters.reset({
      search: null,
      locations: null,
      dateRange: new DaterangeConfig().config.initial,
    });
  }

  getTableColumnType(dCol: DeliveryColumnTypes) {
    return dCol === 'deliveryDate'
      ? TableColumnTypes.DATE
      : [
          'grossWeight',
          'dryWeightDailyAmount',
          'moisturePercentage',
          'moistureDailyAveragePercentage',
          'proteinPercentage',
          'deviation',
          'hectoliterWeight',
          'fullBarleyPercentage',
          'impurityPercentage',
        ].includes(dCol)
      ? TableColumnTypes.NUMBER
      : TableColumnTypes.STRING;
  }

  getTableColumnDigitsInfo(dCol: DeliveryColumnTypes) {
    return ['hectoliterWeight'].includes(dCol) ? '1.1-1' : '1.0-2';
  }

  private downloadPdf = (data: InboundDeliveryOverviewViewModel[]) =>
    combineLatest([
      of(this.searchAndFilters.value as InboundDeliveryOverviewDataFilters),
      this.store.select(UserSelectors.selectCustomer),
      this.store.select(UserSelectors.selectLocations),
    ])
      .pipe(take(1))
      .subscribe(([filters, customer, locations]) => {
        const startDate = filters.dateRange.startDate;
        const endDate = filters.dateRange.endDate;

        this.store.dispatch(
          getDeliveryReportRequest({
            report: {
              title: this.localeService.translate('inbound-delivery.title-details'),
              subTitle: `${this.localeService.translate(
                'inbound-delivery.report.sub-title',
              )} ${this._deliveryDataService.formatDateTime(new Date())}`,
              fileName: this._deliveryDataService.formatExportFileName(
                this.localeService.translate('inbound-delivery.inbound-delivery-overview.fileName'),
                customer.externalId,
                startDate,
                endDate,
              ),
              legend: {
                [this.localeService.translate(
                  'inbound-delivery.report.legend.customer',
                )]: `${customer.name} (${customer.externalId})`,
                [this.localeService.translate('inbound-delivery.report.legend.locations')]:
                  this._deliveryDataService.formatLocations(
                    filters.locations
                      ? locations.filter((s) => filters?.locations.includes(s.id))
                      : locations,
                  ),
                [this.localeService.translate('inbound-delivery.report.legend.start-date')]:
                  this._deliveryDataService.formatDateTime(startDate),
                [this.localeService.translate('inbound-delivery.report.legend.end-date')]:
                  this._deliveryDataService.formatDateTime(endDate),
              },
              tableHeadings: this.exportOptions.columns.map((col) => col.name) ?? [],
              tableFooter: [],
              firstPageRowsPerPage: 32,
              rowsPerPage: 47,
              contactInformation: {
                email: this.localeService.translate('inbound-delivery.report.contact.email'),
                phoneNumber: this.localeService.translate(
                  'inbound-delivery.report.contact.phone-number',
                ),
              },
              rows: this._deliveryDataService.mapToPdfRows(
                inboundDeliveryColumns.map((dCol) => ({
                  id: dCol,
                  type: this.getTableColumnType(dCol),
                  digitsInfo: this.getTableColumnDigitsInfo(dCol),
                })),
                (data as unknown as DeliveryLineWithDelivery[]).map(
                  (d: DeliveryLineWithDelivery) => ({
                    ...d,
                    germination: !!d.germination
                      ? this.localeService.translate(
                          'inbound-delivery.inbound-delivery-overview.germination-true',
                        )
                      : this.localeService.translate(
                          'inbound-delivery.inbound-delivery-overview.germination-false',
                        ),
                  }),
                ),
              ),
            },
          }),
        );
      });
}
