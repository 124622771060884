<div class="page-table-filter wrapper--spacing-horizontal">
  <h1 class="text--h1">{{ pageTitle }}</h1>

  <ng-content select="[tableIntro]"></ng-content>

  <agr-filter
    *ngIf="filterConfig"
    [config]="filterConfig"
    [formControl]="filterControl"
    [resetFiltersAction]="resetFilterAction$"
  ></agr-filter>

  <div class="spacing--margin-bottom" *ngIf="settingsConfig">
    <agr-filter
      [config]="settingsConfig"
      [actions]="settingsActions"
      [selectionTitle]="selectionText"
      [actionsTitle]="'export.export_as' | translate"
      (action)="export($event)"
      [settingsButtonIcon]="'visibility'"
      [settingsButtonLabel]="'page-table-filter.settings' | translate"
      [resetFiltersAction]="resetFilterAction$"
    ></agr-filter>
  </div>

  <agr-table
    *ngIf="tableConfig"
    [config]="tableConfig"
    (selectionChange)="setSelection($event)"
    [emptyTitle]="emptyTitle"
    [emptyDescription]="emptyDescription"
    [emptyImage]="emptyImage"
    [emptyActionIcon]="emptyActionIcon"
    [emptyActionText]="emptyActionText"
    [emptyActionColor]="emptyActionColor"
    [showEmptyAction]="showEmptyAction"
    (emptyAction)="resetFilters()"
    [isEmpty]="this.tableConfig?.dataSource?.filteredData?.length === 0"
  ></agr-table>
</div>
