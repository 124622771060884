import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomerGuard } from '@next/core-lib/relationships';
import { InvoiceComponent } from './invoice.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'invoice/:invoiceId',
        component: InvoiceComponent,
        canActivate: [CustomerGuard],
      },
      {
        path: 'invoice',
        component: InvoiceComponent,
        canActivate: [CustomerGuard],
      },
    ]),
  ],
})
export class InvoiceRoutingModule {}
