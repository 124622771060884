import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  RowTypes,
  HierarchicalData,
  HierarchicalDataColumn,
  ColTypes,
} from './hierarchical-data.model';

@Component({
  selector: 'app-hierarchical-data',
  templateUrl: './hierarchical-data.component.html',
  styleUrls: ['./hierarchical-data.component.scss'],
})
export class HierarchicalDataComponent {
  @Input() emptyTitle: string;
  @Input() emptyDescription: string;
  @Input() emptyImage: string;
  @Input() emptyActionIcon: string;
  @Input() emptyActionText: string;
  @Input() emptyActionColor: ThemePalette;
  @Input() showEmptyAction = false;
  @Input() isEmpty = false;
  @Output() emptyAction = new EventEmitter();
  @Input() headings: HierarchicalDataColumn[];
  @Input() data: HierarchicalData[];

  public readonly rowTypes = RowTypes;
  public readonly colTypes = ColTypes;
}
