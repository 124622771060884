import { CollectionViewer } from '@angular/cdk/collections';
import { IdentifierData } from '@next/core-lib/components';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  OrderOptions,
  PageAndSize,
  Paginated,
} from 'src/app/delivery-data/models/paginated-deliveries.model';
import {
  AgrDataSource,
  ExportableDataSource,
  ExportListItemV2,
  FilterableDataSource,
  PaginatedDataSource,
  PaginationModel,
  SortableDataSource,
  Sorting,
} from '@next/core-lib/table-v2';
import { InvoiceOverviewViewModel, InvoiceOverviewDataFilters } from '../models/invoice.model';
import { Invoice } from 'src/app/invoice-data/invoice-data.model';
import { LocaleService } from '@next/core-lib/i18n';
import { InvoiceDataService } from 'src/app/invoice-data/services/invoice-data.service';
import { translateInvoiceType } from '../utility/invoice-type-translation';

export class InvoiceOverviewDataSource
  extends AgrDataSource<InvoiceOverviewViewModel>
  implements
    ExportableDataSource<InvoiceOverviewViewModel>,
    FilterableDataSource<InvoiceOverviewDataFilters, InvoiceOverviewViewModel>,
    PaginatedDataSource<InvoiceOverviewViewModel>,
    SortableDataSource<InvoiceOverviewViewModel> {
  private readonly _filtersSubject = new ReplaySubject<InvoiceOverviewDataFilters>(1);
  private readonly _sortingSubject = new BehaviorSubject<Sorting>({ invoiceDate: 'desc' });
  private readonly _pageAndSizeSubject = new BehaviorSubject<PageAndSize>({
    pageIndex: 0,
    pageSize: 25,
  });
  private readonly _destroyPaginatorSubject = new Subject<void>();
  private readonly _destroySubject = new Subject<void>();
  private readonly _data$: Observable<Paginated<Invoice>>;
  private readonly _results$: Observable<readonly InvoiceOverviewViewModel[]>;
  private readonly _order$: Observable<OrderOptions>;

  constructor(
    private _invoiceDataService: InvoiceDataService,
    private readonly _localeService: LocaleService,
  ) {
    super();

    this._order$ = this._sortingSubject.pipe(
      map((sorting) => {
        const sortColumn = Object.keys(sorting)[0];
        const sortDirection = sorting[sortColumn];

        return sortColumn && sortDirection
          ? { column: sortColumn, descending: sorting[sortColumn] === 'desc' }
          : undefined;
      }),
    );

    this._data$ = combineLatest([
      this._pageAndSizeSubject,
      this._filtersSubject,
      this._order$,
    ]).pipe(
      // debounceTime prevents sending unnecessary requests to backend when interacting with the table
      // e.g.: ordering/pagination or filtering/pagination actions when not on the first table page
      debounceTime(10),
      tap((_) => this.loadingSubject.next(true)),
      switchMap(([pagination, filters, order]) =>
        this._invoiceDataService.getInvoicesPaginated(
          filters.dateRange.startDate,
          filters.dateRange.endDate,
          {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          },
          {
            term: filters.search,
          },
          filters?.locations,
          order,
        ),
      ),
      shareReplay(1),
    );

    this._results$ = this._data$.pipe(
      filter((x) => !!x?.results),
      map((x) => x.results),
      map((results) => results.flatMap((invoice) => this._mapInvoicesForOverview(invoice))),
      tap((_) => this.loadingSubject.next(false)),
      catchError((error) => {
        this.loadingSubject.next(false);
        return throwError(error);
      }),
      shareReplay(1),
    );
  }

  connect(_: CollectionViewer): Observable<readonly InvoiceOverviewViewModel[]> {
    return this._results$;
  }

  disconnect(_: CollectionViewer): void {
    if (!this._destroySubject.isStopped) {
      this._destroySubject.next();
      this._destroySubject.complete();
    }
  }

  applyFilters(filters: InvoiceOverviewDataFilters) {
    this._filtersSubject.next(filters);
    this.resetPagination();
  }

  applyPage(pageIndex: number, pageSize: number): void {
    this._pageAndSizeSubject.next({ pageIndex, pageSize });
  }

  applySorting(sorting: Sorting): void {
    this._sortingSubject.next(sorting);
    this.resetPagination();
  }

  prepareExportData(
    item: ExportListItemV2,
    selection: InvoiceOverviewViewModel[],
    allPagesSelected: boolean,
  ) {
    let data$: Observable<InvoiceOverviewViewModel[]>;
    data$ =
      selection?.length > 0 && !allPagesSelected ? of(selection) : this.fetchInvoicesBatched();

    if (item.name !== 'PDF') {
      data$ = data$.pipe(
        map((data) =>
          data.map((invoice) => ({
            ...invoice,
            allShipToIds: invoice.allShipToIds.identifiers[0].value as unknown as IdentifierData,
          })),
        ),
      );
    }

    return data$;
  }

  fetchInvoicesBatched(): Observable<InvoiceOverviewViewModel[]> {
    const allInvoices$ = combineLatest([this._data$, this._filtersSubject])
      .pipe(
        exhaustMap(([data, filters]) => {
          const totalResultCount = data.metadata.totalResultCount;

          if (totalResultCount === data.results.length) {
            return of(data.results).pipe(
              map((results) => results.flatMap((invoice) => this._mapInvoicesForOverview(invoice))),
            );
          }

          const batchSize = 1000;
          const batchCount = Math.ceil(totalResultCount / batchSize);
          const batches: Observable<InvoiceOverviewViewModel[]>[] = [];

          for (let pageIndex = 0; pageIndex < batchCount; pageIndex++) {
            batches.push(this.getInvoicesBatch(filters, { pageIndex, pageSize: batchSize }));
          }

          return this.mergeBatches(batches);
        }),
      )
      .pipe(take(1));

    return allInvoices$;
  }

  connectPaginator(): Observable<PaginationModel> {
    return combineLatest([this._data$, this._pageAndSizeSubject]).pipe(
      takeUntil(this._destroyPaginatorSubject),
      map(([data, { pageIndex, pageSize }]) => ({
        pageSize,
        pageIndex,
        length: data.metadata.totalResultCount,
      })),
    );
  }

  disconnectPaginator(): void {
    if (!this._destroyPaginatorSubject.isStopped) {
      this._destroyPaginatorSubject.next();
      this._destroyPaginatorSubject.complete();
    }
  }

  resetPagination(): void {
    this._pageAndSizeSubject
      .pipe(take(1))
      .subscribe(({ pageSize }) => this._pageAndSizeSubject.next({ pageIndex: 0, pageSize }));
  }

  getInvoicesBatch(filters: InvoiceOverviewDataFilters, pagination: PageAndSize) {
    return this._invoiceDataService
      .getInvoicesPaginated(
        filters.dateRange.startDate,
        filters.dateRange.endDate,
        pagination,
        {
          term: filters.search,
        },
        filters?.locations,
        undefined,
        true,
      )
      .pipe(
        map(({ results }) => results.flatMap((invoice) => this._mapInvoicesForOverview(invoice))),
      );
  }

  mergeBatches(batches: Observable<InvoiceOverviewViewModel[]>[]) {
    return forkJoin(batches).pipe(
      map((batchedResults: InvoiceOverviewViewModel[][]) =>
        ([] as InvoiceOverviewViewModel[]).concat(...batchedResults),
      ),
    );
  }

  private _mapInvoicesForOverview = (invoice: Invoice): InvoiceOverviewViewModel => ({
    ...invoice,
    invoiceDescription: translateInvoiceType(invoice.invoiceDescription, this._localeService),
    allShipToIds: {
      identifiers: [
        {
          description: '',
          value: invoice.shipTo.externalShipToId,
        },
        ...invoice.shipTo?.shipToLegacies.map((legacyItem) => ({
          description: legacyItem.legacyType,
          value: legacyItem.id,
        })),
      ],
    },
    shipToId: invoice.shipTo.id,
  });
}
