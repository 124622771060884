import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateFeature, FeatureFlags } from '@next/core-lib/featureflag';
import { GbmComponent } from './gbm.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: 'gbm',
        component: GbmComponent,
        canActivate: [canActivateFeature],
        data: { featureFlags: FeatureFlags.showGBM },
      },
    ]),
  ],
})
export class GbmRoutingModule {}
