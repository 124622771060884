import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Converts a File object to a data url.
 * An example data url looks like data:image/png;base64,iVBORw0KGgoAA...
 * These urls can be used in the src attribute of an <image> tag
 */
@Pipe({
  name: 'objectUrl',
})
export class ObjectUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: File): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value));
  }
}
