import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HierarchicalDataComponent } from './hierarchical-data.component';
import { AgrNotificationEmptyModule } from '@next/core-lib/components';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [HierarchicalDataComponent],
  imports: [SharedModule, CommonModule, BrowserModule, AgrNotificationEmptyModule],
  exports: [HierarchicalDataComponent],
})
export class AgrHierarchicalDataModule {}
