import { AppState } from '../shared/models/shared.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectAppState = createFeatureSelector<AppState>('app');

export const selectUserNavigationItems = createSelector(
  selectAppState,
  (state) => state?.userNavigationItems,
);

export const selectCustomerBrsNumbers = createSelector(
  selectAppState,
  (state) => state?.customerBrsNumbers,
);
