import { DateRange } from '@next/core-lib/components/daterange-picker/daterange-picker.models';
import {
  DeliveryColumnTypes,
  DeliveryLineWithDelivery,
} from 'src/app/delivery-data/delivery-data.model';

export const inboundDeliveryColumns = [
  DeliveryColumnTypes.deliveryDate,
  DeliveryColumnTypes.externalDeliveryNoteId,
  DeliveryColumnTypes.contractDescription,
  DeliveryColumnTypes.grainWeightDocument,
  DeliveryColumnTypes.productTitle,
  DeliveryColumnTypes.grossWeight,
  DeliveryColumnTypes.dryWeightDailyAmount,
  DeliveryColumnTypes.moisturePercentage,
  DeliveryColumnTypes.moistureDailyAveragePercentage,
  DeliveryColumnTypes.proteinPercentage,
  DeliveryColumnTypes.deviation,
  DeliveryColumnTypes.hectoliterWeight,
  DeliveryColumnTypes.germination,
  DeliveryColumnTypes.fullBarleyPercentage,
  DeliveryColumnTypes.impurityPercentage,
] as const;

export type InboundDeliveryOverviewViewModel = Pick<
  DeliveryLineWithDelivery,
  | (typeof inboundDeliveryColumns)[number]
  | 'id'
  | 'deliveryId'
  | 'shipToId'
  | 'allShipToIds'
  | 'uniwinNumberWithExternalDeliveryNoteIdFallback'
>;

export interface InboundDeliveryOverviewDataFilters {
  locations: number[];
  search: string;
  dateRange: DateRange;
}
