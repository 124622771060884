<div class="spacing--padding-bottom">
  <mat-card class="mat-elevation-z1 recent-deliveries">
    <div class="recent-deliveries__header" mat-card-header>
      <h3 class="text--h6">{{ 'dashboard.recent-deliveries.title' | translate }}</h3>
      <a class="text--small" routerLink="/deliveries" (click)="setFilter()">
        {{ 'dashboard.recent-deliveries.header-link' | translate }}
      </a>
    </div>
    <mat-card-content>
      <div class="recent-deliveries">
        <agr-table
          [emptyDescription]="'dashboard.recent-deliveries.error-message' | translate"
          [config]="tableConfig$ | async"
        >
        </agr-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
