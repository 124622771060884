import { FeatureFlags } from '@next/core-lib/featureflag';
import { Environment } from '../../../environment';
import { UserNavigationItem } from '../models/shared.models';

export const getStaticUserNavigationItems = (
  environment: Environment,
  featureFlags: string[] = [],
): UserNavigationItem[] => {
  const navigationItems: UserNavigationItem[] = [
    {
      absoluteUrl: environment.HELP_NL_URL,
      label: 'app.help',
      icon: 'help',
    },
    {
      relativeUrl: '/dashboard',
      label: 'app.dashboard',
      icon: 'dashboard',
    },
    {
      absoluteUrl: environment.SHOP_URL,
      label: 'app.shop-new',
      icon: 'shopping_cart',
      newWindow: true,
      hideNewWindowIcon: true,
      applicationKey: 'Cart',
    },
    {
      relativeUrl: '/deliveries',
      label: 'app.deliveries',
      icon: 'local_shipping',
      applicationKey: 'DeliveryNotes',
    },
    {
      relativeUrl: '/invoice',
      label: 'app.invoices',
      iconKey: 'invoice',
      applicationKey: 'Invoices',
    },
    {
      relativeUrl: '/regenerative-agriculture-dashboard',
      label: 'app.regenerative-agriculture',
      icon: 'analytics',
      applicationKey: 'RegenerativeAgricultureDashboard',
    },
    {
      absoluteUrl: environment.MESTPLACE_URL,
      label: 'app.mestplace',
      icon: 'agriculture',
      newWindow: true,
      hideNewWindowIcon: true,
      applicationKeys: ['Mestplace'],
    },
  ];

  if (featureFlags.includes(FeatureFlags.showContracts)) {
    navigationItems.push({
      relativeUrl: '/contract',
      label: 'app.contract',
      icon: 'receipt',
      applicationKey: 'Contract',
    });
  }

  if (featureFlags.includes(FeatureFlags.showMinerals)) {
    navigationItems.push({
      label: 'app.minerals',
      icon: 'eco',
      children: [
        {
          relativeUrl: '/mineral',
          label: 'mineral.title',
          applicationKey: 'MineralsOverview',
        },
        {
          relativeUrl: '/bex',
          label: 'app.bex',
          applicationKey: 'Bex',
        },
        {
          relativeUrl: '/greenhouse',
          label: 'app.greenhouse',
          applicationKey:'EmissionFactors',
        },
      ],
    });
  }

  if (featureFlags.includes(FeatureFlags.showGrain)) {
    navigationItems.push({
      label: 'app.grain',
      iconKey: 'barley',
      children: [
        {
          relativeUrl: '/inbound-deliveries',
          label: 'app.inbound-deliveries',
          applicationKey: 'GrainDeliveries',
        },
        {
          absoluteUrl: environment.GRAIN_REGISTRATION_URL,
          label: 'app.grain-registration',
          hideForRoles: ['Transporter'],
          hideForLocales: ['de', 'fr', 'pl'],
          applicationKey: 'Aanmelden',
        },
        {
          absoluteUrl: `${environment.GRAIN_REGISTRATION_URL}/pick-up`,
          label: 'app.grain-pickup',
          hideForLocales: ['de', 'fr', 'pl'],
          applicationKey: 'Aanmelden',
        },
      ],
    });
  }

  if (featureFlags.includes(FeatureFlags.showGBM)) {
    navigationItems.push({
      relativeUrl: '/gbm',
      label: 'app.gbm',
      iconKey: 'database-outline',
      applicationKey: 'CropProtection',
    });
  }

  navigationItems.push(
    {
      relativeUrl: '/identification',
      label: 'app.identification',
      applicationKey: 'Identifications',
      icon: 'description',
      hideForLocales: ['de', 'fr', 'en', 'pl'],
    },
    {
      relativeUrl: '/preferences',
      label: 'app.preferences',
      icon: 'notifications',
      applicationKey: 'Cart',
    },
    {
      absoluteUrl: 'https://www.agrifirm.nl/fruitteeltconsult/',
      label: 'app.management.fruit-growing-advice',
      iconKey: 'twig',
      newWindow: true,
      hideNewWindowIcon: true,
      applicationKey: 'FruitCultivationConsult',
      hideSession: true,
    },
    {
      absoluteUrl: 'https://dairystartmonitor.agrifirm.com/',
      label: 'app.management.dairy-start-monitor',
      iconKey: 'cow',
      newWindow: true,
      hideNewWindowIcon: true,
      applicationKey: 'DairyStartMonitor',
    },
    {
      absoluteUrl: 'https://rearing.poultryresult.com/PoultryResultRearing',
      label: 'app.management.poultry-result-rearing',
      iconKey: 'poultry',
      newWindow: true,
      hideNewWindowIcon: true,
      applicationKey: 'PoultryResultOpfok',
      hideSession: true,
    },
    {
      absoluteUrl: 'https://poultryresult.com/',
      label: 'app.management.poultry-result',
      iconKey: 'poultry',
      newWindow: true,
      hideNewWindowIcon: true,
      applicationKey: 'PoultryResult',
      hideSession: true,
    },
    {
      absoluteUrl: 'https://www.agrifirm.nl/prikbord',
      label: 'app.management.bulletin-board',
      iconKey: 'bulletin-board',
      newWindow: true,
      hideNewWindowIcon: true,
      hideForLocales: ['de', 'fr', 'en', 'pl'],
      hideSession: true,
    },
    {
      label: 'app.news.title',
      iconKey: 'newspaper-variant-outline',
      hideNewWindowIcon: true,
      hideForLocales: ['de', 'fr', 'en', 'pl'],
      children: [
        {
          absoluteUrl: 'https://www.agrifirm.nl/teelttechnischnieuws/TTNNoord-GBM/',
          label: 'app.news.arable-north',
          newWindow: true,
          hideNewWindowIcon: true,
          applicationKey: 'CTNNorth',
          hideSession: true,
        },
        {
          absoluteUrl: 'https://www.agrifirm.nl/teelttechnischnieuws/TTNOost-GBM/',
          label: 'app.news.arable-east',
          newWindow: true,
          hideNewWindowIcon: true,
          applicationKey: 'CTNEast',
          hideSession: true,
        },
        {
          absoluteUrl: 'https://www.agrifirm.nl/teelttechnischnieuws/TTNZuid-GBM/',
          label: 'app.news.arable-south',
          newWindow: true,
          hideNewWindowIcon: true,
          applicationKey: 'CTNSouth',
          hideSession: true,
        },
        {
          absoluteUrl: 'https://www.agrifirm.nl/teelttechnischnieuws/TTNMidden-GBM/',
          label: 'app.news.arable-central',
          newWindow: true,
          hideNewWindowIcon: true,
          applicationKey: 'CTNMiddle',
          hideSession: true,
        },
        {
          absoluteUrl: 'https://www.agrifirm.nl/marktinformatie-myagrifirm/',
          label: 'app.news.raw-materials-market',
          newWindow: true,
          hideNewWindowIcon: true,
          applicationKey: 'MarketInformation',
          hideSession: true,
        },
      ],
    },
    {
      absoluteUrl: 'https://production.join-data.net/mijnjoindata',
      label: 'app.management.authorizations',
      icon: 'assignment',
      newWindow: true,
      hideNewWindowIcon: true,
      hideForLocales: ['de', 'fr', 'en', 'pl'],
      hideSession: true,
    },
  );

  return navigationItems;
};
