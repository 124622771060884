<div class="preferences-overview wrapper--spacing-horizontal" data-test="preferences-overview">
  <h1 class="text--h1">{{ 'preferences.preferences-overview.title' | translate }}</h1>
  <p>{{ 'preferences.preferences-overview.description' | translate }}</p>
  <mat-divider></mat-divider>

  <div
    class="preferences-overview__groups spacing--padding-top"
    *ngIf="preferenceGroups$ | async as preferenceGroups; else noPreferenceGroups"
  >
    <div
      class="preferences-overview__group flex--row"
      *ngFor="let preferenceGroup of preferenceGroups"
    >
      <p class="preferences-overview__preference-group-title text--bold flex--justify-start">
        {{ preferenceGroup.displayName || preferenceGroup.groupKey }}
      </p>

      <div class="preferences-overview__preference flex--column">
        <div *ngFor="let preference of preferenceGroup?.subGroups">
          <p>{{ preference.displayName || preference.subGroupKey }}</p>

          <ng-container *ngFor="let deliveryOption of preference.methods">
            <mat-slide-toggle
              color="primary"
              [ngModel]="deliveryOption.isEnabled"
              (change)="
                this.updateUserSetting(
                  preferenceGroup.groupKey,
                  preference.subGroupKey,
                  deliveryOption.method,
                  $event.checked
                )
              "
            >
              <div class="spacing--padding-right">
                {{ deliveryOption.displayName || deliveryOption.method }}
              </div>
            </mat-slide-toggle>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noPreferenceGroups>
  <agr-notification-empty
    [isEmpty]="true"
    [emptyTitle]="'cart-processing.empty-title' | translate"
    [emptyDescription]="'cart-processing.empty-description' | translate"
    [showEmptyAction]="false"
  >
  </agr-notification-empty>
</ng-template>
