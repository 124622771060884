import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { RootState } from '@next/core-lib/app';
import { Invoice, InvoiceColumnTypes } from '../../../invoice-data/invoice-data.model';
import { selectTopRecentInvoices } from '../../../invoice-data/store/invoice-data.selectors';
import {
  clearRecentInvoicesRequest,
  loadRecentInvoicesRequest,
} from '../../../invoice-data/store/invoice-data.actions';
import { TableColumnTypes, TableConfig } from '@next/core-lib/table';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LocaleService } from '@next/core-lib/i18n';
import { SettingsActions, SettingsStorage } from '@next/core-lib/settings';
import { settingsContextName, SettingsKeys } from '../../../shared/models/shared.models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recent-invoices',
  templateUrl: './recent-invoices.component.html',
  styleUrls: ['./recent-invoices.component.scss'],
})
export class RecentInvoicesComponent implements OnInit, OnDestroy {
  private readonly amountOfInvoicesToSelect = 3;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public tableConfig$: Observable<TableConfig> = this.store
    .select(selectTopRecentInvoices(this.amountOfInvoicesToSelect))
    .pipe(
      map((invoices: Invoice[]) => ({
        dataSource: new MatTableDataSource(
          invoices?.reduce((acc, value) => [...acc, value], []) ?? [],
        ),
        columns: [
          {
            id: InvoiceColumnTypes.invoiceDate,
            type: TableColumnTypes.DATE,
            label: `dashboard.recent-invoices.${InvoiceColumnTypes.invoiceDate}`,
            headerClassName: 'flex--1-6',
            className: 'flex--1-6',
          },
          {
            id: InvoiceColumnTypes.externalInvoiceId,
            type: TableColumnTypes.STRING,
            label: `dashboard.recent-invoices.${InvoiceColumnTypes.externalInvoiceId}`,
            headerClassName: 'flex--1-4',
            className: 'flex--1-4',
          },
          {
            id: InvoiceColumnTypes.allShipToIds,
            type: TableColumnTypes.IDENTIFIER,
            label: `dashboard.recent-invoices.${InvoiceColumnTypes.allShipToIds}`,
            hideIdentifierDescription: true,
            onlyUniqueIdentifiers: true,
            headerClassName: 'flex--1-4',
            className: 'flex--1-4',
          },
          {
            id: InvoiceColumnTypes.brutoAmount,
            type: TableColumnTypes.CURRENCY,
            label: `dashboard.recent-invoices.${InvoiceColumnTypes.brutoAmount}`,
            currencyKeyColumn: InvoiceColumnTypes.currencyKey,
          },
        ],
        rowClickAction: {
          id: 'view',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: this.viewInvoice.bind(this),
        },
        pagination: false,
        primaryColumn: InvoiceColumnTypes.externalInvoiceId,
        primaryColumnPrefix: this.localeService.translate(
          'dashboard.recent-invoices.primaryColumnPrefix',
        ),
        selectColumn: false,
        activeSort: {
          id: InvoiceColumnTypes.invoiceDate,
          start: 'desc',
          disableClear: false,
        },
      })),
    );

  constructor(
    private readonly store: Store<RootState>,
    private readonly localeService: LocaleService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadRecentInvoicesRequest());
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearRecentInvoicesRequest());
  }

  public setFilter(searchValue: string = ''): void {
    this.store.dispatch(
      SettingsActions.setSettings({
        settings: {
          contextName: settingsContextName,
          storage: SettingsStorage.PlatformSession,
          key: SettingsKeys.search,
          data: searchValue,
        },
      }),
    );
  }

  private viewInvoice(invoice: Invoice) {
    this.setFilter(invoice.externalInvoiceId);
    this.router.navigate(['invoice']);
  }
}
